/**
 * Renders an assessment detail editor
 */

import React from 'react';

// Helpers
import { checkValidity } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Components
import Duration from '../common/Duration';

// Icons
import * as icons from '../ui/Icons';
import Tooltip from "../common/Tooltip";

export const EditAssessmentDetails = class EditAssessmentDetails extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      estimatedDuration: 0,
      iconName: null
    };

    // Create refs
    this.name = React.createRef();
    this.details = React.createRef();
    this.key = React.createRef();
    this.icon = React.createRef();
  }

  formatPayload = (payload) => {
    payload.name = this.name.current.value || '';
    payload.estimatedDuration = this.state.estimatedDuration;
    payload.iconName = this.icon.current.value || '';

    if(this.details.current.value) {
      payload.details = this.details.current.value;
    }

    if(this.key.current.value) {
      payload.key = this.key.current.value;
    }

    delete payload.dateCreated;
    delete payload.dateUpdated;

    return payload;
  }

  handleUpdate = () => {
    const { assessment, onUpdate } = this.props;

    const payload = this.formatPayload(assessment);

    if(onUpdate) {
      onUpdate(payload);
    }
  }

  handleSubmit = () => {
    const {
      onSubmit,
      onSuccess,
      store,
      translation
    } = this.props;
    const { AppStore } = store;

    const payload = this.formatPayload({
      assessmentJson: {},
      customAlgorithms: [],
      customCutPoint: []
    })

    if(onSubmit) {
      const callback = (data) => {
        AppStore.toast = translation.success;
        onSuccess(data);
      }

      onSubmit(payload, callback);
    }
  }

  /**
   * renderIcon - Renders the appropriate icon for a given string value
   * @param {string} iconName
   */
  renderIcon = iconName => {
    let icon = null;

    switch (iconName) {
      case 'book':
        icon = <icons.assessmentBook />;
        break;

      case 'calendar':
        icon = <icons.assessmentCalendar />;
        break;

      case 'computer':
        icon = <icons.assessmentComputer />;
        break;

      case 'light bulb':
        icon = <icons.assessmentLightBulb />;
        break;

      case 'student':
        icon = <icons.assessmentPerson />;
        break;

      case 'typing':
        icon = <icons.assessmentTyping />;
        break;

      case 'writing':
        icon = <icons.assessmentWriting />;
        break;

      case 'math':
        icon = <icons.assessmentMath />;
        break;

      case 'career':
        icon = <icons.assessmentCareer />;
        break;

      case 'career (new)':
        icon = <icons.assessmentCareerNew />;
        break;

      default:
        break;
    }

    return icon;
  };

  render() {
    const { assessment, onClose, onUpdate, translation } = this.props;

    const fields = [
      {
        id: 'name',
        ref: this.name,
        translation: translation.name,
        type: 'text',
        required: true,
        defaultValue: assessment?.name || ''
      }, {
        id: 'key',
        ref: this.key,
        translation: translation.key,
        type: 'text',
        required: false,
        defaultValue: assessment?.key || ''
      }, {
        id: 'details',
        ref: this.details,
        translation: translation.details,
        type: 'textarea',
        required: false,
        defaultValue: assessment?.details || ''
      }
    ];

    return (
      <form
        className="form form--sbs panel panel--md panel--solo"
        noValidate
        onSubmit={(e) => checkValidity(e, this.handleSubmit)}
        onReset={onClose}
      >
        {/*
          Typically, I would prefer to use a single fieldset
          here and put to form__fields lists in it, but
          we need to use flexbox in this part and Chrome
          has a cool bug that causes flexbox not to work
          on fieldsets so I’m adding a div and dying inside
        */}
        <div className="form__content">
          <fieldset className="icon-select">
            <ul className="form__fields">
              <li>
                <label
                  className="form__lbl"
                  htmlFor="icon"
                >
                  {translation.icon.label}
                </label>

                <label className="select">
                  <select
                    id="icon"
                    onChange={(e) => {
                      this.setState({
                        iconName: e.target.value
                      }, () => {
                        if(onUpdate) {
                          this.handleUpdate()
                        }
                        else{
                          return;
                        }
                      })
                    }}
                    ref={this.icon}
                    defaultValue={assessment?.iconName || ''}
                  >
                    <option value="">{translation.icon.none}</option>

                    {translation.icon.options.map((option, i) =>
                      <option
                        key={i}
                        value={option.toLowerCase()}
                      >
                        {option}
                      </option>
                    )}
                  </select>
                </label>

                {(assessment?.iconName || this.state.iconName) &&
                  this.renderIcon(assessment?.iconName || this.state.iconName)
                }
              </li>
            </ul>
          </fieldset>

          <fieldset>
            <ul className="form__fields">
              {fields.map(
                (field, i) =>
                <li key={i}>
                  <label
                    className={`form__lbl${
                      field.required ? ' required' : ''
                    }`}
                    htmlFor={field.id}
                  >
                    {field.translation.label}

                    { field.translation.tooltipText &&
                      <Tooltip
                          content={field.translation.tooltipText}
                          className="cplt-label-tooltip"
                          right
                      />
                    }
                  </label>

                  {field.type !== 'textarea' &&
                    <input
                      type={field.type}
                      id={field.id}
                      required={field.required ? true : false}
                      aria-required={field.required ? 'true' : 'false'}
                      data-errormsg={field.translation.error}
                      ref={field.ref}
                      defaultValue={field.defaultValue}
                      onChange={onUpdate ? this.handleUpdate : null}
                    />
                  }

                  {field.type === 'textarea' &&
                    <textarea
                      id={field.id}
                      required={field.required ? true : false}
                      aria-required={field.required ? 'true' : 'false'}
                      data-errormsg={field.translation.error}
                      ref={field.ref}
                      defaultValue={field.defaultValue}
                      onChange={onUpdate ? this.handleUpdate : null}
                    />
                  }
                </li>
              )}

              <li>
                <Duration
                  {...this.props}
                  label={translation.duration.label}
                  errorMsg={translation.duration.error}
                  onChange={(estimatedDuration) => {
                    this.setState({
                      estimatedDuration
                    }, () => {
                      if(onUpdate) {
                        this.handleUpdate()
                      }
                    })
                  }}
                  defaultValue={assessment?.estimatedDuration || ''}
                />
              </li>
            </ul>
          </fieldset>
        </div>

        {/* If we’re updating an existing assessment, we don’t need the save or cancel buttons */}
        {!assessment &&
          <div className="panel__action">
            <button
              className="btn btn--outline"
              type="reset"
              onClick={onClose}
            >
              {translation.cancel}
            </button>

            <button
              className="btn"
              type="submit"
            >
              {translation.submit}
            </button>
          </div>
        }
      </form>
    )
  }
}

export default translate('EditAssessmentDetails')(EditAssessmentDetails);