/**
 * Renders the student home view
 * with available assessments and results
 */

import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../translate/Translate';

// Components
import StudentRouter from './StudentRouter';

export const StudentHome = observer(class StudentHome extends React.Component {
  render() {
    return (

      <StudentRouter
        {...this.props}
        type='new'
      />
    )
  }
})

export default translate('StudentHome')(StudentHome);
