import AceEditor from "react-ace";
import React, {useEffect, useState} from "react";

function AutoSizeReactAceEditor(props) {
  const [maxLines, setMaxLines] = useState(Math.floor(window.innerHeight / 16) / 3);

  useEffect(() => {
    // Update maxLines when the window is resized
    const handleResize = () => {
      setMaxLines(Math.floor(window.innerHeight / 16) / 3);
    };

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AceEditor
      className={'ignore-all'}
      mode={props.language}
      value={props.data}
      theme='github'
      readOnly={true}
      showPrintMargin={false}
      maxLines={maxLines}
      minLines={1}
      setOptions={{showFoldWidgets: false}}
    >
    </AceEditor>

  )

} export default AutoSizeReactAceEditor;