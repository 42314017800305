import React from 'react';

// Dependencies
import { differenceInSeconds, parseISO } from 'date-fns';

// Translation
import translate from '../translate/Translate';

// Helpers
import { parseSeconds } from '../../helpers';
// Components
import Date from '../common/Date';
import Score from '../common/Score';

const InstructorReport = props => {
  // Highlight correct and incorrect answers

  const highlightChoices = (value, correctAnswer, response) => {
    let className = null;

    if(!correctAnswer) {
      return className;
    }

    //eslint-disable-next-line
    if(value == correctAnswer && correctAnswer) {
      className = 'correct';
    } else if(value === response) {
      className = 'incorrect';
    }

    return className;
  }

  // Render the correct question text
  const renderQuestionText = question => {
    const { html, textToRead, textToType, title, type } = question;

    if (type !== 'typingquestion' && type !== 'readingquestion' && type !== 'html') {
      return title;
    } else if (type === 'html') {
      return html;
    } else if (type === 'typingquestion') {
      return textToType;
    } else if (type === 'readingquestion') {
      return textToRead;
    }
  }

  // Render the correct choice text
  const renderChoiceText = choice => {
    if (choice.text) {
      return choice.text;
    } else if (!choice.text && choice.value) {
      return choice.value;
    } else if (!choice.text && typeof choice === 'string') {
      return choice;
    }
  }

  // Question numbers
  let questionNumber = 0;

  const renderQuestionNumber = () => {
    questionNumber += 1;
    return questionNumber;
  }


  return (
      <article

          className="report"
          data-attempt={props.attemptSid}
      >
        <header className="report__head">
          <img
              alt = "report logo"
              className="report__logo"
              src="/img/wgu.png"
          />

          <div className="report__details">
            <h1 className="report__title">
              {props.attempt.assessmentName ? props.attempt.assessmentName : 'CPLT'}
            </h1>

            <span className="report__stat">
            {props.translation.completed_date}:

            <Date
                {...props}
                date={props.attempt.dateCompleted}
                dateFormat="M/d/yy h:mm a"
            />
          </span>

            <span className="report__stat">
            {props.translation.duration}:

              {parseSeconds(
                  differenceInSeconds(parseISO(props.attempt.dateCompleted), parseISO(props.attempt.dateCreated))
              )}
          </span>
          </div>
        </header>

        <section className="report__section">
          <h2 className="report__heading">
            {props.translation.intro.heading_1}
          </h2>

          <p>{props.translation.intro.body_1}</p>
        </section>

        <section className="report__section">
          <h2 className="report__heading">
            {props.translation.intro.heading_2}
          </h2>

          <div dangerouslySetInnerHTML={{ __html: props.translation.intro.body_2 }} />
        </section>

        {/* Loop through scores */}
        {props.tags && Object.keys(props.tags).length > 0 &&
        <section className="report__section report__scores">
          <h2 className="meta">Scores</h2>

          <table className="score-list">
            <thead>
            <tr>
              <th className="meta" scope="col">{props.translation.scores.competency}</th>
              <th className="meta" scope="col">{props.translation.scores.score}</th>
            </tr>
            </thead>

            <tbody>

            {Object.keys(props.tags).map((tag, i) =>
                <React.Fragment key={i}>
                  <Score
                      {...props}
                      percent={props.tags[tag].percentage}
                      title={tag}
                  />

                  {/* If there are subkeys, render them */}
                  {Object.keys(props.tags[tag]).map((subTag, j) => {
                    if (
                        subTag !== "earned" &&
                        subTag !== "possible" &&
                        subTag !== "percentage"
                    ) {
                      return (
                          <Score
                              {...props}
                              key={j}
                              displayStyle="compact"
                              percent={props.tags[tag][subTag].percentage}
                              title={subTag}
                          />
                      )
                    }

                    return null;
                  })}
                </React.Fragment>
            )}
            </tbody>
          </table>
        </section>
        }
        {/* End of scores loop */}

        {/* Loop through questions */}
        {props.questionData &&
        <section className="report__section">
          {props.questionData.assessmentJson.pages.map(page =>
              page.elements.map((question, i) =>
                <article
                    className="report__question"
                    key={i}
                >
                  <header className="report__question-head">
                    {/* Question number */}
                    <span className="report__question-number">
                  {renderQuestionNumber()}.
                </span>

                    {/* Question text */}
                    <span
                        className="report__question-title"
                        dangerouslySetInnerHTML={{ __html: renderQuestionText(question) }}
                        id={`question-title-${i}`}
                    />
                  </header>

                  {/* Typing question response */}
                  {question.type === 'typingquestion' &&
                  <div className="report__question-response">
                    <strong>
                      {props.translation.typed}
                    </strong>

                    <p>
                      {props.attempt.responseJson[question.name].response}
                    </p>

                    <strong>
                      {props.translation.time_elapsed
                          .replace('%seconds%', props.attempt.responseJson[question.name].secondsElapsed)}
                    </strong>
                  </div>
                  }

                  {/* Choices */}
                  {/*
                Note: This is really annoying because SurveyJS apparently thinks it’s
                cool to save some choices as objects while saving others as strings.
                So basically we have to check for a choice.value _and_ just a choice
                in order to output things like the choice text, correct/incorrect
                responses, and other fun stuff.
              */}
                  {question.choices &&
                  <div className="report__question-choices">
                    {question.choices.map((choice, i) => {
                      let questionSelector;

                      // HACK: Because of my best friend, SurveyJS, some
                      // responses have the question’s key set to the question name
                      // property and others apparently have it set to the question
                      // version, so we need to figure out which we’re going to
                      // use to figure out if the student’s response is correct
                      if(props.attempt.responseJson[question.name]) {
                        questionSelector = question.name;
                      } else {
                        questionSelector = question.questionVersion;
                      }

                      return (
                          <div
                              className="report__question-choice"
                              key={i}
                          >
                            {/* Correct answer */}
                            {/*eslint-disable-next-line*/}
                            {choice.value == question.correctAnswer &&
                            typeof question.correctAnswer !== 'undefined' &&

                            <span className="report__response report__response--correct">
                            {props.translation.correct}
                          </span>
                            }

                            {/* Incorrect response */}
                            {/*eslint-disable-next-line*/}
                            {((choice.value == props.attempt.responseJson[questionSelector].response &&
                                /*eslint-disable-next-line*/
                                choice.value != question.correctAnswer
                            ) || (
                                typeof choice === 'string' &&
                                choice === props.attempt.responseJson[questionSelector].response &&
                                /*eslint-disable-next-line*/
                                choice.value != question.correctAnswer
                            )) &&
                            typeof question.correctAnswer !== 'undefined' &&
                            question.correctAnswer &&

                            <span className="report__response report__response--incorrect">
                            {props.translation.response}
                          </span>
                            }

                            {/* Radio buttons */}
                            {(question.type === 'radiogroup' || question.type === 'dropdown' || question.type === 'likertquestion') &&
                            <div>
                              {/* Checked */}
                              {choice.value === props.attempt.responseJson[questionSelector].response &&
                              <strong
                                  className="report__radio report__radio--checked"
                              >
                                Selected choice
                              </strong>
                              }

                              {/* Checked (for alternative data format) */}
                              {typeof choice === 'string' && choice === props.attempt.responseJson[questionSelector].response &&
                              <strong
                                  className="report__radio report__radio--checked"
                              >
                                {props.translation.choices.selected}
                              </strong>
                              }

                              {/* Unchecked */}
                              {choice.value !== props.attempt.responseJson[questionSelector].response &&
                              <strong
                                  className="report__radio"
                              >
                                {props.translation.choices.unselected}
                              </strong>
                              }
                            </div>
                            }

                            {/* Choice text */}
                            {!choice.equation &&
                            <span
                                className={
                                  highlightChoices(
                                      choice.value || choice, question.correctAnswer, props.attempt.responseJson[questionSelector].response
                                  )
                                }
                                dangerouslySetInnerHTML={{ __html: renderChoiceText(choice) }}
                            />
                            }
                          </div>
                      )
                    })}
                  </div>
                  }
                </article>
              )
          )}
        </section>
        }
      </article>
  )
}

export default translate('PDFReport')(InstructorReport);