/**
 * Renders Survey Card
 */

import React, {Component} from 'react';
import { observer } from 'mobx-react';

// Icons
import * as icons from '../ui/Icons';

// Translation
import translate from '../translate/Translate'
import EditSurvey from "../forms/EditSurvey";

export const SurveyCard = observer(class SurveyCard extends Component {
    constructor() {
        super();

        this.creator = null;

        this.state = {
            render: false
        }
    }



   /**
    * handleDelete - Deletes the survey after a confirmation
    */
    handleDelete = () => {
        const { listSurveys, surveySid, translation } = this.props;
        const { SurveyStore, AppStore } = this.props.store;

        const callback = () => {
            listSurveys();
            AppStore.toast = translation.delete.success;
        }

        AppStore.showDialog(
            translation.delete_confirm,
            'confirm',
            () => SurveyStore.deleteSurvey(surveySid, callback)
        )
    }

    render() {
        const { translation, name, description, surveyKey, draft } = this.props;
        const {AppStore} = this.props.store;

        return (
            <article className="panel panel--card panel--clickable">
                <div className="panel__content">
                    <h2 className="panel__title panel__title--alt">
                        {name}
                    </h2>
                    {draft &&
                        <span className="tag tag--label tag--draft">
                            {translation.draft}
                         </span>
                    }

                    <ul className="list-reset">
                        <li>
                            <span className="meta">
                                {translation.key}
                            </span>

                            <icons.key />
                            {surveyKey}
                        </li>
                    </ul>

                    {description !== null &&
                        <p className="panel__content--overflow">
                            {description}
                        </p>
                    }

                    {description === null &&
                        <p>
                            {translation.no_description}
                        </p>
                    }

                    <footer className="panel__action panel__action--alt">
                        <button
                            className="btn btn--ghost btn--negative"
                            onClick={() => {
                                AppStore.drawerContent =
                                <EditSurvey
                                    {...this.props}
                                    component="EditSurvey"
                                    surveySid={this.props.surveySid}
                                    newSurvey={false}
                                />;
                                AppStore.drawerMod = 'full';
                            }}
                        >
                            <icons.pencil />
                        </button>
                        <button className="btn btn--ghost" onClick={this.handleDelete}>
                            <icons.trash />
                        </button>
                    </footer>
                </div>
            </article>
        );
    }
})

export default translate('SurveyCard')(SurveyCard);