import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import {Link} from 'react-router-dom'
import he from 'he';

// Helpers
import { getParam, getUrlSegment } from '../../helpers';
import RenderHTML from "../common/RenderHTML";

// Custom Survey Questions
import { readingQuestion, typingQuestion, likertQuestion } from '../../customSurveyQuestions';

// Dependencies
import * as Survey from 'survey-react';

// Translation
import translate from '../translate/Translate';
import * as ReactDOM from "react-dom";

// Set up Survey
Survey.Serializer.addProperty('question', { name: 'math:boolean' });
Survey.Serializer.addProperty('question', { name: 'tags' });
Survey.Serializer.addProperty('question', { name: 'points:number', default: 0 });
Survey.Serializer.addProperty('question', { name: 'renderAs' });
Survey.Serializer.addProperty('itemvalue',
  {
    name: "credit",
    default: 0,
    choices: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  }
);
Survey.Serializer.addProperty('question', { name: 'renderRawHTML', default: true });


const Assessment = observer(class Assessment extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      attemptSid: getParam('attempt'),
      formattedResponse: null,
      ready: false,
      response: null
    };

    // Bind this to functions
    this.handleSubmit = this.handleSubmit.bind(this);
    this.confirmComplete = this.confirmComplete.bind(this);
    this.complete = this.complete.bind(this);
  }
  componentDidUpdate() {
    if (window.MathJax) {
      try {
        window.MathJax.typeset();
      } catch (error) {
        // Handle MathJax error if needed
      }
    }
  }

  async componentDidMount() {

    if(window.MathJax){
      window.MathJax.typeset();
    }
    const { assessment, store } = this.props;
    const { AssessmentStore, UserStore, ActivityStore } = store;
    const { userData } = UserStore;


    ActivityStore.sendActivity('Page View',{
      pageUrl: window.location.href,
    });

    if(
      !assessment &&
      getUrlSegment(1) === 'assessment-review' &&
      userData.role.toLowerCase() !== 'learner'
    ) {
      await AssessmentStore.getAssessment(
        getUrlSegment(2),
        {
          assessmentVersionSid: getParam('assessmentVersionSid'),
          displayMode: 'single-page',
          review: true

        }
      );
    }
    if(
        !assessment &&
        getUrlSegment(1) !== 'assessment-review' &&
        userData.role.toLowerCase() !== 'learner'
    ) {
      await AssessmentStore.getAssessment(
          getUrlSegment(2),
          {
            assessmentVersionSid: getParam('version'),
            displayMode: 'single-page'
          }
      );
    }
    if(
        !assessment &&
        getUrlSegment(1) !== 'assessment-review' &&
        userData.role.toLowerCase() === 'learner'
    ) {
      await AssessmentStore.getLearnerAssessment();
    }



    if(getParam('attempt')) {
      const attempt = await AssessmentStore.getAttempt(getParam('attempt'));

      // Format response data the way SurveyJS expects it
      if(attempt) {
        await this.storeResponses(toJS(attempt.responseJson));
      }
      this.setState({
        ready: true
      });
    } else {
      this.setState({
        ready: true
      });
    }
  }

  async storeResponses(responseJson) {
    const response = {};

    if(responseJson && Object.keys(responseJson).length > 0) {
      Object.keys(responseJson).forEach(question => {
        if(responseJson[question].response && !responseJson[question].secondsElapsed) {
          response[question] = responseJson[question].response;
        } else {
          response[question] = responseJson[question];
        }
      });
    }

    this.setState({
      response,
      formattedResponse: responseJson
    });

    return true;
  }

  async handleSubmit(surveyResponse, complete, backgroundLoad = null, saveCallback = null) {
    const { attemptSid } = this.state;
    const { AssessmentStore } = this.props.store;

    const responseJson = {};
    const removeableProps = [];

    // Store the surveyResponse in case the
    // user clicks the save button
    this.setState({
      surveyResponse
    });

    Object.keys(surveyResponse.data).forEach(question => {
      const questionProps = surveyResponse.getQuestionByName(question);
      //eslint-disable-next-line
      let questionType = null;

      if(surveyResponse.data[question]?.secondsElapsed) {
        responseJson[question] = surveyResponse.data[question];
      } else {
        responseJson[question] = {
          response: surveyResponse.data[question]
        };
      }

      if(questionProps) {
        questionType = questionProps.getType();
      }

      // Handle comments
      if(surveyResponse.data[`${question}-Comment`]) {
        responseJson[question].comment = surveyResponse.data[`${question}-Comment`];

        removeableProps.push(`${question}-Comment`);
      }
    });

    if(removeableProps.length) {
      removeableProps.forEach(key => {
        delete responseJson[key];
      });
    }

    const payload = {
      responseJson,
      finalSubmission: false,
      userAgent: navigator.userAgent
    };

    let callback = (response) => {
      this.storeResponses(response.responseJson);

      if(saveCallback) {
        saveCallback();
      }
    };

    if(complete) {
      payload.finalSubmission = true;
      callback = null;
    }

    await AssessmentStore.saveAttempt(
      attemptSid,
      payload,
      callback,
      backgroundLoad
    );

    if(complete) {
      this.complete();
    }
  }

  confirmComplete(surveyResponse, options) {
    const { store, translation } = this.props;
    const { AppStore } = store;

    options.allowComplete = false;

    AppStore.showDialog(
      translation.complete_confirm,
      'confirm',
      async () => {
        await this.handleSubmit(surveyResponse, true);
      }
    );
  }

  complete() {
    const { attemptSid } = this.state;

    window.location = (`/results/${attemptSid}`);
  }

  isCorrect(question) {
    if(question.correctAnswer !== undefined) {
      // eslint-disable-next-line
      return question.value == question.correctAnswer;
    }

    return 'n/a';
  }

  renderMathJax() {
    if(window.MathJax) {
      try {
        window.MathJax.typeset();
      } catch (error) {
        // Ignore MathJax retry error which is non-breaking
      }
    }
  }

  renderSave = () => {
    const { translation } = this.props;
    let nav;

    const saveDelay = setInterval(() => {
      nav = document.querySelector('.sv_nav');

      if(nav && !document.querySelector('.sfl-btn')) {
        clearInterval(saveDelay);

        const saveBtn = document.createElement('button');
        saveBtn.textContent = translation.save.button;
        saveBtn.className = 'btn btn--outline sfl-btn'
        saveBtn.onclick = this.handleSave;

        nav.insertBefore(saveBtn, nav.lastChild);
      }
    }, 100);
  }

  handleSave = () => {
    const { store, translation } = this.props;
    const { surveyResponse } = this.state;
    const { AppStore } = store;

    const callback = () => {
      AppStore.showDialog(translation.save.success);
    };

    this.handleSubmit(surveyResponse, false, true, callback);
  }

  renderAudioButton = (parent, stopBtn) => {
    /** SpeechSynthesis is unsupported in IE & Opera for Android */
    let ie = false;
    let operaMini = false;

    if(document.querySelector('html').className.indexOf('ie-true') > -1) {
      ie = true;
    }

    if(navigator.userAgent.indexOf('Opera Mini') > -1) {
      operaMini = true;
    }

    if(!ie && !operaMini) {
      const textToSpeech = (text) => {
        let synth = window.speechSynthesis;
        if (synth.speaking) {
          return;
        }
        var utterance = new SpeechSynthesisUtterance(text);
        synth.speak(utterance);
      }

      stopBtn.classList.remove('btn--full');
      stopBtn.classList.add('btn--half');
      const text = parent.querySelector('.rate-question__passage').innerText;

      const audioBtn = document.createElement('button');
      audioBtn.classList.add('rate-question__audio', 'btn', 'btn--outline', 'btn--half', 'btn--audio');
      audioBtn.innerText = 'Play Full Audio';
      audioBtn.addEventListener("click", function(){ textToSpeech(text); });
      parent.append(audioBtn);
      parent.append(stopBtn);
    }
  }

  render() {
    const { store, translation } = this.props;
    const { ready, response } = this.state;
    const { AssessmentStore, UserStore } = store;
    const { userData } = UserStore;
    let model = null;

    // and it exists in store, use that
    let assessment = AssessmentStore.assessment;


    if(assessment) {
      // Parse assessment data if it has been base64 encoded
      if(typeof assessment !== 'object') {
        assessment = JSON.parse(Buffer.from(assessment, 'base64').toString());
      }
      model = new Survey.Model(assessment.assessmentJson);

      typingQuestion(Survey);
      readingQuestion(Survey);
      likertQuestion(Survey);

      // --------------- begin custom rendering -------------------
      model.onAfterRenderPage.add((survey, page) => {
        const pageTitle = page.htmlElement.querySelector('h4.sv_page_title');
        if (pageTitle && pageTitle.innerText.length <= 0) {
          pageTitle.remove();
        }

        let nav = document.querySelector('.sv_nav');
        let saveBtn = document.querySelector('.sfl-btn');
        if (nav && saveBtn) {
          nav.insertBefore(saveBtn, nav.lastChild);
        }
      })

      // Handle custom rendering
      model.onAfterRenderQuestion.add((survey, options) => {
        const questionTitle = options.htmlElement.querySelector('h5');
        let questionText = null;
        let questionNumber = null;
        let textString = null;

        if(questionTitle) {
          questionText = questionTitle.querySelector('span:not([class])');
          questionNumber = questionTitle.querySelector('.sv_q_num');
          questionNumber.innerText += '.';
        }

        if(questionText) {
          // Remove all HTML encoding from the question.
          textString = he.decode(questionText.innerHTML);
        }

        // Parse any HTML in the question title
        if(questionText && textString) {
          questionText.innerHTML = textString;
          ReactDOM.render(<RenderHTML rawHTML = {textString}></RenderHTML>, questionText)
        }

        // Modify the markup of the question title
        // because SurveyJS’s markup is just astounding
        if(questionTitle) {
          const question = options.htmlElement;
          question.className += ' question';

          const questionHead = options.htmlElement.querySelector(':scope > div:first-child');
          questionHead.className += ' question__head';

          questionTitle.outerHTML = ` 
            <legend class="${questionTitle.className} question__title"></legend>
          `;

          const questionLabel = options.htmlElement.querySelector('.question__title');
          const questionNumberHeading = document.createElement('h2');
          questionNumberHeading.append(questionNumber);
          questionLabel.append(questionNumberHeading);
          questionLabel.append(questionText);

          question.prepend(questionLabel);
          const questionAnswer = options.htmlElement.querySelector(':scope > div:last-of-type');
          questionAnswer.className += ' question__answer';

          const questionLegend = questionAnswer.querySelector('legend');

          if(questionLegend) {
            questionLegend.remove();
          }

          const fieldset = document.createElement('fieldset');
          fieldset.append(questionLabel);
          fieldset.append(questionHead);
          fieldset.append(questionAnswer);

          question.append(fieldset);

          // Check for any empty headings
          // which SurveyJS loves to add
          const headings = document.querySelectorAll('h3, h5');

          headings.forEach(heading => {
            if(!heading.textContent) {
              heading.remove();
            }
          })
        }

        // Hide the Likert style question's placeholder. Should only be
        // removed from the Assessment view, not the Survey creator, because
        // otherwise it breaks the Likert rendering in Survey creator.
        const likertPlaceholder = document.querySelectorAll('.likert-placeholder');
        likertPlaceholder.forEach(placeholder => placeholder.style.display = 'none');

        // Render typing and reading questions
        if(
          options.question.getType() === 'typingquestion' ||
          options.question.getType() === 'readingquestion'
        ) {
          if(getUrlSegment(1) !== 'assessment-review') {
            const parent = options.htmlElement.querySelector('.rate-question');
            const passage = parent.querySelector('.rate-question__passage');
            passage.setAttribute('aria-live', 'polite');
            let secondsElapsed = 0;
            let timer;

            if(parent) {
              const responseField = parent.querySelector('.rate-question__response');

              const startBtn = document.createElement('button');
              startBtn.className = 'rate-question__start btn btn--full';

              if(options.question.getType() === 'typingquestion') {
                startBtn.textContent = translation.typing_question.start;
              } else {
                startBtn.textContent = translation.reading_question.start;
              }

              const stopBtn = document.createElement('button');
              stopBtn.className = 'rate-question__stop btn btn--full';
              stopBtn.textContent = translation.typing_question.stop;

              if(options.question.getType() === 'typingquestion') {
                stopBtn.textContent = translation.typing_question.stop;
              } else {
                stopBtn.textContent = translation.reading_question.stop;
              }

              // Prevent pasting in response field
              if(responseField) {
                responseField.addEventListener('paste', (e) => {
                  e.preventDefault();
                });
              }

              // Start
              startBtn.onclick = () => {
                parent.classList.add('rate-question--active');

                timer = setInterval(() => {
                  secondsElapsed++;
                }, 1000);

                this.renderAudioButton(parent, stopBtn);

                if(responseField) {
                  responseField.value = '';

                  responseField.focus();
                }

              }

              // Finish typing
              stopBtn.onclick = () => {
                clearInterval(timer);

                parent.classList.remove('rate-question--active');
                parent.classList.add('rate-question--complete');

                const msg = document.createElement('span');
                msg.className = 'rate-question__complete-msg note'
                msg.textContent = translation.typing_question.completed;

                if(options.question.getType() === 'typingquestion') {
                  msg.textContent = translation.typing_question.completed;
                } else {
                  msg.textContent = translation.reading_question.completed;
                }

                parent.append(msg);

                const responseJson = {...this.state.formattedResponse};

                responseJson[options.question.name] = {
                  secondsElapsed
                };

                if(responseField) {
                  responseJson[options.question.name].response = responseField.value;
                }

                const payload = {
                  responseJson,
                  finalSubmission: false,
                  userAgent: navigator.userAgent
                }

                const callback = (response) => {
                  this.storeResponses(response.responseJson);
                };

                AssessmentStore.saveAttempt(
                  this.state.attemptSid,
                  payload,
                  callback,
                  true
                );
              }

              parent.prepend(startBtn);
              parent.append(stopBtn);
            }
          } else {
            if(!response) {
              return;
            }

            const studentResponse = response[options.question.name];
            const secondsElapsed = response[options.question.name].secondsElapsed;
            const parent = options.htmlElement.querySelector('.rate-question');


            if(options.question.getType() === 'typingquestion') {
              parent.innerHTML = `
                <strong class="rate-question__title-response">${translation.typing_question.student_response}</strong>
                <div class="note"><p>${studentResponse.response}</p></div>
              `
            }

            parent.innerHTML += `
              <strong class="rate-question__title-response">${translation.time_elapsed.replace('%seconds%', secondsElapsed)}</strong>
            `
          }
        }

        // Loop through all options and decode HTML
        if(options.question.choices) {
          options.question.choices.forEach((choice, i) => {
            const choiceEl = options.htmlElement.querySelectorAll('label')[i];

            if(!choiceEl) {
              return;
            }

            // Remove the input's aria-label since it's not needed.
            // The input is already wrapped in a <label> which contains text.
            choiceEl.firstChild.removeAttribute('aria-label');

            // The label text is contained in the last <span> child.
            // It is not given a class by SurveyJS.
            const choiceText = choiceEl.querySelector('span:not([class])');

            if(options.question.propertyHash.renderRawHTML){
              ReactDOM.render(<RenderHTML rawHTML = {(choice.itemValue)}></RenderHTML>, choiceText)
            } else {
              choiceText.innerHTML = he.decode(choiceText.innerHTML)
            }
          })
        }

        // If reviewing, show correct answers
        if(
          response &&
          Object.keys(response).length &&
          getUrlSegment(1) === 'assessment-review' &&
          userData.role.toLowerCase !== 'learner'
        ) {
          options.htmlElement.className += ' has-response'

          if(options.question.choices) {
            options.question.choices.forEach((choice, i) => {
              //eslint-disable-next-line
              if(choice.itemValue == options.question.correctAnswer) {
                const choiceEl = options.htmlElement.querySelectorAll('label')[i];
                if(choiceEl) {
                  const correctTag = document.createElement('em');
                  correctTag.textContent = translation.correct;
                  correctTag.className = 'sm_sv__tag sm_sv__tag--correct';

                  choiceEl.className += ' sm_sv__correct-choice';
                  choiceEl.append(correctTag);
                }
              }
            });
          }

          if(this.isCorrect(options.question) === true) {
            options.htmlElement.className += ' sm_sv__q-correct';
          } else if(this.isCorrect(options.question) === false) {
            options.htmlElement.className += ' sm_sv__q-incorrect';

            const incorrectChoice = options.htmlElement.querySelector('input:checked')?.parentNode

            if(incorrectChoice) {
              const incorrectTag = document.createElement('em');
              incorrectTag.textContent = translation.incorrect;
              incorrectTag.className = 'sm_sv__tag sm_sv__tag--incorrect';

              incorrectChoice.append(incorrectTag);
            }
          }
        }
      });
      // --------------- end custom rendering -------------------
    }

    if(ready) {
      if(assessment) {
        return (
          <main
            id="main-content"
            className={`
              page sm_sv_wrap${
                getUrlSegment(1) === 'assessment-review' ? ' sm_sv_wrap--readonly' : ''
              }
            `}
            tabIndex="-1"
            aria-labelledby="page-title"
          >

            <head>
              <title>{assessment.name} | SmarterMeasure</title>
              <script src="https://polyfill.io/v3/polyfill.min.js?features=es6"></script>
            </head>

            {getUrlSegment(1) === 'assessment-review' &&
              <nav className="breadcrumb">
                <div className="wrap">
                  <Link to="/">
                    <a className="back breadcrumb__back"
                       href="#/"
                    >
                      {translation.results_back}
                    </a>
                  </Link>
                </div>
              </nav>
            }

            <div className="wrap">
              <header className="page__head">
                <h1 id="page-title" className="page__title">{assessment.name}</h1>
              </header>

              <Survey.Survey
                model={model}
                onAfterRenderPage={(surveyResponse) => {
                  this.renderMathJax();
                  this.setState({
                    surveyResponse
                  }, () => {
                    this.renderSave(surveyResponse);
                  })
                }}
                onCompleting={this.confirmComplete}
                onComplete={(surveyResponse) => this.handleSubmit(surveyResponse, true)}
                onPartialSend={(surveyResponse) => this.handleSubmit(surveyResponse, false, true)}
                onValueChanged={(surveyResponse) => this.handleSubmit(surveyResponse, false, true)}
                sendResultOnPageNext={true}
                showCompletedPage={false}
                data={response}
                mode={getUrlSegment(1) === 'assessment-review' ? 'display' : ''}
              />
            </div>
          </main>
        )
      }
    }
    return null;

  }
})

export default translate('Assessment')(Assessment);