/**
 * Renders a select form control
 */

import React from 'react';

const Select = class Select extends React.Component {
  constructor() {
    super();

    // Create refs
    this.select = React.createRef();
  }

  handleChange = () => {
    const { id, onChange } = this.props;

    if(onChange) {
      onChange(id, this.select.current.value);
    }
  }

  render() {
    const {
      defaultValue,
      errorMsg,
      hideLabel,
      id,
      label,
      options,
      placeholder,
      required
    } = this.props;

    return (
      <React.Fragment>
        {label &&
          <label
            id={`${id}-label`}
            className={`form__lbl${required ? ' required' : ''}${hideLabel ? ' meta' : ''}`}
          >
            {label}
          </label>
        }

        <label
          className="select"
        >
          <select
            id={id}
            onChange={this.handleChange}
            required={required ? true : false}
            aria-required={required ? 'true' : 'false'}
            data-errormsg={errorMsg}
            ref={this.select}
            defaultValue={defaultValue || ''}
            {...label && { "aria-labelledby": `${id}-label` }}
          >
            {placeholder &&
              <option value="">{placeholder}</option>
            }

            {options.map(
              (option, i) =>
              <option key={i} value={option.value}>
                {option.label}
              </option>
            )}
          </select>
        </label>
      </React.Fragment>
    )
  }
}

export default Select;