import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../translate/Translate';

// Helpers
import { getLocalData, storeLocalData } from '../../helpers';

// Components
import Dropdown from '../common/Dropdown';

// Icons
import * as icons from './Icons';

const Accessibility = observer(class Accessibility extends React.Component {
  componentDidMount() {
    const { AppStore } = this.props.store;
    const { defaultFontSize } = AppStore;
    const fontSize = getLocalData('accessibility_fontSize');
    const highContrast = getLocalData('accessibility_highContrast');
    const openDyslexia = getLocalData('accessibility_openDyslexia');

    // Check for store accessibility preferences
    if(fontSize && fontSize !== defaultFontSize) {
      AppStore.fontSize = parseInt(fontSize, 10);
    }

    if(highContrast && highContrast === 'true') {
      AppStore.highContrast = true;
    } else {
      AppStore.highContrast = false;
    }

    if(openDyslexia && openDyslexia === 'true') {
      AppStore.dyslexic = true;
    } else {
      AppStore.dyslexic = false;
    }
  }

  /**
   * adjustFontSize
   * @param {integer} amount
   */
  adjustFontSize(amount) {
    const { AppStore } = this.props.store;
    const { defaultFontSize } = AppStore;

    if(amount) {
      AppStore.fontSize += amount;
    } else {
      AppStore.fontSize = defaultFontSize;
    }

    storeLocalData('accessibility_fontSize', AppStore.fontSize);
  }

  loadStylesheet(href) {
    const { onStylesLoaded } = this.props;
    const existingStylesheet = document.querySelector(`link[href="${href}"]`);

    if(existingStylesheet) {
      return null;
    }

    const mainStyles = document.querySelector('#main-styles');

    if(mainStyles) {
      mainStyles.remove();
    }

    const stylesheet = document.createElement('link');
    stylesheet.id = 'main-styles';
    stylesheet.href = href;
    stylesheet.media = 'screen';
    stylesheet.rel = 'stylesheet';
    stylesheet.type = 'text/css';
    stylesheet.onload = onStylesLoaded;

    document.head.append(stylesheet);

    // TEMP: hardcode WGU stylesheet
    if(href === '../../css/screen.css') {
      const wguCss = document.createElement('link');
      wguCss.id = 'wgu-styles';
      wguCss.href = '../../css/wgu.css';
      stylesheet.media = 'screen';
      wguCss.rel = 'stylesheet';
      wguCss.type = 'text/css';
      wguCss.onload = onStylesLoaded;

      document.head.append(wguCss);
    } else {
      const wguStyles = document.querySelector('#wgu-styles');

      if(wguStyles) {
        wguStyles.remove();
      }
    }
  }

  render() {
    const { store, translation } = this.props;
    const { AppStore } = store;
    const { defaultFontSize, dyslexic, fontSize, highContrast } = store.AppStore;

    const options = [
      {
        title: highContrast ? translation.options.high_contrast_disable  : translation.options.high_contrast_enable,
        action: () => {
          AppStore.highContrast = !AppStore.highContrast;
          storeLocalData('accessibility_highContrast', AppStore.highContrast);
        },
        icon: <icons.contrast />
      }, {
        title: translation.options.increase_size,
        action: () => this.adjustFontSize(2),
        icon: <icons.textSizeUp />
      }, {
        title: translation.options.decrease_size,
        action: () => this.adjustFontSize(-2),
        icon: <icons.textSizeDown />
      }, {
        title: translation.options.reset_size,
        action: () => this.adjustFontSize(),
        icon: <icons.textSizeReset />
      }, {
        title: dyslexic ? translation.options.dyslexia_font_disable : translation.options.dyslexia_font_enable,
        action: () => {
          AppStore.dyslexic = !AppStore.dyslexic;
          storeLocalData('accessibility_openDyslexia', AppStore.dyslexic);
        },
        icon: <icons.dyslexic />
      }
    ];

    return (
      <div>
        <head>
          {dyslexic &&
            <link
              href="../../../css/open-dyslexic.css"
              rel="stylesheet"
              type="text/css"
              media="screen"
            />
          }

          {!highContrast &&
            this.loadStylesheet('../../css/screen.css')
          }

          {highContrast &&
            this.loadStylesheet('../../css/high-contrast.css')
          }

          <link
            href="../../../css/print.css"
            rel="stylesheet"
            type="text/css"
            media="print"
          />
        </head>

        <Dropdown
          {...this.props}
          className="icon--md"
          icon={<icons.accessibility />}
          id="accessibility-options"
          options={options}
          triggerText={translation.label}
          triggerClassName="dropdown__trigger--invisible"
        />

        {fontSize !== defaultFontSize &&
          <style jsx global>{`
            html {
              font-size: ${fontSize / 16}rem
            }
          `}</style>
        }
      </div>
    )
  }
})

export default translate('Accessibility')(Accessibility);