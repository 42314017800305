/**
 * Renders the QTI Importer
 */

import React from 'react';

// Components
import ImportAssessmentUpload from './ImportAssessmentUpload';
import ImportAssessmentCompetencies from './ImportAssessmentCompetencies';
import ImportAssessmentConfirm from './ImportAssessmentConfirm';
import ImportAssessmentDetails from './ImportAssessmentDetails';

// Translation
import translate from '../translate/Translate';

export const ImportAssessment = class ImportAssessment extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    addToGroup: false,
    assessmentDetails: '',
    assessmentGroupKey: '',
    assessmentName: '',
    assessmentSid: '',
    assessmentGroupType: 'createNew',
    assessmentTemplate: {},
    error: false,
    key: '',
    maxAttempts: null,
    metaData: {},
    step: 1,
    success: true
  });

  importAnotherFile = () => {
    const initialState = this.getInitialState();
    this.setState(initialState);
  }

  componentDidMount() {
    const { AssessmentStore } = this.props.store;
    AssessmentStore.listAssessmentTemplates();
    AssessmentStore.listAssessmentGroups();
  }

  goToStep = (step) => {
    if (step) {
      this.setState({
        step: step
      })
    }
  }

  handleChange = input => event => {
    const value = event.target.type === 'checkbox' ?
      event.target.checked
      : event.target.type === 'number' ?
        parseInt(event.target.value)
          : event.target.value;
    this.setState({
      [input]: value
    });
  }

  updateCompetencies = input => event => {
    const value = event.target.value;
    this.setState(state => {
      const competencies = state.metaData.competencies.map((item, i) => {
        if (i === input) {
          return {
            ...item,
            label: value
          };
        } else {
          return item;
        }
      });

      return {
        metaData: {
          ...this.state.metaData,
          competencies
        }
      };
    });
  }

  updateAssessmentTemplate = (id, value) => {
    const { AssessmentStore } = this.props.store;
    this.setState(state => {
      const templates = AssessmentStore.assessmentTemplates.filter(template => template.title === value);
      return {
        assessmentTemplate: templates.length ? templates[0].template : {}
      }
    });
  }

  handleFileChange = (file) => {
    this.setState({
      file
    });
  }

  parseQti = () => {
    const { key, file } = this.state;
    const { AssessmentStore } = this.props.store;

    const callback = (result) => {
      this.setState({
        metaData: result.metaData,
        assessmentSid: result.assessmentSid
      }, () => {
        if (result.assessmentSid) {
          this.goToStep(3);
        } else {
          this.goToStep(2);
        }
      });
    }

    AssessmentStore.parseQti(key, file, callback);
  }

  processQti = () => {
    const {
      addToGroup,
      assessmentDetails,
      assessmentGroupKey,
      assessmentGroupName,
      assessmentGroupType,
      assessmentName,
      assessmentSid,
      assessmentTemplate,
      existingKey,
      file,
      key,
      maxAttempts,
      metaData
     } = this.state;
    const { AssessmentStore } = this.props.store;

    const createAssessmentGroup = addToGroup && assessmentGroupType === 'createNew';
    const groupKey = addToGroup && assessmentGroupType === 'createNew' ?
      assessmentGroupKey
      : assessmentGroupType === 'useExisting' ?
        existingKey
        : null

    const payload = {
      assessmentKey: key,
      ...(!assessmentSid && {
        assessmentName,
        assessmentDetails,
        assessmentTemplate,
        maxAttempts,
        createAssessmentGroup,
        ...(groupKey && { assessmentGroupKey: groupKey }),
        ...(addToGroup && assessmentGroupType === 'createNew' && {
          assessmentGroupName
        })
      }),
      ...(assessmentSid && {
        assessmentSid
      }),
      metaData
    }

    const callback = () => {
      this.goToStep(4);
    }

    AssessmentStore.processQti(payload, file, callback);
  }

  render() {
    const {
      addToGroup,
      assessmentDetails,
      assessmentGroupKey,
      assessmentGroupType,
      assessmentName,
      assessmentSid,
      assessmentTemplate,
      key,
      maxAttempts,
      metaData,
      step
     } = this.state;

    const values = {
      addToGroup,
      assessmentDetails,
      assessmentGroupKey,
      assessmentGroupType,
      assessmentName,
      assessmentSid,
      assessmentTemplate,
      key,
      maxAttempts,
      metaData
    };

    switch(step) {
    case 1:
      return (
        <ImportAssessmentUpload
          {...this.props}
          component="ImportAssessmentUpload"
          handleChange={this.handleChange}
          handleFileChange={this.handleFileChange}
          onSubmit={this.parseQti}
          values={values}
        />
      );

    case 2:
      return (
        <ImportAssessmentDetails
          {...this.props}
          component="ImportAssessmentDetails"
          goToStep={this.goToStep}
          handleChange={this.handleChange}
          updateAssessmentTemplate={this.updateAssessmentTemplate}
          values={values}
        />
      );

    case 3:
      return (
        <ImportAssessmentCompetencies
          {...this.props}
          component="ImportAssessmentCompetencies"
          handleChange={this.handleChange}
          updateCompetencies={this.updateCompetencies}
          onSubmit={this.processQti}
          values={values}
        />
      );

    case 4:
      return (
        <ImportAssessmentConfirm
          {...this.props}
          component="ImportAssessmentConfirm"
          importAnotherFile={this.importAnotherFile}
          success={this.state.success}
          error={this.state.error}
          values={values}
        />
      );
      default:
        return;
    }
  }
}

export default translate('ImportAssessment')(ImportAssessment);
