import { observable, action } from 'mobx';
import { api, lhApi, spApi } from '../config';

// Helpers
import { formatQuery, getData, getUrlSegment, storeData } from '../helpers';

// AppStore (used for loading, error throwing, etc.)
import AppStore from './AppStore';

let obx = observable({
  domainName: null,
  installSid: null,
  token: null,
  cssUrl: null,
  userData: { role: '' },
  signOnData: null,
  targetUserData: {},
  targetUserId: null,
  timeZone: null,
  assessmentGroupSid: null,

  /**
   * resetUserData - Clears out all user data from store and session storage
   */
  resetUserData: action(() => {
    obx.domainName = null;
    obx.installSid = null;
    obx.token = null;
    obx.cssUrl = null;
    obx.userData = { role: '' };
    obx.signOnData = null;
    obx.timeZone = null;
    obx.assessmentGroupSid = null;

    sessionStorage.removeItem('iam');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('domainName');
  }),

  /**
   * getUserTimeZone - Uses the Intl API to determine the client machine’s time zone
   */
  getUserTimeZone: action(() => {
    obx.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }),

  /**
   * getAccountInstall - Gets install information for a given account domain
   * @param {string} domainName
   */
  getAccountInstall: action(domainName => {
    AppStore.startLoading('getAccountInstall');

    obx.domainName = domainName;
    storeData('domainName', domainName);
    const url = `/installs/${domainName}`;

    spApi.get(url)
      .then(response => {
        Object.keys(response.data).forEach(key => {
          obx[key] = response.data[key]
        });

        AppStore.finishLoading('getAccountInstall');
        return response.data;
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * login
   * @param {object} payload
   * @param {string} redirect
   */
  login: action((payload, redirect) => {
    AppStore.startLoading('login');

    const url = `/installs/${obx.installSid}/login`;

    api.post(url, payload)
      .then(response => {
        obx.userData = response.data;

        storeData('userData', JSON.stringify(obx.userData));

        if(response.data.authentication) {
          storeData('token', response.data.authentication.token);
          storeData('iam', JSON.stringify(response.data.authentication));
        }

        AppStore.finishLoading('login');

        if(redirect) {
          window.location = (redirect);
        } else {
          window.location =('/');
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * logout
   */
  logout: action(() => {
    AppStore.startLoading('logout');

    const url = `/logout`;

    api.get(url)
      .then(() => {
        AppStore.finishLoading('logout');

        obx.resetUserData();

        let domainName = obx.domainName;

        if(!domainName) {
          domainName = getData('domainName');
        }

        if(domainName) {
          window.location = (`/sign-in?account=${domainName}`);
        } else {
          window.location = ('/sign-in');
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * Get all user data for a user
   *
   * @function getUserData
   * @param {string} userSid
   * @param {function} callback
   */
  getUserData: (userSid = obx.userData.userSid, callback) => {
    AppStore.startLoading('getUserData');

    const url = `/installs/${obx.installSid}/users/${userSid}`

    api.get(url)
      .then(response => {
        if(callback) {
          callback(response.data)
        }

        AppStore.finishLoading('getUserData');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  },

  /**
   * getSignOnData - Gets stored user sign on data using a token
   * @param {function} callback
   */
  getSignOnData: action(callback => {
    // callback();
    // return;

    AppStore.startLoading('getSignOnData');

    const url = `/signon/data`

    lhApi.get(url)
      .then(response => {
        obx.signOnData = response.data;

        if(obx.userData.role.toLowerCase() === 'learner') {
          // Validate assessment group key for students+
          obx.validateAssessmentGroupKey(callback);
        } else if(obx.userData.role.toLowerCase() === 'instructor') {
          // Make sure a target user id was included
          let targetUserId = obx.signOnData.data.custom_target_user_id;

          if(!targetUserId) {
            targetUserId = obx.signOnData.data.customTargetUserId;
          }

          if(!targetUserId) {
            obx.flagSignOnData('targetUser');
          } else {
            obx.targetUserId = targetUserId;

            // Get target user enrollment data
            obx.getEnrollmentData(callback);
          }
        }

        // Make sure all user IDs are in session storage
        if(obx.targetUserId) {
          obx.userData.custom_target_user_id = obx.targetUserId;
        }

        if(obx.signOnData.data.userId) {
          obx.userData.user_id = obx.signOnData.data.userId;
        } else if(obx.signOnData.data.user_id) {
          obx.userData.user_id = obx.signOnData.data.user_id;
        }

        storeData('userData', JSON.stringify(obx.userData));

        AppStore.finishLoading('getSignOnData');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * getEnrollmentData
   * @param {function} callback
   */
  getEnrollmentData: action(callback => {
    AppStore.startLoading('getEnrollmentData');

    const url = `/installs/${obx.installSid}/users?lmsId=${obx.targetUserId}`

    lhApi.get(url)
      .then(response => {
        if(response.data.results.length > 0) {
          response.data.results[0].userSid = response.data.results[0].sid;
          obx.targetUserData = response.data.results[0];

          if(callback) {
            callback();
          }
        } else {
          obx.flagSignOnData('userMissing');
        }

        AppStore.finishLoading('getEnrollmentData');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * flagSignOnData - Alerts the user if the assessment group or assessments keys provided
   * in their signOnData is invalid
   */
  signOnError: null,

  flagSignOnData: action(type => {
    obx.signOnError = type;

    window.location = (`/config-error`);
  }),

  /**
   * validateAssessmentGroupKey - Checks validity of assessment group key from signOnData
   * @param {function} callback
   */
  assessmentGroup: null,

  validateAssessmentGroupKey: action(callback => {
    AppStore.startLoading('validateAssessmentGroupKey');

    let url = null;

    let assessmentGroupKey = obx.signOnData.data.custom_assessment_group_key;

    if(!assessmentGroupKey) {
      assessmentGroupKey = obx.signOnData.data.customAssessmentGroupKey;
    }

    let assessmentKey = obx.signOnData.data.custom_assessment_key;

    if(!assessmentKey) {
      assessmentKey = obx.signOnData.data.customAssessmentKey;
    }

    if(assessmentGroupKey) {
      url = `/installs/${obx.installSid}/assessmentGroups?key=${assessmentGroupKey}`;
    } else if(assessmentKey) {
      url = `/installs/${obx.installSid}/assessmentGroups?assessmentKey=${assessmentKey}`;
    } else {
      assessmentGroupKey = 'no_assessment_group_key_stored';
    }

    api.get(url)
      .then(response => {
        if(!response.data.results.length) {
          obx.flagSignOnData('assessmentGroupKey');
        } else if(callback) {
          obx.assessmentGroupSid = response.data.results[0].assessmentGroupSid;
          obx.assessmentGroup = response.data.results[0];
          obx.checkInProgressAssessments(callback);
          // obx.validateAssessmentKey(callback);
        }

        AppStore.finishLoading('validateAssessmentGroupKey');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * validateAssessmentKey - Checks validity of assessment key from signOnData
   * @param {function} callback
   */
  validateAssessmentKey: action(callback => {
    AppStore.startLoading('validateAssessmentKey');

    let assessmentKey = obx.signOnData.data.custom_assessment_key;

    if(!assessmentKey) {
      assessmentKey = obx.signOnData.data.customAssessmentKey;
    }

    if(!assessmentKey) {
      assessmentKey = 'no_assessment_key_stored';
    }

    const url = `/installs/${obx.installSid}/assessments?key=${assessmentKey}`;

    api.get(url)
      .then(response => {
        if(!response.data.results.length) {
          obx.flagSignOnData('assessmentKey');
        } else if(callback) {
          obx.checkInProgressAssessments(callback);
        }

        AppStore.finishLoading('validateAssessmentKey');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * checkInProgressAssessments - Checks if the user has any assessments that are incomplete
   * @param {function} callback
   */
  inProgress: [],

  checkInProgressAssessments: action(callback => {
    AppStore.startLoading('checkInProgressAssessments');

    const query = {
      completionState: 'latest-incomplete',
      userSid: obx.userData.userSid,
      assessmentKey: obx.signOnData.data.customAssessmentKey
    };

    const url = `/installs/${obx.installSid}/attempts${formatQuery(query)}`;

    api.get(url)
      .then(response => {
        obx.inProgress = response.data.results;

        // Also check for completed assessments
        obx.checkCompletedAssessments(callback);

        AppStore.finishLoading('checkInProgressAssessments');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * checkCompletedAssessments - Checks if the user has any assessments that are complete
   * @param {function} callback
   */
  completed: [],

  checkCompletedAssessments: action(callback => {
    AppStore.startLoading('checkCompletedAssessments');

    const query = {
      completionState: 'all-complete',
      userSid: obx.userData.userSid,
      assessmentKey: obx.signOnData.data.customAssessmentKey
    };

    const url = `/installs/${obx.installSid}/attempts${formatQuery(query)}`;

    api.get(url)
      .then(response => {
        obx.completed = response.data.results;

        if(callback) {
          callback();
        }

        AppStore.finishLoading('checkCompletedAssessments');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * checkTtl - Checks how much time is remaining on the current token
   */
  checkTtl: action(() => {
    const url = `/ttl`;

    api.get(url)
      .then(response => {
        if(response.data.ttl <= 120 && response.data.ttl > 0) {
          // If 2 minutes or less remain, show a warning
          AppStore.showDialog(
            AppStore.translation.ExpireWarning,
            'confirm',
            obx.extendSession
          );

          AppStore.dialogCountdown = response.data.ttl;

          AppStore.dialogOnExpire = () => {
            window.location = '/session-expired';
            AppStore.closeDialog();
          }
        } else if(response.data.ttl <= 0 && getUrlSegment(1) !== 'sign-in') {
          window.location = 'session-expired';
        } else if(getUrlSegment(1) !== 'sign-in') {
          // Otherwise, check again later
          setTimeout(() => {
            obx.checkTtl();
          }, (response.data.ttl - 120) * 1000);
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * extendSession - Makes an arbitrary API call to extend token life
   */
  extendSession: action(() => {
    const url = '/check';

    api.get(url)
      .then(() => {
        // Start TTL check back up
        obx.checkTtl();
      })
      .catch(error => {
        AppStore.throwError(error);
      });
  })
});

export default obx;