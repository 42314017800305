// Import React
import React from 'react';

import {config} from './app/config'

import {PostHogProvider} from "posthog-js/react"
// Helpers
import { getUrlSegment } from './app/helpers';

// Import dependencies
import { render } from 'react-dom';

// Import styles
import './css/screen.css'

// Import components
import App from './app/components/App';

// Import stores
import store from './app/stores';

// List of paths that don’t require TTL check
const noTtlUrls = ['session-expired', 'sign-in', 'unauthorized'];

// Main app component
const app =
  <PostHogProvider
    apiKey={config.posthogApiKey}
    options={{api_host: config.posthogHost}}

><App skipTtl={noTtlUrls.indexOf(getUrlSegment(1)) > -1} store={store} /></PostHogProvider>;

// Root DOM element
const rootEl = document.querySelector('#app');

render(app, rootEl);
