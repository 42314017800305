/**
 * Renders a dropdown menu
 * @property {array} options - Array of objects used to populate the dropdown options
 * @property {string} triggerText - The text for the dropdown trigger
 */

import React from 'react';
import { observer } from 'mobx-react';

// Helpers
import { isDescendant } from '../../helpers';

const Dropdown = observer(class Dropdown extends React.Component {
  constructor() {
    super();

    // Create refs
    this.dropdown = React.createRef();
    this.dropdownControl = React.createRef();
    this.dropdownOptions = React.createRef();

    // Set the initial state
    this.state = {
      expanded: false,
      focusedOption: -1,
      longForm: false,
      pushLeft: false
    };

    this._options = [];
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    // Hide when anything else is clicked
    document.addEventListener('click', (e) => {
      if(this.state.expanded && !isDescendant(this.dropdown.current, e.target) && this._isMounted) {
        this.setState({
          expanded: false,
          focusedOption: -1
        });
      }
    });

    // Array of dropdown option elements used for focus management
    this._options = this.dropdownOptions.current.getElementsByClassName('dropdown__option');
  }

  componentDidUpdate() {
    // Check if the dropdown is too far right
    const dropdown = this.dropdownOptions.current;

    if(dropdown.getBoundingClientRect().right >= window.innerWidth && this._isMounted) {
      this.setState({
        pushLeft: true
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * handleIframe - Opens links in an iframe in a drawer
   * @param {event} e
   * @param {object} option
   * @param {string} option.title - The text for the option
   * @param {string} option.url - The URL to point the option to if it is a link
   * @param {boolean} option.iframe - Makes the option link open in a drawer with an iframe
   * @param {function} option.action - The function to be triggered if the option is a button
   */
  handleIframe(e, option) {
    e.preventDefault();

    const { AppStore } = this.props.store;

    AppStore.drawerUrl = option.url;
    AppStore.drawerTitle = option.title;
  }

  /**
   * handleAction - Handles the callback function for an option that is a button
   * @param {function} action - The function to be triggered
   */
  handleAction(action) {
    action();

    this.setState({
      expanded: false
    });
  }

  /**
   * renderOption - Renders the individual options in a dropdown
   * @param {object} option
   * @param {string} option.title - The text for the option
   * @param {string} option.url - The URL to point the option to if it is a link
   * @param {boolean} option.iframe - Makes the option link open in a drawer with an iframe
   * @param {function} option.action - The function to be triggered if the option is a button
   */
  renderOption(option) {
    const { longForm } = this.state;
    let optionItem = null;

    if(option.title && option.title.length > 35 && !longForm && this._isMounted) {
      this.setState({
        longForm: true
      });
    }

    if(option.url && option.iframe) {
      // iframe link
      optionItem = (
        <a
          className="dropdown__option"
          href={option.url}
          onClick={(e) => this.handleIframe(e, option)}
        >
          {option.icon}
          {option.title}
        </a>
      );
    } else if(option.url) {
      // Regular link
      optionItem = (
        <a
          className="dropdown__option"
          href={option.url}
        >
          {option.icon}
          {option.title}
        </a>
      );
    } else if(option.action) {
      // Action button
      optionItem = (
        <button
          className="dropdown__option"
          type="button"
          onClick={() => this.handleAction(option.action)}
        >
          {option.icon}
          {option.title}
        </button>
      );
    }

    return optionItem;
  }

  toggle = () => {
    if (this.state.expanded) {
      this.hide();
    } else {
      this.expand(0);
    }
  }

  expand = (index) => {
    if(this._isMounted) {
      this.setState({
        expanded: true
      }, () => {
        this.focusOption(index);
      });
    }
  }

  focusOption = (index) => {
    if(this._isMounted) {
      this.setState({
        focusedOption: index
      }, () => {
        this._options[index].focus();
      });
    }
  }

  hide = () => {
    if(this._isMounted) {
      this.setState({
        expanded: false,
        focusedOption: -1
      });
    }
  }

  checkKey = (e) => {
    const first = 0;
    const last = this._options.length - 1;
    const current = this.state.focusedOption;

    const ESCAPE = 27;
    const END = 35;
    const HOME = 36;
    const TAB = 9;
    const DOWN_ARROW = 40;
    const UP_ARROW = 38;

    switch(e.keyCode) {
      case ESCAPE:
        this.hide();
        this.dropdownControl.current.focus();
        break;
      case HOME:
        if (this.state.expanded) {
          e.preventDefault();
          this.focusOption(first);
        }
        break;
      case END:
        if (this.state.expanded) {
          e.preventDefault();
          this.focusOption(last);
        }
        break;
      case TAB:
        if (this.state.expanded) {
          this.hide();
        }
        break;
      case UP_ARROW:
        e.preventDefault();
        if (!this.state.expanded) {
          this.expand(last);
        } else {
          if (current > 0) {
            this.focusOption(current - 1);
          } else {
            this.focusOption(last);
          }
        }
        break;
      case DOWN_ARROW:
        e.preventDefault();
        if (!this.state.expanded) {
          this.expand(first);
        } else {
          if (current < last) {
            this.focusOption(current + 1);
          } else {
            this.focusOption(first);
          }
        }
        break;
      default:
        break;
    }
  }

  render() {
    const {
      className,
      disabled,
      hideTriggerText,
      icon,
      id,
      options,
      triggerClassName,
      triggerText
    } = this.props;

    return (
      <div
        id={id}
        className={`dropdown${
          className ? ` ${className}` : ''
        }${
          this.state.expanded ? ' dropdown--open' : ''
        }${
          this.state.longForm ? ' dropdown--long' : ''
        }${
          this.state.pushLeft ? ' dropdown--push-left' : ''
        }`}
        aria-controls={`${id}-dropdown`}
        ref={this.dropdown}
        onKeyDown={this.checkKey}
      >
        <button
          className={`dropdown__trigger ${triggerClassName ? triggerClassName : ''}`}
          type="button"
          onClick={this.toggle}
          disabled={disabled ? true : false}
          ref={this.dropdownControl}
        >
          {hideTriggerText &&
            <span className="meta">{triggerText}</span>
          }

          {icon}

          {!hideTriggerText &&
            <span>{triggerText}</span>
          }
        </button>

        <ul
          id={`${id}-dropdown`}
          className="dropdown__options"
          aria-expanded={this.state.expanded ? true : false}
          hidden={this.state.expanded ? false : true}
          ref={this.dropdownOptions}
        >
          {options.map(
            (option, i) =>
            <li key={i}>
              {this.renderOption(option)}
            </li>
          )}
        </ul>
      </div>
    );
  }
})

export default Dropdown;