/**
 * Renders a drawer element
 * @property {string} id - The ID attribute for the tooltip element
 * @property {string} content - The tooltip content
 */

import React from 'react';

// Translation
import translate from '../translate/Translate';

const Duration = class Duration extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      duration: 0
    };

    // Create refs
    this.duration = React.createRef();
    this.durationMins = React.createRef();
    this.durationHrs = React.createRef();
  }

  componentDidMount() {
    const { defaultValue } = this.props;

    if(defaultValue) {
      this.setState({
        duration: defaultValue
      });
    }
  }

  handleChange = () => {
    const { onChange } = this.props;
    const hrs = parseInt(this.durationHrs.current.value, 10);
    const mins = parseInt(this.durationMins.current.value, 10);

    this.setState({
      duration: hrs + mins
    });

    if(onChange) {
      onChange(hrs + mins);
    }
  }

  render() {
    const { label, translation } = this.props;
    const { duration } = this.state;

    return (
      <div className="duration">
        <label
          className="form__lbl"
          htmlFor="duration"
        >
          {label ? label : translation.label}
        </label>

        <input
          type="hidden"
          id="duration"
          ref={this.duration}
          value={duration ? duration : 0}
          readOnly
        />

        <label className="select">
          <select
            id="duration-hours"
            onChange={this.handleChange}
            ref={this.durationHrs}
            value={duration ? Math.floor(duration / 60) * 60 : 0}
          >
            {Array.from({length: 11},
              (key, i) =>
              <option key={i} value={i * 60}>
                {i} {i === 1 ? translation.hour : translation.hours}
              </option>
            )}
          </select>
        </label>

        <label className="select">
          <select
            id="duration-hours"
            onChange={this.handleChange}
            ref={this.durationMins}
            value={duration ? (duration % 60) : 0}
          >
            {Array.from({length: 60},
              (key, i) =>
              <option key={i} value={i}>
                {i < 10 ? `0${i}` : i} {i === 1 ? translation.minute : translation.minutes}
              </option>
            )}
          </select>
        </label>
      </div>
    )
  }
}

export default translate('Duration')(Duration);