/**
 * Determines which home view to render
 * based on the user’s role
 */

import React from 'react';

// Components
import { observer } from 'mobx-react';

import AdminHome from './AdminHome';
import InstructorHome from './InstructorHome';
import StudentHome from './StudentHome'

const Home = observer(class Home extends React.Component {
  render() {
    const { userData } = this.props.store.UserStore;
    let View = null;

    switch(userData.role.toLowerCase()) {
      case 'administrator':
        View = AdminHome;
        break;

      case 'learner':
        View = StudentHome;
        break;

      case 'instructor':
        View = InstructorHome;
        break;

      default:
        break;
    }

    if(View) {
      return (
        <View {...this.props} />
      );
    }

    else{
      return null;
    }
  }
})

export default Home;
