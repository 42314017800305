/**
 * Renders the survey details page
 */

import React from "react";

// Icons
import { checkValidity } from "../../helpers";
import translate from "../translate/Translate";

export const SurveyDetails = class SurveyDetails extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {};

    // Create refs
    this.name = React.createRef();
    this.description = React.createRef();
    this.pageViews = React.createRef();
    this.timeBetween = React.createRef();
    this.key = React.createRef();
  }

  /**
  * Takes in a payload and returns it with the values of the form fields.
  * @param {object} payload
  * @param {string} payload.name - Name of the survey
  * @param {string} payload.key - Key to be used for the survey
  * @param {number} payload.triggerJson.pageViewsBeforeInitialSurvey - No. of page views before we present survey
  * @param {number} payload.triggerJson.daysUntilNextSurvey - No. of days between survey views
  * @param {string} payload.description - Description of survey or context about survey
  */
  formatPayload = (payload) => {

    payload.name = this.name.current.value || '';
    payload.key = this.key.current.value || '';
    payload.triggerJson.pageViewsBeforeInitialSurvey = this.pageViews.current.value || 0;
    payload.triggerJson.daysUntilNextSurvey = this.timeBetween.current.value || 0;
    payload.description = this.description.current.value || '';

    if(this.description.current.value) {
      payload.description = this.description.current.value;
    }

    return payload;
  }

  handleUpdate = () => {
    const { survey, onUpdate } = this.props;

    const payload = this.formatPayload(survey);

    if(onUpdate) {
      onUpdate(payload);
    }
  }

  handleSubmit = () => {
    const {
      onSubmit,
      onSuccess,
      store,
      translation,
      survey
    } = this.props;
    const { AppStore, SurveyStore } = store;

    const payload = this.formatPayload({
      triggerJson: {},
      description: ''
    })

    this.props.parent.setState({setupComplete: true})

    const callback = (data) => {
      this.props.parent.setState({surveySid: data});
    }

    SurveyStore.createSurvey(survey, callback)

    if(onSubmit) {
      const callback = (data) => {
        AppStore.toast = translation.success;
        onSuccess(data);
      }

      onSubmit(payload, callback);
    }
  }

  render() {
    const { survey, onClose, onUpdate, translation, newSurvey, setupComplete } = this.props;

    const fields = [
      {
        id: "name",
        ref: this.name,
        translation: translation.name,
        type: "text",
        required: true,
        defaultValue: survey?.name || "",
        class: "half",
      },
      {
        id: "key",
        ref: this.key,
        translation: translation.key,
        type: "text",
        required: true,
        defaultValue: survey?.key || "",
        class: "half"
      },
      {
        id: "description",
        ref: this.description,
        translation: translation.details,
        type: "textarea",
        required: true,
        defaultValue: survey?.description || "",
      },
    ];

    return (
      <form
        className="form panel panel--md panel--solo"
        noValidate
        onSubmit={(e) => checkValidity(e, this.handleSubmit, true)}
        onReset={onClose}
      >
        {/*
            Typically, I would prefer to use a single fieldset
            here and put to form__fields lists in it, but
            we need to use flexbox in this part and Chrome
            has a cool bug that causes flexbox not to work
            on fieldsets so I’m adding a div and dying inside
        */}
        <div className="form__content">
          <fieldset className="field--half">
            <ul className="form__fields">
              {fields.map((field, i) => (
                <li key={i}>
                  <label
                    className={`form__lbl${field.required ? " required" : ""}`}
                    htmlFor={field.id}
                  >
                    {field.translation.label}
                  </label>

                  {field.type !== "textarea" && (
                    <input
                      className={field.class ? field.class : null}
                      type={field.type}
                      id={field.id}
                      required={field.required}
                      aria-required={field.required ? "true" : "false"}
                      data-errormsg={field.translation.error}
                      ref={field.ref}
                      defaultValue={field.defaultValue}
                      onChange={onUpdate ? this.handleUpdate : null}
                    />
                  )}

                  {field.type === "textarea" && (
                    <textarea
                      id={field.id}
                      required={field.required}
                      aria-required={field.required ? "true" : "false"}
                      data-errormsg={field.translation.error}
                      ref={field.ref}
                      defaultValue={field.defaultValue}
                      onChange={onUpdate ? this.handleUpdate : null}
                    />
                  )}
                </li>
              ))}
            </ul>
          </fieldset>
          {/*
              Wanted to have this just put in the map function
              but we needed to use flexbox down here and it was
              not working properly when mapped vs hardcoded
           */}
          <fieldset className="field--half">
            <ul className="form__fields">
              <li className="field--half">
                <label className="form__lbl--half required" htmlFor="page-views">
                  {translation.page_views.label}
                </label>

                <input
                  className="--center"
                  type="number"
                  id="page-views"
                  required={true}
                  aria-required={true}
                  data-errormsg={translation.page_views.error}
                  ref={this.pageViews}
                  defaultValue={survey?.triggerJson.pageViewsBeforeInitialSurvey}
                  onChange={onUpdate ? this.handleUpdate : null}
                  min={1}
                />
              </li>
              <li className="field--half">
                <label
                  className="form__lbl--half required"
                  htmlFor="daysUntilNextSurvey"
                >
                  {translation.time_between.label}
                </label>

                <input
                  className="--center"
                  type="number"
                  id="daysUntilNextSurvey"
                  required={true}
                  aria-required={true}
                  data-errormsg={translation.time_between.error}
                  ref={this.timeBetween}
                  onChange={onUpdate ? this.handleUpdate : null}
                  defaultValue={survey?.triggerJson.daysUntilNextSurvey }
                  min={1}
                />
              </li>
            </ul>
          </fieldset>
        </div>
        {newSurvey && !setupComplete &&
          <div className="panel__action">
            <button className="btn" type="submit">
              {translation.submit}
            </button>
          </div>
        }
      </form>
    );
  }
};

export default translate("SurveyDetails")(SurveyDetails);
