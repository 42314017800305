import { observable, action } from 'mobx';
import { api } from '../config';

// Helpers
import { formatQuery } from '../helpers';

// Dependencies
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

// AppStore (used for loading, error throwing, etc.)
import AppStore from './AppStore';

// UserStore (used to access user data)
import UserStore from './UserStore';

let obx = observable({
  /**
   * formatChartData
   */
  formatChartData: action((data, maxVar, dateFormat) => {
    data.forEach(record => {
      record.label = format(utcToZonedTime(parseISO(record.date), UserStore.timeZone), dateFormat);
      record.value = [record.numTaken];

      if(record.numTaken > obx[maxVar]) {
        obx[maxVar] = Math.ceil(record.numTaken / 5) * 5;
      }

      delete record.date;
      delete record.numTaken;
    });

    return data;
  }),

  /**
   * getAssessmentsTaken
   * @param {string} granularity
   * @param {boolean} getUnique
   */
  assessmentsTaken: null,
  assessmentsTakenMax: 0,

  getAssessmentsTaken: action((query, getUnique) => {

    obx.assessmentsTakenMax = 0;

    const url = `/installs/${UserStore.installSid}/reports/assessmentsTaken${formatQuery(query)}`;

    api.get(url)
      .then(response => {
        let dateFormat = 'MM/dd/yy'

        if(query.granularity === 'month') {
          dateFormat = 'MM/yy'
        }

        obx.assessmentsTaken = obx.formatChartData(response.data.assessmentsTaken, 'assessmentsTakenMax', dateFormat);


        if(getUnique) {
          obx.getUniqueAttempts(query, true, dateFormat);
        }
      })
  }),

  /**
   * getUniqueAttempts
   * @param {string} granularity
   * @param {boolean} appendToTaken
   * @param {string} dateFormat
   */
  uniqueAttempts: null,

  getUniqueAttempts: action((query, appendToTaken, dateFormat) => {
    AppStore.startLoading('getUniqueAttempts');

    const url = `/installs/${UserStore.installSid}/reports/uniqueStudents${formatQuery(query)}`;

    api.get(url)
      .then(response => {
        if(!appendToTaken) {
          obx.uniqueAttempts = obx.formatChartData(response.data.uniqueStudents, 'assessmentsTakenMax', dateFormat);
        } else {
          const attempts = obx.formatChartData(response.data.uniqueStudents, 'assessmentsTakenMax', dateFormat);

          for(let i = 0; i < attempts.length; i++) {
            obx.assessmentsTaken[i].value.push(attempts[i].value[0]);
          }

          obx.uniqueAttempts = attempts;

        }

        AppStore.finishLoading('getUniqueAttempts');
      })
        .catch(error => {
          console.log(error)
          AppStore.finishLoading('getUniqueAttempts');

        })
  }),
  adminHomeCalls: false,

  /**
   * getAttemptCounts - Gets the number of attempts for all assessments or (if provided in query) a single assessmentSid
   * @param {object} query
   */
  attemptCounts: null,

  getAttemptCounts: action(query => {
    AppStore.startLoading('getAttemptCounts');

    obx.attemptCounts = null;

    const url = `/installs/${UserStore.installSid}/reports/numAttempts${formatQuery(query)}`;

    api.get(url)
      .then(response => {
        obx.attemptCounts = obx.attemptCounts = [
          { label: AppStore.translation.AdminHome.assessment_attempts.key.one_attempt, value: response.data.oneAttempt },
          { label: AppStore.translation.AdminHome.assessment_attempts.key.two_attempts, value: response.data.twoAttempts },
          { label: AppStore.translation.AdminHome.assessment_attempts.key.three_attempts, value: response.data.threeplusAttempts }
        ];

        AppStore.finishLoading('getAttemptCounts');
      });
  }),

  /**
   * getAllAverageScores - Gets the average scores for knowledge, confidence, and experience
   * @param {object} query
   */
  averageScores: null,
  averageScoresMax: 0,

  getAllAverageScores: action(query => {
    AppStore.startLoading('getAllAverageScores');

    const baseUrl = `/installs/${UserStore.installSid}/reports/averageScore`;

    // This isn’t great, but the API is built to require a single
    // competency. Since we need to get three competency averages,
    // we need to make three API calls… they are nested because
    // they need to be output in the same order every time.
    const knowledgeQuery = {...query};
    knowledgeQuery.competency = 'knowledge';

    const confidenceQuery = {...query};
    confidenceQuery.competency = 'confidence';

    const experienceQuery = {...query};
    experienceQuery.competency = 'experience';

    const results = [];

    api.get(`${baseUrl}${formatQuery(knowledgeQuery)}`)
      .then(knoweledge => {
        api.get(`${baseUrl}${formatQuery(confidenceQuery)}`)
          .then(confidence => {
            api.get(`${baseUrl}${formatQuery(experienceQuery)}`)
              .then(experience => {
                results.push(knoweledge.data, confidence.data, experience.data);

                // Format the data for the bar chart
                obx.averageScoresMax = 100;

                results.forEach(record => {
                  record.label = record.competency;
                  record.value = [record.average ? Math.round((record.average + Number.EPSILON) * 100) / 100 : 0];

                  if(record.average && record.average > obx.averageScoresMax) {
                    obx.averageScoresMax = Math.ceil(record.average / 5) * 5;
                  }

                  delete record.average;
                  delete record.competency;
                  delete record.dateStart;
                  delete record.dateEnd;
                });

                obx.averageScores = results;

                AppStore.finishLoading('getAllAverageScores');
              });
          });
      })
      .catch(error => {
        console.log(error)
        AppStore.finishLoading('getAllAverageScores');
      });
  }),

  /**
   * getKnowledgeScores
   * @param {object} query
   */
  knowledgeScores: null,

  getKnowledgeScores: action(query => {
    AppStore.startLoading('getKnowledgeScores');

    obx.knowledgeScores = null;

    const url = `/installs/${UserStore.installSid}/reports/knowledgeQuintiles${formatQuery(query)}`;

    api.get(url)
      .then(response => {
        const results = [];

        // Format data for pie chart component
        Object.keys(response.data.scoresByQuintile).forEach(key => {
          results.push({
            label: key,
            value: response.data.scoresByQuintile[key]
          });
        });

        obx.knowledgeScores = results;

        AppStore.finishLoading('getKnowledgeScores');
      })
      .catch(error => {
        console.log(error);
        AppStore.finishLoading('getKnowledgeScores')
    });
  })
});

export default obx;