/**
 * Renders a pie chart
 */

import React from 'react';

const PieChart = class PieChart extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      data: null,
      selected: null,
      total: 0,
      totalCalculated: false
    };

    // Cumulative percent holder
    this.cumulativePercent = 0;
  }

  componentDidMount() {
    this.updateTotal();
  }

  componentDidUpdate(prevProps) {
    this.updateTotal();
  }

  updateTotal = () => {
    const { data } = this.props;
    let total = 0;

    if(data !== this.state.data) {
      data.forEach(slice => {
        total += slice.value;
      });
  
      this.setState({
        data,
        total,
        totalCalculated: true
      });
    }
  }

  getCoordinates = (percent) => {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);

    return [x, y];
  }

  renderSlice = (slice) => {
    // Slice rendering based on https://medium.com/hackernoon/a-simple-pie-chart-in-svg-dbdd653b6936
    const { total } = this.state;
    const percent = slice.value / total; 

    const [startX, startY] = this.getCoordinates(this.cumulativePercent);

    this.cumulativePercent += percent;

    const [endX, endY] = this.getCoordinates(this.cumulativePercent);

    const largeArcFlag = percent > .5 ? 1 : 0;

    const pathData = [
      `M ${startX} ${startY}`,
      `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`,
      `L 0 0`
    ].join(' ');

    return pathData;
  }

  render() {
    const {
      data,
      emptyText,
      id,
      size,
      showPercent,
      title,
      units,
      withKey
    } = this.props;
    const { selected, total, totalCalculated } = this.state;

    this.cumulativePercent = 0;

    if(totalCalculated) {
      return (
        <div className="chart">
          <svg
            aria-describedby={`chart-desc-${id}`}
            aria-labelledby={`chart-title-${id}`}
            className="piechart"
            height={size}
            ref={this.chart}
            role="img"
            viewBox="-1 -1 2 2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title id={`chart-title-${id}`}>{title}</title>
            <desc id={`chart-desc-${id}`}>TODO: Description</desc>
          
            <g>
              {total > 0 && data.map(
                (slice, i) =>
                <path
                  className="piechart__slice"
                  d={this.renderSlice(slice)}
                  key={i}
                  onMouseEnter={e => {
                    this.setState({
                      mouseX: e.clientX,
                      mouseY: e.clientY,
                      selected: `${slice.label}: ${showPercent ? `${Math.round((slice.value / total * 100) * 100) / 100}%` : slice.value}`
                    })
                  }}
                  onMouseLeave={() => this.setState({ selected: null })}
                />
              )}

              {total === 0 &&
                <path
                  className="piechart__slice piechart__slice--empty"
                  d="M 1 -3.429011037612589e-15 A 1 1 0 1 1 1 -1.0779367755043061e-14 L 0 0"
                />
              }
            </g>
          </svg>

          {withKey &&
            <ul className="chart__key chart__key--pie">
              {data.map(
                (slice, i) =>
                <li key={i}>
                  {slice.label}{units ? units : ''}
                </li>
              )}
            </ul>
          }

          {total === 0 &&
            <span className="chart__no-results">{emptyText}</span>
          }

          {selected &&
            <span
              className="chart__tooltip"
              style={{
                left: this.state.mouseX,
                top: this.state.mouseY
              }}
            >
              {selected}
            </span>
          }
        </div>
      )
    }

    return null;
  }
}

export default PieChart;
