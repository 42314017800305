import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../translate/Translate';

const LRIIntro = observer(class LRIIntro extends React.Component {
  render() {
    return (
      <div>
        <header className="page__head">
          <h1 className="page__title">Interpreting your SmarterMeasure score</h1>
        </header>

        <p>SmarterMeasure (formerly known as READI) is a tool to help you assess your readiness for learning in an online and/or technology rich environment. The SmarterMeasure tool is not intended to make an absolute decision as to whether or not you will succeed in your courses. However, your scores will give you an idea of your strengths in the different components SmarterMeasure assesses. It is strongly recommended that you communicate with someone at your school about your SmarterMeasure scores so that you will be informed of the resources for support which your school provides to help you succeed.</p>

        <table>
          <tbody>
            <tr>
              <th scope="row">Student</th>
              <td>Fared Janning</td>
            </tr>
            <tr>
              <th scope="row">Account</th>
              <td>Wagstaff University</td>
            </tr>
            <tr>
              <th scope="row">Date started</th>
              <td>January 7, 2020 10:25 AM CST</td>
            </tr>
            <tr>
              <th scope="row">Date completed</th>
              <td>January 7, 2020 11:18 AM CST</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
})

export default translate('LRIIntro')(LRIIntro);