/**
 * Renders a file upload element
 */

import React from 'react';

// Translation
import translate from '../translate/Translate';

// Icons
import * as icons from '../ui/Icons';

const File = class File extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      files: [],
      status: null
    };

    // Create refs
    this.fileInput = React.createRef();
  }

  addFiles = (fileList) => {
    const { multiple, onAdd } = this.props;
    let files;

    if(multiple === false) {
      files = [];
    } else {
      files = [...this.state.files];
    }

    //eslint-disable-next-line
    [...fileList].map(file => {
      files.push(file);
    });

    this.setState({
      files
    });

    if(onAdd) {
      onAdd(files);
    }
  }

  removeFile = (i) => {
    const { onRemove } = this.props;
    const files = [...this.state.files];

    files.splice(i, 1);

    this.setState({
      files
    });

    if(onRemove) {
      onRemove(files);
    }
  }

  render() {
    const { accept, label, multiple, translation, required } = this.props;
    const { files, status } = this.state;

    return (
      <div
        className={`file${
          status ? ` file--${status}` : ''
        }`}
        onDragEnter={() => this.setState({ status: 'dragover' })}
        onDragLeave={() => this.setState({ status: null })}
        onDrop={() => this.setState({ status: null })}
      >
        <input
          type="file"
          id="test-file"
          className="file__input"
          accept={accept ? accept : ''}
          multiple={typeof multiple !== 'undefined' ? multiple : true}
          required={required ? true : false}
          aria-required={required ? 'true' : 'false'}
          data-errormsg={translation.error}
          ref={this.fileInput}
          onChange={e => this.addFiles(e.target.files)}
          onDragEnter={() => this.setState({ status: 'dragover' })}
          onDragLeave={() => this.setState({ status: null })}
          onDrop={() => this.setState({ status: null })}
        />
        <div className="file__focus"></div>

        {files.length === 0 &&
          <label
            htmlFor="test-file"
          >
            <strong className="file__title">
              {label ? label : translation.label}
            </strong>

            <span className="file__subtitle">
              {translation.instructions}
            </span>

            <span className="file__btn btn--link">
              {translation.fallback}
            </span>
          </label>
        }

        {files.length > 0 &&
          <ul className="file__list">
            {files.map(
              (file, i) =>
              <li key={i}>
                {file.name}

                <button
                  className="btn btn--ghost btn--negative"
                  onClick={() => this.removeFile(i)}
                >
                  <icons.times />
                  <span className="meta">{translation.remove}</span>
                </button>
              </li>
            )}
          </ul>
        }
      </div>
    )
  }
}

export default translate('File')(File);
