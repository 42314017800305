/**
 * Renders Step 3 of 4 of the QTI Import form
 */

import React from 'react';
import Head from '../common/Head'

// Translation
import translate from '../translate/Translate';

// Helpers
import { checkValidity } from '../../helpers';

export const ImportAssessmentCompetencies = class ImportAssessmentCompetencies extends React.Component {

  componentDidMount() {
    const { store, translation } = this.props;
    const { AppStore } = store;
    AppStore.drawerTitle = translation.heading;
  }

  componentWillUnmount() {
    const { AssessmentStore } = this.props.store;
    AssessmentStore.assessment = null;
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;

    if(onSubmit) {
      onSubmit();
    }
  }

  render() {
    const { translation, values, updateCompetencies } = this.props;
    const { metaData, assessmentSid } = values;
    const { competencies } = metaData;

    return (
      <div className="panel panel--md panel--solo">
        <Head>
          <title>{translation.heading} | SmarterMeasure</title>
        </Head>

        {assessmentSid &&
          <div className="note">
            <p>{translation.description.assessment_found.replace('%key%', values.key)}</p>
          </div>
        }

        <div className="note">
          <p>{translation.description.competency_found.replace('%num%', competencies ? competencies.length : 0)}</p>
        </div>

        <form
          className="form"
          noValidate
          onSubmit={(e) => checkValidity(e, this.handleSubmit)}
        >
          <fieldset>
            <ul className="form__fields">
              <li>
                <table>
                  <caption className="meta">{translation.competency.label}</caption>
                  <thead>
                    <tr>
                      <th scope="col">{translation.competency.head.code}</th>
                      <th scope="col" className="required">{translation.competency.head.label}</th>
                      <th scope="col">{translation.competency.head.questions}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {competencies && competencies.length &&
                      competencies.map((competency, i) => (
                        <tr key={i}>
                          <td>{competency.code}</td>
                          <td>
                            <input
                              type="text"
                              defaultValue={competency.label}
                              required={true}
                              aria-required="true"
                              data-errormsg={translation.competency.label_error}
                              onChange={updateCompetencies(i)}
                              />
                          </td>
                          <td>{competency.questionCount}</td>
                        </tr>
                    ))}
                  </tbody>
                </table>
              </li>
            </ul>
          </fieldset>
          <div className="panel__action">
            <button className="btn">{translation.complete}</button>
          </div>
        </form>

      </div>
    )
  }
}

export default translate('ImportAssessmentCompetencies')(ImportAssessmentCompetencies);