import React from 'react';

// Dependencies
import { differenceInSeconds, parseISO } from 'date-fns';

// Translation
import translate from '../translate/Translate';

// Helpers
import { parseSeconds } from '../../helpers';

// Components
import Date from '../common/Date';
import Score from '../common/Score';

const StudentReport = props => {
  return (

    <article
      className="report"
      data-attempt={props.attemptSid}
    >
      <header className="report__head">
        <img
          alt={'report logo'}
          className="report__logo"
          src="/img/wgu.png"
        />

        <div className="report__details">
          <h1 className="report__title">
            {props.attempt.assessmentName ? props.attempt.assessmentName : 'CPLT'}
          </h1>

          <span className="report__stat">
            {props.translation.completed_date}:

            <Date
              {...props}
              date={props.attempt.dateCompleted}
              dateFormat="M/d/yy h:mm a"
            />
          </span>

          <span className="report__stat">
            {props.translation.duration}:

            {parseSeconds(
              differenceInSeconds(parseISO(props.attempt.dateCompleted), parseISO(props.attempt.dateCreated))
            )}
          </span>
        </div>
      </header>

      <section className="report__section">
        <h2 className="report__heading">
          {props.translation.intro.heading_1}
        </h2>

        <p>{props.translation.intro.body_1}</p>
      </section>

      <section className="report__section">
        <h2 className="report__heading">
          {props.translation.intro.heading_2}
        </h2>

        <div dangerouslySetInnerHTML={{ __html: props.translation.intro.body_2 }} />
      </section>

      {/* Loop through scores */}
      {props.tags && Object.keys(props.tags).length > 0 &&
        <section className="report__section report__scores">
          <h2 className="meta">Scores</h2>

          <table className="score-list">
            <thead>
              <tr>
                <th className="meta" scope="col">{props.translation.scores.competency}</th>
                <th className="meta" scope="col">{props.translation.scores.score}</th>
              </tr>
            </thead>

            <tbody>

              {Object.keys(props.tags).map((tag, i) =>
                <React.Fragment key={i}>
                  <Score
                    {...props}
                    percent={props.tags[tag].percentage}
                    title={tag}
                  />

                  {/* If there are subkeys, render them */}
                  {Object.keys(props.tags[tag]).map((subTag, j) => {
                    if (
                      subTag !== "earned" &&
                      subTag !== "possible" &&
                      subTag !== "percentage"
                    ) {
                      return (
                        <Score
                          {...props}
                          key={j}
                          displayStyle="compact"
                          percent={props.tags[tag][subTag].percentage}
                          title={subTag}
                        />
                      )
                    }

                    return null;
                  })}
                </React.Fragment>
              )}
            </tbody>
          </table>
        </section>
      }
      {/* End of scores loop */}
    </article>
  )
}

export default translate('PDFReport')(StudentReport);