import axios from 'axios';

// const { REACT_APP_ENVIRONMENT } = getConfig().publicRuntimeConfig;
// TEMP: Hard code the environment
// const REACT_APP_ENVIRONMENT = 'stage';


export let config = {
  apiUrl: 'https://plus-stage-api.smartermeasure.com/v1',
  apiHealth: 'https://plus-stage-api.smartermeasure.com/',
  spApiUrl: 'https://api-stage.smarterproctoring.com/v1',
  lhApiUrl: 'https://signon-stage.smarterservices.com/v1',
  posthogApiKey: 'phc_suk8Oz36V7K2J9Ln0cXXgF7Cl1edYmjmffAz3Y5SDVc',
  posthogHost: 'https://app.posthog.com'
}


// QA
if (process.env.REACT_APP_ENVIRONMENT === 'qa') {
  config.apiUrl = 'https://plus.api.stage.smartermeasure.com/v1';
  config.spApiUrl = 'https://api.smarterproctoring.com/v1';
  config.lhApiUrl = 'https://signon.smarterservices.com/v1';
}
// Production
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  config.apiUrl = 'https://plus.api.smartermeasure.com/v1';
  config.spApiUrl = 'https://api.smarterproctoring.com/v1';
  config.lhApiUrl = 'https://signon.smarterservices.com/v1';
  config.posthogApiKey = 'phc_AdTlBK0Gsh77tMkF8J0wTBruU6dCz9zeBWjuANMToZW';
}

export let api = axios.create({
  baseURL: config.apiUrl
});

export let spApi = axios.create({
  baseURL: config.spApiUrl
});

export let lhApi = axios.create({
  baseURL: config.lhApiUrl
});

export function setToken(token) {
  api = axios.create({
    baseURL: config.apiUrl,
    headers: { token }
  });

  spApi = axios.create({
    baseURL: config.spApiUrl,
    headers: { token }
  });

  lhApi = axios.create({
    baseURL: config.lhApiUrl,
    headers: { token }
  });
}