/**
 * Renders a list of tag aliases
 */

import React from 'react';

// Translation
import translate from '../translate/Translate';

// Icons
import * as icons from '../ui/Icons';

// Components
import EditTagAlias from '../forms/EditTagAlias';

export const TagAliases = class TagAliases extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      alias: null,
      aliasTag: null,
      editing: false
    }
  }

  handleDelete(aliasKey) {
    const { onDelete, store, translation } = this.props;
    const { AppStore } = store;

    const callback = () => {
      AppStore.toast = translation.deleted;
    }

    AppStore.showDialog(
      translation.delete_confirm,
      'confirm',
      () => onDelete(aliasKey, callback)
    );
  }

  renderNewBtn() {
    const { translation } = this.props;

    return (
      <button
        className="btn btn--outline btn--center"
        type="button"
        onClick={() => this.setState({ editing: true })}
      >
        {translation.new_alias}
      </button>
    )
  }

  render() {
    const {
      aliases,
      assessment,
      onSubmit,
      tags,
      translation
    } = this.props;
    const {
      alias,
      aliasTag,
      editing
    } = this.state;

    if(editing) {
      return (
        <EditTagAlias
          {...this.props}
          alias={alias}
          aliasTag={aliasTag}
          assessment={assessment}
          onClose={() => this.setState({
            alias: null,
            aliasTag: null,
            editing: false
          })}
          onSubmit={onSubmit}
          tags={tags}
        />
      )
    } else {
      return (
        <div>
          {(!aliases || Object.keys(aliases).length === 0) &&
            <span className="no-results">
              {translation.no_results}
            </span>
          }

          {aliases && Object.keys(aliases).length > 0 &&
            <ul className="grid grid--wrap" data-columns="3">
              {Object.keys(aliases).map(
                (tagAlias, i) =>
                <li
                  className="grid__item"
                  key={i}
                >
                  <article
                    className="panel panel--card"
                  >
                    <h2 className="panel__title panel__title--alt">
                      <button
                        className="btn--title"
                        type="button"
                        onClick={() => this.setState({
                          alias: aliases[tagAlias],
                          aliasTag: tagAlias,
                          editing: true 
                        })}
                      >
                        {aliases[tagAlias].label}
                      </button>
                    </h2>

                    <button
                      className="btn btn--ghost btn--negative panel__remove"
                      type="button"
                      onClick={() => this.handleDelete(tagAlias)}
                    >
                      <icons.times />
                      <span className="meta">
                        {translation.delete_alias}
                      </span>
                    </button>

                    {aliases[tagAlias].description &&
                      <p>{aliases[tagAlias].description}</p>
                    }
                  </article>
                </li>
              )}
            </ul>
          }

          {this.renderNewBtn()}
        </div>
      )
    }
  }
}

export default translate('TagAliases')(TagAliases);
