/**
 * Renders an toaster message
 * @property {string} content
 */

import React from 'react';

// Icons
import * as icons from '../ui/Icons';

const Toast = class Toast extends React.Component {
  constructor() {
    super();

    // Bind this to functions
    this.handleClose = this.handleClose.bind(this);

    // Set the initial state
    this.state = {
      exiting: false
    };
  }

  componentDidMount() {
    const _this = this;
    const delay = 5000;

    // Hide the toaster after a delay
    setTimeout(function(){
      _this.handleClose();
    }, delay);
  }

  handleClose() {
    const { AppStore } = this.props.store;

    this.setState({
      exiting: true
    });

    setTimeout(function(){
      AppStore.toast = null;
    }, 1000);

    const main = document.querySelector('#main-content');

    if(main) {
      main.focus();
    }
  }

  renderIcon(mod) {
    let icon;

    switch(mod) {
      case 'positive':
        icon = <icons.checkCircle />;
        break;

      case 'warn':
        icon = <icons.warningCircle />;
        break;

      case 'negative':
        icon = <icons.minusCircle />;
        break;

      default:
        icon = <icons.infoCircle />;
    }

    return icon;
  }

  render() {
    const { content, mod } = this.props;

    return (
      <aside
        className={`toast${
          this.state.exiting ? ' toast--exiting' : ''
        }${
          mod ? ` toast--${mod}` : ''
        }`}
      >
        <i className="toast__icon">
          {this.renderIcon(mod)}
        </i>

        <span
          className="toast__content"
          role="alert"
        >
          {content}
        </span>

        <button
          className="toast__close"
          onClick={this.handleClose}
        >
          <icons.times />

          <span className="meta">Dismiss</span>
        </button>
			</aside>
    )
  }
}

export default Toast;
