/**
 * Renders a range chart
 */

import React from 'react';

const RangeChart = class RangeChart extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      selected: null,
      width: 0
    };

    // Create refs
    this.chart = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.getDimensions();
    }, 100);

    window.addEventListener('resize', e => {
      this.resizeTimer = setTimeout(() => {
        this.getDimensions();
      }, 100);
    })
  }

  getDimensions() {
    if(this.chart.current) {
      this.setState({
        width: this.chart.current.getBoundingClientRect().width
      });
    }
  }

  fill(value) {
    const { max } = this.props;
    const pct = value / max * 100;

    if(pct > 75) {
      return 'blue';
    } else if(pct > 50) {
      return 'green';
    } else if(pct > 25) {
      return 'yellow';
    }

    return 'red';
  }

  render() {
    const {
      levels,
      markers,
      max = 100,
      units,
      value
    } = this.props;
    const levelColors = ['#e9595b', '#f9cd48', '#46be8a', '#0074cc', '#0074cc'];

    let bg = '';

    if(levels) {
      bg = `
        linear-gradient(
          to right,
          ${levels.map(
            (level, i) => {
              if(i > 0 && i < levels.length - 1) {
                return `
                  ${levelColors[i - 1]} ${level / max * 100}%,
                  ${levelColors[i]} ${level / max * 100}%
                `;
              } else {
                return levelColors[i];
              }
            }
          )}
        )
      `;
    };

    return (
      <div
        className="rangechart"
        style={{ background: bg }}
      >
        <div
          className="rangechart__value"
          style={{ left: `${value / max * 100}%` }}
        >
          <span className="chart__tooltip">
            {value}{units || ''}
          </span>
        </div>

        {markers &&
          <ul className="rangechart__ticks">
            {markers.map(
              (marker, i) =>
              <li
                className="tick"
                key={i}
                style={{ left: `${marker / max * 100}%` }}
              >
                <span className="rangechart__marker">
                  {marker}{units || ''}
                </span>
              </li>
            )}
          </ul>
        }
      </div>
    )
  }
}

export default RangeChart;
