/**
 * Renders instructor or student assessment view
 */

import React from 'react';
import { observer } from 'mobx-react';

// Components
import AssessmentsList from './AssessmentsList';
import StudentAssessments from './StudentAssessments';

export const Assessments = observer(class Assessments extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      render: false
    };
  }

  componentDidMount() {
    const { UserStore } = this.props.store;

    // Make sure user role is present before rendering
    const userDelay = setInterval(() => {
      if(UserStore.userData.role) {
        clearInterval(userDelay);

        this.setState({
          render: true
        });
      }
    }, 100);
  }

  render() {
    const { render } = this.state;
    const { userData } = this.props.store.UserStore;

    if(render) {
      if(userData.role.toLowerCase() === 'learner') {
        return (
          <StudentAssessments {...this.props} />
        )
      } else {
        return (
          <AssessmentsList {...this.props} />
        )
      }
    }

    return null;
  }
})

export default Assessments;
