/**
 * Renders the survey view
 */

import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../translate/Translate';

// Icons
import Head from '../common/Head'
import SurveyCard from "../common/SurveyCard";
import ImportAssessment from "../forms/ImportAssessment";
import EditSurvey from "../forms/EditSurvey";

export const Surveys = observer(class Surveys extends React.Component {
    constructor() {
        super();

        this.handleNewSurvey = this.handleNewSurvey.bind(this);

        // Bind this to functions
        this.listSurveys = this.listSurveys.bind(this);
        this.handleNewSurvey = this.handleNewSurvey.bind(this);
        this.handleDrawer = this.handleDrawer.bind(this);

        this.query = {
            limit: 24
        }
    }

    componentDidMount() {
        this.listSurveys();
    }

    listSurveys() {
        const { SurveyStore } = this.props.store;

        SurveyStore.listSurveys(this.query);
    };

    handleNewSurvey(e) {
        e.preventDefault();

        // Update the URL without causing a page load
        // const pathname = getPathname(e.target.href);

        // window.history.pushState(
        //   {},
        //   'edit-survey',
        //   pathname
        // );
        // Open the assessment edit drawer
        this.handleDrawer('new');
    };

    handleDrawer(op) {
        const { AppStore } = this.props.store;

        switch (op) {
            case 'new':
                AppStore.drawerContent =
                    <EditSurvey
                        {...this.props}
                        component="EditSurvey"
                        surveySid={ null}
                        newSurvey={true}
                        placeholderToShowTabs={true}
                    />
                break;

            case 'import':
                AppStore.drawerContent =
                    <ImportAssessment
                        {...this.props}
                        component="ImportAssessment"
                    />;
                break;

            default:
                break;
        }

        AppStore.drawerMod = 'full';
    };

    render() {
        const { store, translation } = this.props;
        const { SurveyStore } = store;
        const { surveys } = SurveyStore;
        return (
            <main
                id="main-content"
                className="page"
                tabIndex="-1"
                aria-labelledby="page-title"
            >
                <Head>
                    <title>{translation && translation.heading} | SmarterMeasure</title>
                </Head>
                {/*TODO: Make Filter work properly for surveys*/}
                {/* <Filter
                    id="filter"
                    filterParams={this.query}
                    filterProp="search"
                    onClear={this.listSurveys}
                    onFilter={SurveyStore.listSurveys}
                    placeholder={'Search Surveys...'}
                    useApi
                /> */}

                <div className="wrap">
                    <header className="page__head">
                        <h1 id="page-title" className="page__title">{translation && translation.heading}</h1>

                        <button
                            className="btn"
                            onClick={this.handleNewSurvey}
                        >
                            {translation && translation.new_survey}
                        </button>
                    </header>

                    {/*Some Static Survey Cards for show*/}
                    {surveys.length > 0 &&
                        <ul id="survey-group" className="grid grid--wrap" data-columns="2">
                            {surveys.map((survey) =>
                                <li className="grid__item" key={survey.surveySid}>
                                    <SurveyCard
                                        {...this.props}
                                        survey = {survey}
                                        surveySid={survey.surveySid}
                                        name={survey.draftJson?.name || survey.name }
                                        description={survey.draftJson?.description || survey.description}
                                        surveyKey={survey.draftJson?.key || survey.key}
                                        dateCreated={survey.dateCreated}
                                        listSurveys={this.listSurveys}
                                        draft={survey.draftJson}
                                    />
                                </li>
                            )}
                        </ul>
                    }
                </div>
            </main>
        );
    }
})

export default translate('Surveys')(Surveys);