/**
 * Renders a diff of two assessment version
 */

 import React from 'react';
 import { observer } from 'mobx-react';
 
 // Translation
 import translate from '../translate/Translate';
 
 // Dependencies
 import { diff, formatters } from 'jsondiffpatch'
 
 // Icons
 import * as icons from '../ui/Icons';
 
 export const SurveyDiff = observer(class SurveyDiff extends React.Component {
   constructor() {
     super();
 
     // Set the initial state
     this.state = {
       diffMarkup: ''
     };
   }
 
   componentDidMount() {
     const { survey, oldVersion } = this.props;
     const diffResult = diff(oldVersion, survey);
 
     this.setState({
       diffMarkup: formatters.html.format(diffResult, oldVersion)
     });
   }
 
   render() {
     const { onClose, title, translation } = this.props;
 
     return (
       <div className="modal modal--lg">
         <div className="modal__container">
           <article
             className="modal__content"
           >
             <header className="modal__head">
               {title &&
                 <h2 className="modal__title">
                   {title}
                 </h2>
               }
 
               <button
                 className="modal__close"
                 onClick={onClose}
               >
                 <icons.times />
                 <span className="meta">{translation.close}</span>
               </button>
             </header>
 
             <div dangerouslySetInnerHTML={{ __html: this.state.diffMarkup }} />
           </article>
         </div>
       </div>
     )
   }
 })
 
 export default translate('SurveyDiff')(SurveyDiff);
 