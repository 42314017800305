/**
 * Renders a load more button that is triggered on scroll
 */

import React from 'react';

// Helpers
import { isDescendant } from '../../helpers';

const LoadMore = class LoadMore extends React.Component {
  constructor() {
    super();

    // Bind this to functions
    this.loadMore = this.loadMore.bind(this);

    // Set the initial state
    this.state = {
      loading: false
    };

    // Create refs
    this.button = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.handleScroll();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  isVisible() {
    if(this.button.current) {
      const button = this.button.current.getBoundingClientRect();
      return button.top <= window.innerHeight * 3;
    }

    return false;
  }

  handleScroll = () => {
    const { infinite } = this.props;
    const drawerContent = document.querySelector('.drawer__content');
    const button = this.button.current;

    const handleVisible = () => {
      if(this.isVisible()) {
        this.loadMore();
      }
    }


    if(infinite) {
      if(drawerContent && isDescendant(drawerContent, button)) {
        drawerContent.addEventListener('scroll', handleVisible);
      } else {
        window.addEventListener('scroll', handleVisible);
      }
    }
  }

  loadMore() {
    const { loadFunction } = this.props;

    this.setState({
      loading: true
    });

    const callback = () => {
      if(this._isMounted) {
        this.setState({
          loading: false
        });
      }
    }

    loadFunction(callback);
  }

  render() {
    const { buttonText } = this.props;

    if(!this.state.loading) {
      return (
        <button
          className="btn btn--more"
          onClick={this.loadMore}
          type="button"
          ref={this.button}
        >
          {buttonText}
        </button>
      )
    } else {
      return (
        <aside className="loader--async">
          <span className="meta">Loading…</span>
        </aside>
      )
    }
  }
}

export default LoadMore;