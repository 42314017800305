/**
 * Renders the student dashboard view
 * with available assessments and results
 */

import React from 'react';
import { observer } from 'mobx-react';
import Head from '../common/Head'

// Translation
import translate from '../translate/Translate';

// Components
import AssessmentCard from '../common/AssessmentCard';

export const StudentAssessments = observer(class StudentAssessments extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      singleAssessment: false
    };
  }

  componentDidMount() {
    const { AssessmentStore, UserStore } = this.props.store;
    const { installSid, userData } = UserStore;

    const callback = (response) => {
      const { items } = response.data;

      if(items.length === 1) {
        this.setState({
          singleAssessment: true
        });

        const assessment = items[0];

        const payload = [{
          groupItemSid: assessment.sid,
          userSid: userData.userSid,
          enrollmentSid: userData.enrollmentSid
        }];

        const callback = () => {
          window.location = (
            `/assessment/${assessment.assessmentSid}?install=${installSid}&attempt=${AssessmentStore.attemptSid}&token=${UserStore.token}`
          )
        };

        AssessmentStore.createAttempt(payload, callback);
      }
    }

    AssessmentStore.listGroupAssessments(
      UserStore.assessmentGroupSid,
      { userSid: UserStore.userData.userSid },
      callback
    );
  }

  render() {
    const { store, translation } = this.props;
    const { singleAssessment } = this.state;
    const { AppStore, AssessmentStore } = store;
    const { assessments } = AssessmentStore;

    return (
      <main
        id="main-content"
        className="page page--sm"
        tabIndex="-1"
        aria-labelledby="page-title"
      >
        <Head>
          <title>{translation.heading} | SmarterMeasure</title>
        </Head>

        <div className="wrap">
          <header className="page__head">
            <h1 id="page-title" className="page__title">{translation.heading}</h1>
          </header>

          {
            assessments.length === 0 &&
            !AppStore.loading &&
            <span className="no-results panel">
              {translation.no_results}
            </span>
          }

          {assessments.length && singleAssessment &&
            <aside className="loader">
              <div className="loader__msg">
                <span className="meta">Loading…</span>
              </div>
            </aside>
          }

          {assessments.length > 0 && !singleAssessment &&
            <ol id="assessments" className="grid grid--wrap" data-columns="1">
              {assessments.map(
                (assessment) =>
                <li
                  className="grid__item grid__item--push"
                  key={assessment.assessmentSid}
                >
                  <AssessmentCard
                    {...this.props}
                    assessment={assessment}
                    action={'start'}
                    hideStatus
                  />
                </li>
              )}
            </ol>
          }
        </div>
      </main>
    )
  }
})

export default translate('StudentAssessments')(StudentAssessments);
