/**
 * Renders a view that gives the student
 * the option to view results or take an assessment
 */

import React from 'react';
import { observer } from 'mobx-react';

// Helpers
import { formatQuery } from '../../helpers';

// Icons
import * as icons from '../ui/Icons';

// Translation
import translate from '../translate/Translate';
import {Link} from "react-router-dom";

export const StudentRouter = observer(class StudentRouter extends React.Component {
  handleContinue(e, query) {
    e.preventDefault();

    const { inProgress } = this.props.store.UserStore;

    window.location = `/assessment/${inProgress[0].assessmentSid}${formatQuery(query)}`

  }

  render() {
    const { store, translation } = this.props;
    const { assessmentGroup, completed, inProgress, installSid } = store.UserStore;

    return (
      <main
        id="main-content"
        className="page"
        tabIndex="-1"
        aria-labelledby="page-title"
      >

          <title>{translation.heading} | SmarterMeasure</title>


        <div className="panel panel--sm panel--solo">
          <h1 id="page-title" className="panel__title">
            {translation.heading}
          </h1>

          {assessmentGroup &&
            <p>
              <icons.folder />
              {translation.assessment_group} {assessmentGroup.name}
            </p>
          }

          {translation.context &&
            <div dangerouslySetInnerHTML={{ __html: translation.context }} className="note" />
          }

          {completed.length > 0 &&
            <p>{translation.body}</p>
          }

          <div className="btn-group">
            {/* User has an in-progress attempt */}
            {inProgress.length > 0 &&

                <Link
                    to="/assessments"

                    className="btn btn--outline"
                    onClick={(e) => {
                      this.handleContinue(
                        e,
                        {
                          install: installSid,
                          attempt: inProgress[0].attemptSid,
                          version: inProgress[0].assessmentVersionSid,
                          token: store.UserStore.token
                        }
                      )
                    }}
                >
                  {translation.continue_attempt}
                </Link>
            }

            {/* Start a new attempt */}
            {inProgress.length === 0 &&

                <Link
                    to="/assessments"

                    className="btn btn--outline"
                >
                  {completed.length > 0 ? translation.retake : translation.new_attempt}
                </Link>
            }

            {/* View results */}
            {completed.length > 0 &&
                <Link
                    to="/results"
                    className="btn btn--outline"
                >
                  {translation.view_results}
                </Link>
            }
          </div>
        </div>
      </main>
    )
  }
})

export default translate('StudentRouter')(StudentRouter);
