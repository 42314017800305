import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../translate/Translate';

// Icons
import * as icons from '../ui/Icons';

// Components
import BarChart from '../charts/Bar';
import RangeChart from '../charts/Range';

const LRILifeFactors = observer(class LRILifeFactors extends React.Component {
  render() {
    const detailData = [
      { label: 'Time', value: [55] },
      { label: 'Place', value: [70] },
      { label: 'Reason', value: [75] },
      { label: 'Resources', value: [75] },
      { label: 'Skills', value: [75] }
    ];

    return (
      <div>
        <header className="page__head">
          <h1 className="page__title">Life Factors</h1>
        </header>

        <p>Many students strongly desire to continue their education. However, often other situations in life prevent them from being able to do so. The Life Factors section of this assessment asks questions about other elements in your life that may impact your ability to continue your education. The section measures five items: Time, Place, Reason, Resources, and Skills.</p>

        <p>You may be able to modify circumstances which impact some of these life skills. If so, you are encouraged to take appropriate action to help yourself succeed. But some of the circumstances in life may be beyond your control. If this is the case, being aware of these realities and planning accordingly may be beneficial for you.</p>

        <div className="note">
          <i className="note__icon">
            <icons.infoCircle />
          </i>

          <p>The maximum possible score for this section is 100%. You scored a total of 70%. Your results for each of the five areas are below.</p>
        </div>

        <RangeChart
          {...this.props}
          levels={[0, 25, 50, 75, 100]}
          markers={[0, 25, 50, 75, 100]}
          max={100}
          units="%"
          value={70}
        />

        <section className="page__section">
          <h2>Detailed Item Results</h2>

          <BarChart
            {...this.props}
            barWidth={30}
            data={detailData}
            height={250}
            id="details-chart"
            levels={4}
            max={100}
            scoreColors
            title="Detailed Item Results"
            units="%"
          />

          <section className="page__sub-section">
            <h3>Time</h3>

            <strong className="score-tag score-tag--mid-high page__section-tag">3</strong>

            <p>The amount of time you have available to participate in activities related to your school work is a minor concern. While you do have some time available for your school work, you need to communicate with others that you are continuing your education and may need to opt out of some activities to devote time to studying. You need to identify exactly what times of day you have available for studying and then guard that time just like it was an appointment on your calendar. Avoid activities like social networking which research has shown can easily consume too much time and distract you from studying.</p>

            <p>A generally accepted rule of thumb for time spent with college classes is that you should expect to study about 2 to 3 hours per week for each unit of credit in a typical 16 week class. Based on this rule of thumb, a student taking 15 credit hours should expect to spend 30 to 45 hours each week with school work. Realize that this is similar to the time required of a typical job.  Continuing your education requires a substantial investment of time. Of course, if you are working while you attend school, you will need to budget your time carefully. As a rough guideline, your studying time might be divided as follows.</p>

            <table>
              <thead>
                <tr>
                  <th scope="col">Course credits</th>
                  <th scope="col">Time reading assigned text</th>
                  <th scope="col">Time for homework assignments</th>
                  <th scope="col">Time for review and test prep</th>
                  <th scope="col">Total study time (per week)</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>3 credits</td>
                  <td>1 to 2 hours</td>
                  <td>3 to 5 hours</td>
                  <td>2 hours</td>
                  <td>6 to 9 hours</td>
                </tr>
                <tr>
                  <td>4 credits</td>
                  <td>2 to 3 hours</td>
                  <td>3 to 6 hours</td>
                  <td>3 hours</td>
                  <td>8 to 12 hours</td>
                </tr>
                <tr>
                  <td>5 credits</td>
                  <td>2 to 4 hours</td>
                  <td>4 to 7 hours</td>
                  <td>4 hours</td>
                  <td>10 to 15 hours</td>
                </tr>
              </tbody>
            </table>
          </section>

          <section className="page__sub-section">
            <h3>Place</h3>

            <strong className="score-tag score-tag--mid-high page__section-tag">3</strong>

            <p>Your answers indicate that having an identified, appropriate place to study is a minor concern for you. It is good that you have thought about the places at which you can study. Now you may need to communicate to others about your plans and how you would appreciate their respect of your privacy while studying. Review the resources below for helpful tips on places to study.</p>

            <p>Here are some helpful hints about creating an appropriate place to study.  (1) Find an environment that suits you.  Some persons prefer silence and some prefer background noise.  (2) Select a comfortable place, but not so comfortable that you will fall asleep.  (2) Claim the space as yours.  Inform others the space is your study area and they are not to disturb items or you while in the space.  (4) Avoid common areas such as kitchen tables or den couches.  (5) Avoid multi-tasking.  While you are studying close your email and do not participate in social networking.  (6) Feed your stomach then your mind.  If you are hungry you may not be able to focus and may be tempted to take frequent breaks to snack. (7) Be supplied.  Keep all of the supplies such as pens, paper and highlighters at your study space. (8) Have a bright idea.  Make sure that your study space has appropriate lighting.</p>
          </section>

          <section className="page__sub-section">
            <h3>Reason</h3>

            <p>Your answers indicate that your reasons for going to school may be a minor concern. You have thought about the benefits and the sacrifices involved in continuing your education. Going to school may be a top priority for you now, but keep on your guard so that other factors don’t compete with the desire to improve yourself through education. Review the resources below to learn about ways to keep yourself motivated.</p>

            <p>Several research studies have shown that the more education a person has, the more annual income they generate and the better their quality of life.  Today, a college degree is an essential ingredient. Employers frequently use degrees as a way to screen applicants. Then even after you get the job your salary may be reflective of your educational credentials.  On average, a person with a Master's degree earns $31,900 more per year than a high school graduate—a difference of as much as 105%!  So keep yourself motivated and your eyes on the prize.</p>
          </section>

          <section className="page__sub-section">
            <h3>Resources</h3>

            <strong className="score-tag score-tag--mid-high page__section-tag">3</strong>

            <p>Your answers indicate that the level of support and resources you have for going to school is an issue of minor concern. It is good that you recognize there are persons who can be your “cheerleader” to encourage you in the process. Give them a call or send an email letting them know you appreciate and need their support. If you have any gaps in your support network for items such as childcare, explore every possible option of assistance so that these factors will not be a road block to your educational progress. Read the resources below for more ideas about getting the support you need to be successful in going to school.</p>

            <p>To be successful, you really need the support of others and the appropriate resources.  To make sure that you have access to the financial resources to continue your education, talk to the financial aid office at your school.  If you are concerned about having the physical stamina, consider getting a physical exam from your doctor.  If you have family or friends who can support you in ways such as childcare, have a discussion with them about your plans for going to school and express how you would appreciate their support.  If appropriate, have a discussion with your employer.  Ask if the company has an educational reimbursement plan and any policies regarding studying while on the job or during breaks.</p>
          </section>

          <section className="page__sub-section">
            <h3>Skills</h3>

            <strong className="score-tag score-tag--mid-high page__section-tag">3</strong>

            <p>Your responses to this section indicate that you have had some success in the past with academic pursuits, but you are moderately concerned about your academic success now. Think about what you did in the past to be academically successful. Were there certain study strategies or ways that you received assistance? If so, find out about all of the resources for academic support that your school currently provides. Many schools offer resources such as tutoring, writing support, etc. Review the resources below for more tips on being academically successful.</p>

            <p>College is where you become more intelligent.  You are not expected to be a genius to get in, just prepared enough to know how to work and willing to make the necessary sacrifices of your time and energy.  Do you know how to study? Can you stick with a task until it is finished?  Being successful in college is often as much attitude as aptitude.</p>
          </section>

          <div className="note">
            <h2 className="note__title">Resources for Support</h2>

            <p>A multitude of resources exist on the World Wide Web. Those listed here are just a small sample of what is available in terms of information or tutorials on the topics focused on in this assessment. Some of these sites are free; a few require a subscription or fee. To find more or different information beyond these sites, feel free to perform your own web search.</p>
          </div>
        </section>
      </div>
    )
  }
})

export default translate('LRILifeFactors')(LRILifeFactors);