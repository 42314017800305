/**
 * Renders the user list
 */

import React from 'react';
import { observer } from 'mobx-react';
import Head from '../common/Head'
import {Link} from 'react-router-dom';

// Helpers
import { deformatQuery } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Components
import Filter from '../common/Filter';
import LoadMore from '../common/LoadMore';

// Icons
import * as icons from '../ui/Icons';

 const Users = observer(class Users extends React.Component {
  constructor() {
    super();

    // Bind this to functions
    this.listUsers = this.listUsers.bind(this);

    // Default query params
    this.query = {
      limit: 100,
      sortKeys: 'createdDate',
      sortOrder: 'ASC'
    }
  }

  componentDidMount() {
    this.listUsers();
  }

  listUsers() {
    const { EnrollmentStore } = this.props.store;

    EnrollmentStore.listUsers(this.query);
  }

  render() {
    const { store, translation } = this.props;
    const { AppStore, EnrollmentStore, UserStore } = store;
    const { users, usersNext } = EnrollmentStore;
    const { userData } = UserStore;

    return (
      <main
        id="main-content"
        className="page"
        tabIndex="-1"
        aria-labelledby="page-title"
      >
        <Head>
          <title>{translation && translation.heading} | SmarterMeasure</title>
        </Head>

        <Filter
          id="filter"
          filterParams={this.query}
          // TEMP: Only search first name because
          // searching multiple will give no results
          // filterProp={'firstName', 'lastName'}
          filterProp="filter"
          onClear={this.listUsers}
          onFilter={EnrollmentStore.listUsers}
          placeholder={translation && translation.filter}
          useApi
        />

        <div className="wrap">
          <header className="page__head">
            <h1 id="page-title" className="page__title">{translation && translation.heading}</h1>
            {userData.is_super_admin &&
              <Link
                to="/users/add"
              >
                <a className="btn btn--outline"
                   href="#/"
                >
                  Create an admin user
              </a>
              </Link>
            }
          </header>

          {users && users.length === 0 && !AppStore.loading &&
            <span className="no-results panel">
              {translation.no_results}
            </span>
          }

          {users && users.length > 0 &&
            <table className="panel">
              <thead>
                <tr>
                  <th scope="col">{translation.table.first_name}</th>
                  <th scope="col">{translation.table.last_name}</th>
                  <th scope="col">{translation.table.email}</th>
                  <th scope="col">{translation.table.details}</th>
                </tr>
              </thead>

              <tbody>
                {users.map(
                  (user, i) =>
                  <tr key={i}>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.preferredEmail ? user.preferredEmail : user.email}</td>
                    <td className="table__cell--sm">
                      <Link
                        to={`/users/${user.sid}`}
                      >
                        <a
                          href="/#"
                          className="btn btn--outline"
                          title={`${translation.table.details} - ${user.firstName} ${user.lastName}`}
                        >
                          <span className="meta">{translation.table.details}</span>
                          <icons.chevronRight />
                        </a>
                      </Link>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          }

          {usersNext &&
            <LoadMore
              {...this.props}
              buttonText={translation.load_more}
              loadFunction={(callback) => {
                EnrollmentStore.listUsers(deformatQuery(usersNext), true, callback)
              }}
              infinite={true}
            />
          }
        </div>
      </main>
    )
  }
})

export default translate('Users')(Users);
