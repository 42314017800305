/**
 * Renders the student home view
 * with available assessments and results
 */

import React from 'react';
import { observer } from 'mobx-react';
import Head from '../common/Head';

// Translation
import translate from '../translate/Translate';

// Dependencies
import { subDays } from 'date-fns';

// Components
import BarChart from '../charts/Bar';
import PieChart from '../charts/Pie';
import Select from '../common/Select';
import DatePicker from '../common/DatePicker';

const AdminHome = observer(class AdminHome extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      granularity: 'month',
      startDate: null,
      endDate: null,
      query: {}
    };

    // Create refs
    this.granularity = React.createRef();
  }

  componentDidMount() {
    const { ReportStore } = this.props.store;

    const query = this.getDateRange(30);

    if(ReportStore.adminHomeCalls === false){
      ReportStore.getAssessmentsTaken({ granularity: 'month' }, true);
      ReportStore.getAttemptCounts(query);
      ReportStore.getAllAverageScores(query);
      ReportStore.getKnowledgeScores(query);
      ReportStore.adminHomeCalls = true;
    }
  }

  getDateRange = (days) => {
    const now = new Date();
    const startDate = subDays(now, days).toISOString();
    const endDate = now.toISOString();

    return { startDate, endDate };
  };

  handleFilter = (prop, value) => {
    const { ReportStore } = this.props.store;

    this.setState({
      [prop]: value
    }, () => {
      const query = {};

      if(this.state.startDate && this.state.endDate) {
        query.startDate = this.state.startDate;
        query.endDate = this.state.endDate;
      }

      // Only make API calls if the query has changed
      if(JSON.stringify(query) !== JSON.stringify(this.state.query) || prop === 'granularity') {
        ReportStore.getAttemptCounts(query);
        ReportStore.getAllAverageScores(query);
        ReportStore.getKnowledgeScores(query);

        // Make the assessments taken call last since
        // it’s the only one that accepts granularity
        query.granularity = this.state.granularity;
        ReportStore.getAssessmentsTaken(query, true);
      }

      this.setState({
        query
      });
    });
  }

  render() {
    const { store, translation } = this.props;
    const { ReportStore } = store;
    const {
      assessmentsTaken,
      assessmentsTakenMax,
      attemptCounts,
      averageScores,
      averageScoresMax,
      knowledgeScores,
      uniqueAttempts
    } = ReportStore;

    const assessmentsTakenOptions = [];
    const today = new Date();

    //eslint-disable-next-line
    Object.keys(translation.assessments_taken.scope.options).map(option => {
      assessmentsTakenOptions.push({
        label: translation.assessments_taken.scope.options[option],
        value: option
      });
    });

    const rangeOptions = [];

    //eslint-disable-next-line
    Object.keys(translation.date_range.options).map(option => {
      rangeOptions.push({
        label: translation.date_range.options[option],
        value: option
      });
    });

    return (
        <main
            id="main-content"
            className="page"
            tabIndex="-1"
            aria-labelledby="page-title"
        >
          <Head>
            <title>{translation.heading} | SmarterMeasure</title>
          </Head>

          <div className="wrap">
            <header className="page__head">
              <h1
                  id="page-title"
                  className="meta"
              >
                {translation.heading}
              </h1>

              <form className="form form--inline">
                <ul className="form__fields">
                  <li>
                    <Select
                        id="assessments-taken-scope"
                        label={translation.assessments_taken.scope.label}
                        onChange={(id, value) => this.handleFilter('granularity', value)}
                        options={assessmentsTakenOptions}
                        ref={this.granularity}
                    />
                  </li>
                  <li>
                    <DatePicker
                        {...this.props}
                        htmlId="assessments-taken-from"
                        label="From Date"
                        onChange={(id, value) => this.handleFilter('startDate', value)}
                        max={this.state.endDate ? new Date(this.state.endDate) : today}
                        min={new Date("01/01/2020")}
                    />
                  </li>
                  <li>
                    <DatePicker
                        {...this.props}
                        htmlId="assessments-taken-to"
                        label="To Date"
                        onChange={(id, value) => this.handleFilter('endDate', value)}
                        defaultDate={today}
                        max={today}
                        min={this.state.startDate ?
                            new Date(this.state.startDate)
                            : new Date("01/01/2020")}
                    />
                  </li>
                </ul>
              </form>
            </header>

            <div className="grid" data-columns="6">
              {/* Assessments Taken */}
              <section
                  id="assessments-taken"
                  className="panel grid__item grid__item--span-4"
              >
                <header className="panel__head">
                  <h2 className="panel__title panel__title--sm">
                    {translation.assessments_taken.title}
                  </h2>
                </header>

                {assessmentsTaken && uniqueAttempts &&
                <BarChart
                    barWidth={30}
                    data={assessmentsTaken}
                    emptyText={translation.assessments_taken.empty}
                    height={250}
                    id="assessments-taken-chart"
                    keyLabels={['Assessments Taken', 'Unique Students']}
                    levels={3}
                    max={assessmentsTakenMax}
                    title={translation.assessments_taken.title}
                    pagination={10}
                />
                }

              </section>

              {/* Number of attempts */}
              <section
                  id="attempt-counts"
                  className="panel grid__item grid__item--span-2"
              >
                <header className="panel__head">
                  <h2 className="panel__title panel__title--sm">
                    {translation.assessment_attempts.title}
                  </h2>
                  {/* <Select
                  defaultValue="30"
                  id="attempt-counts-scope"
                  label={translation.assessment_attempts.scope.label}
                  onChange={(id, value) => ReportStore.getAttemptCounts(this.getDateRange(value))}
                  options={rangeOptions}
                  hideLabel
                  /> */}
                </header>

                {attemptCounts &&
                <PieChart
                    data={attemptCounts}
                    emptyText={translation.assessment_attempts.empty}
                    id="attempts-counts-chart"
                    size={200}
                    title={translation.assessment_attempts.title}
                    withKey
                />
                }
              </section>

              {/* Average competency scores */}
              <section
                  id="average-scores"
                  className="panel grid__item grid__item--span-3"
              >
                <header className="panel__head">
                  <h2 className="panel__title panel__title--sm">
                    {translation.average_scores.title}
                  </h2>
                  {/* <Select
                  defaultValue="30"
                  id="average-scores-scope"
                  label={translation.average_scores.scope.label}
                  onChange={(id, value) => ReportStore.getAllAverageScores(this.getDateRange(value))}
                  options={rangeOptions}
                  hideLabel
                  /> */}
                </header>

                {averageScores &&
                <BarChart
                    barWidth={30}
                    data={averageScores}
                    emptyText={translation.average_scores.empty}
                    height={250}
                    id="average-scores-chart"
                    levels={3}
                    max={averageScoresMax}
                    title={translation.average_scores.title}
                    units="%"
                />
                }
              </section>

              {/* Knowledge score breakdown */}
              <section
                  id="knowledge-scores"
                  className="panel grid__item grid__item--span-3"
              >
                <header className="panel__head">
                  <h2 className="panel__title panel__title--sm">
                    {translation.knowledge_scores.title}
                  </h2>
                  {/* <Select
                  defaultValue="30"
                  id="knowledge-scores-scope"
                  label={translation.knowledge_scores.scope.label}
                  onChange={(id, value) => ReportStore.getKnowledgeScores(this.getDateRange(value))}
                  options={rangeOptions}
                  hideLabel
                  /> */}
                </header>

                {knowledgeScores &&
                <PieChart
                    data={knowledgeScores}
                    emptyText={translation.average_scores.empty}
                    id="knowledge-scores-chart"
                    size={200}
                    title={translation.knowledge_scores.title}
                    units="%"
                    withKey
                />
                }
              </section>
            </div>
          </div>
        </main>
    )
  }
})

export default translate('AdminHome')(AdminHome);
