import React from 'react';
import parse, {domToReact} from 'html-react-parser';
// eslint-disable-next-line
import ace from 'ace-builds/src-noconflict/ace';


// ace editor configs
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-ruby";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-perl";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/mode-makefile";
import "ace-builds/src-noconflict/mode-php";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-apache_conf";
import "ace-builds/src-noconflict/mode-objectivec";
import "ace-builds/src-noconflict/mode-vbscript";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-coffee";
import "ace-builds/src-noconflict/mode-nginx";

import "ace-builds/src-noconflict/theme-github";

import {aceEditorLanguageSelection} from "../../helpers";
import AutoSizeReactAceEditor from "./AutoSizeReactAceEditor";



function RenderHTML( {rawHTML} ) {



  const options = {
    replace: (domNode) => {
      // the code snippet plugin wraps the code inside of a pre tag
      if (domNode.name === 'code' && domNode.children[0] && domNode.children[0].data) {
        let language = ''
        if (domNode.attribs && domNode.attribs.class) {
          // the language name is stored inside of the class as language-{language name}
          let className = domNode.attribs.class;
          if(className.includes('language-')){
            language = className.replace('language-', '')
            language = aceEditorLanguageSelection(language);
          }
        }

        return (
          <div>
            <AutoSizeReactAceEditor
              language={language}
              options={options}
              data={domNode.children[0].data}
            />
          </div>
         );
      }
      else if (domNode.name === 'pre') {
        return(
          <div>
            {domToReact(domNode.children, options)}
        </div>)

      }
      return domNode;

    },

  };
  return <div>{parse(rawHTML, options)}</div>;
}

export default RenderHTML;