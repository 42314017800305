/**
 * Renders the student home view
 * with available assessments and results
 */

import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../translate/Translate';

// Components
import Results from './Results';

export const InstructorHome = observer(class InstructorHome extends React.Component {
  render() {
    return (
      <Results
        {...this.props}
      />
    )
  }
})

export default translate('InstructorHome')(InstructorHome);
