import React from 'react';
import Head from '../common/Head'

// Translation
import translate from '../translate/Translate';

// Icons
import * as icons from '../ui/Icons';

const ConfigError = class ConfigError extends React.Component {
  render() {
    const { store, translation } = this.props;
    const { UserStore } = store;

    return (
      <main
        id="main-content"
        className="page wrap"
        tabIndex="-1"
        aria-labelledby="page-title"
      >
        <Head>
          <title>{translation.heading} | SmarterMeasure</title>
        </Head>

        {UserStore.signOnError !== 'userMissing' &&
          <div className="panel panel--sm panel--solo">
            <i className="panel__icon">
              <icons.lock />
            </i>

            <h1 id="page-title" className="panel__title">
              {translation.heading}
            </h1>

            <p>{translation.body}</p>

            <h6><strong>{translation.error_details.heading}</strong></h6>

            <code className="code-block">
              {translation.error_details[UserStore.signOnError]}
            </code>
          </div>
        }

        {UserStore.signOnError === 'userMissing' &&
          <div className="panel panel--sm panel--solo">
            <p>{translation.error_details[UserStore.signOnError]}</p>
          </div>
        }
      </main>
    )
  }
}

export default translate('ConfigError')(ConfigError);
