import { action, observable } from 'mobx';
import { api } from '../config';

// Helpers
import { formatQuery } from '../helpers';

// AppStore (used for loading, error throwing, etc.)
import AppStore from './AppStore';

// UserStore (used to access user data)
import UserStore from './UserStore';

let obx = observable({
  /**
   * listUsers - Gets all users for an install
   * @param {object} query
   */
  users: [],
  usersNext: null,

  listUsers: action((query, append, callback) => {
    AppStore.startLoading('listUsers');

    const url = `/installs/${UserStore.installSid}/users${formatQuery(query)}`;

    api.get(url)
      .then(response => {
        if(!append) {
          obx.users = response.data.results;
        } else {
          obx.users = obx.users.concat(response.data.results);
        }

        if(response.data.next) {
          obx.usersNext = response.data.next;
        } else {
          obx.usersNext = null;
        }

        if(callback) {
          callback(obx.users);
        }

        AppStore.finishLoading('listUsers');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * getUser - Gets a specific user
   * Note: At the time of writing, there is no way to get a single user
   * so this is really bad, but I have to list *all* users and then check
   * if the one I’m looking for is in there…
   * @param {string} userSid
   */
  user: null,

  getUser: action(userSid => {
    const filterUsers = (users) => {
      //eslint-disable-next-line
      users.some(user => {
        if(user.sid === userSid) {
          obx.user = user;
          return true;
        }
      });
    }

    obx.listUsers({ limit: 1000 }, false, filterUsers);
  }),

  /**
   * Create a new admin user.
   *
   * @function createUser
   * @param {object} payload
   * @param {function} callback
   */
  createUser: (payload, callback) => {
    AppStore.startLoading('createUser');

    const url = `/installs/${UserStore.installSid}/users`;

    api.post(url, payload)
      .then(() => {
        AppStore.finishLoading('createUser');

        if(callback) {
          callback();
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }
});

export default obx;