import React from 'react';
import Head from '../common/Head'

// Translation
import translate from '../translate/Translate';

// Icons
import * as icons from '../ui/Icons';

const Unauthorized = class Unauthorized extends React.Component {
  render() {
    const { translation } = this.props;

    return (
      <main
        id="main-content"
        className="page wrap"
        tabIndex="-1"
        aria-labelledby="page-title"
      >
        <Head>
          <title>{translation.heading} | SmarterMeasure</title>
        </Head>

        <div className="panel panel--sm panel--solo">
          <i className="panel__icon">
            <icons.lock />
          </i>

					<h1 id="page-title" className="panel__title">
						{translation.heading}
					</h1>

					<p>{translation.body}</p>
				</div>
      </main>
    )
  }
}

export default translate('Unauthorized')(Unauthorized);
