/**
 * Renders a list of assessment changes
 */

 import React from 'react';
 import { observer } from 'mobx-react';

 // Helpers
 import { deformatQuery } from '../../helpers';

 // Translation
 import translate from '../translate/Translate';

 // Components
 import SurveyDiff from './SurveyDiff';
 import Date from '../common/Date';
 import Dropdown from '../common/Dropdown';
 import LoadMore from '../common/LoadMore';

 export const SurveyHistory = observer(class SurveyHistory extends React.Component {
   constructor() {
     super();

     // Set the initial state
     this.state = {
       currentVersionSid: null,
       diffName: null,
       showDiff: false
     }
   }

   componentDidMount() {
     this.listSurveyHistory();
   }

   /**
    * listSurveyHistory - Gets previous versions of a survey
    */
   listSurveyHistory = () => {
     const { surveySid, store } = this.props;
     const { SurveyStore } = store;

     const query = {
       limit: 24
     };

     const callback = (data) => {
       this.setState({
         currentVersionSid: data[0]?.surveyVersionSid || null
       });
     };

      SurveyStore.listSurveyHistory(surveySid, query, false, callback);
   }

   /**
    * handleView - shows a preview of a version of a survey
    * @param {object} version - survey version that we are going to see
    * @param {string} version.surveySid - survey sid (SU)
    * @param {string} version.surveyVersionSid - survey version sid (SV)
    */
   handleView = (version) => {
     const { installSid, token } = this.props.store.UserStore;

     const url = `/survey/${version.surveySid}?install=${installSid}&surveySid=${version.surveySid}&versionSid=${version.surveyVersionSid}&ro=true&token=${token}`;

     window.open(url);
   }

   /**
    * handleCompare - gets a diff of the selected version compared to the current version
    * @param {object} version - survey version that will be compared to the current
    * @param {string} version.surveySid - survey sid (SU)
    * @param {string} version.surveyVersionSid - survey version sid (SV)
    */
   handleCompare = (version, name) => {
     const { currentVersionSid } = this.state;
     const { SurveyStore } = this.props.store;

     const callback = () => {
       this.setState({
         diffName: name,
         showDiff: true
       });
     }

     SurveyStore.getVersionDiff(version.surveySid, version.surveyVersionSid, currentVersionSid, callback);
  }

   /**
    * handleRestore - restores an older version of a survey as a draft
    * @param {object} version - survey version data
    * @param {object} version.surveyJson - survey questions
    */
   handleRestore = (version) => {
     const { onRestore, store, translation } = this.props;
     const { AppStore } = store;

     if(onRestore) {
       const callback = () => {
         AppStore.toast = translation.restore.success;
       }

       onRestore(version, callback);
     }
   }

   render() {
     const { surveySid, store, translation } = this.props;
     const { diffName, showDiff } = this.state;
     const { AppStore, SurveyStore } = store;
     const { surveyHistory, surveyHistoryTotal, surveyHistoryNext, diff } = SurveyStore;

     return (
       <div className="panel panel--solo">
         {surveyHistory.length === 0 && !AppStore.loading &&
           <span className="no-results">{translation.no_results}</span>
         }

         {surveyHistory.length > 0 &&
           <table>
             <caption className="meta">{translation.caption}</caption>

             <thead>
               <tr>
                 <th scope="col">{translation.version}</th>
                 <th scope="col">{translation.published}</th>
                 <th scope="col">{translation.user}</th>
                 <th scope="col">{translation.actions}</th>
               </tr>
             </thead>

             <tbody>
               {surveyHistory.map(
                 (version, i) => {
                   const options = [
                     {
                       title: translation.view,
                       action: () => {
                         this.handleView(version);
                       }
                     }, {
                       title: translation.compare,
                       action: () => {
                         this.handleCompare(version, `${translation.version} ${surveyHistoryTotal - i}`);
                       }
                     }, {
                       title: translation.restore.button,
                       action: () => {
                         this.handleRestore(version);
                       }
                     }
                   ];

                   // If this is the most recent version, remove
                   // the restore and compare options.
                   if(i === 0) {
                     options.splice(2, 1);
                     options.splice(1, 1);
                   }

                   return (
                     <tr key={i}>
                       <td>
                         {translation.version} {surveyHistoryTotal - i}
                         {i === 0 &&
                           ' '
                         }
                         {i === 0 &&
                           <strong className="tag">{translation.current}</strong>
                         }
                       </td>
                       <td>
                         <Date
                           {...this.props}
                           date={version.startDate}
                           dateFormat="M/d/yy h:mm a"
                         />
                       </td>
                       <td>
                         {
                           version.user.firstName && version.user.lastName ?
                             `${version.user.firstName} ${version.user.lastName}` :
                             translation.user_not_found
                         }
                       </td>
                       <td className="table__cell--sm">
                         <Dropdown
                           {...this.props}
                           id={`${version.sid}-actions`}
                           options={options}
                           triggerText={translation.actions}
                         />
                       </td>
                     </tr>
                   )
                 }
               )}
             </tbody>
           </table>
         }

         {surveyHistoryNext &&
           <LoadMore
             {...this.props}
             buttonText={translation.load_more}
             loadFunction={(callback) => {
               SurveyStore.listSurveyHistory(surveySid, deformatQuery(surveyHistoryNext), true, callback)
             }}
             infinite={true}
           />
         }

         {showDiff &&
           <SurveyDiff
             {...this.props}
             oldVersion={diff}
             onClose={() => this.setState({ diffName: null, showDiff: false })}
             title={translation.comparing.replace('%diff%', diffName)}
           />
         }
       </div>
     )
   }
 })

 export default translate('SurveyHistory')(SurveyHistory);
