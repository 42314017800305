import { observable, action, toJS } from 'mobx';
import { api } from '../config.js'

//AppStore (used for loading, error throwing, etc.)
import AppStore from './AppStore';

// UserStore (used to access user data)
import UserStore from './UserStore';

// Helpers
import { formatQuery } from '../helpers';

let obx = observable({

  creatorRendered: false,
    /**
     * deleteSurvey - Deletes one survey from an install
     * @param {string} surveySid - survey sid (SU)
     * @param {function} callback - function to be called after api call
     */
  deleteSurvey: action ((surveySid, callback) => {
    AppStore.startLoading('deleteSurvey');

    const url = `installs/${UserStore.installSid}/surveys/${surveySid}`

    api.delete(url)
        .then(() => {
            if (callback) {
                callback();
            }
            AppStore.finishLoading('deleteSurvey');
        })
    }),
    /**
     * createSurvey - Creates a new survey
     * @param {object} payload - survey data to be sent to the api
     * @param {string} payload.userSid - userSid (US) is added so we can keep track of who changed the survey
     * @param {function} callback - function to be ran after api call
     */
    createSurvey: action((payload, callback) => {
    AppStore.startLoading('createSurvey');

    const url = `/installs/${UserStore.installSid}/surveys`;

    payload.userSid = UserStore.userData.userSid;

    api.post(url, payload)
        .then(response => {
          if(callback) {
            callback(response.data);
          }
          AppStore.finishLoading('createSurvey');
        })
        .catch(error => {
            AppStore.throwError(error)
        })
    }),

    surveys: [],
    /**
     * listSurveys - Gets all surveys in an app install
     * @param {object} query - query to be passed to the api, none of this is required
     * @param {number} query.limit - number of surveys we want to return
     * @param {number} query.offset - number of surveys we want to skip over before starting to return surveys
     * @param {string} query.surveySid - survey sid (SU) can be added to get a single survey
     * @param {boolean} append - determines if this is the beginning of the array or more results to be added
     */
    listSurveys: action((query)=> {
      const url = `/installs/${UserStore.installSid}/surveys${formatQuery(query)}`;

      api.get(url)
        .then(response => {
          obx.surveys = response.data
        })
        .catch(error => {
          AppStore.throwError(error)
        })
    }),

    surveyHistory: [],
    surveyHistoryNext: null,
    surveyHistoryTotal: 0,
    /**
     * listSurveyHistory - Gets the previous versions of a survey
     * @param {string} surveySid - survey sid (SU)
     * @param {object} query - query to be passed to the api, none of this is required
     * @param {number} query.limit - number of surveys we want to return
     * @param {number} query.offset - number of surveys we want to skip over before starting to return surveys
     * @param {boolean} append - determines if this is the beginning of the array or more results to be added
     * @param {function} callback - function to be called after the api call
     */
    listSurveyHistory: action((surveySid, query, append, callback) => {
      if(!append) {
        AppStore.startLoading('listSurveyHistory');
      }
      const url = `/installs/${UserStore.installSid}/surveys/${surveySid}/history${formatQuery(query)}`;

      api.get(url)
        .then(response => {
          if(!append) {
            obx.surveyHistory = response.data.results;
          } else {
            obx.surveyHistory = obx.surveyHistory.concat(response.data.results);
          }

          obx.surveyHistoryTotal = response.data.total;

          if(response.data.next) {
            obx.surveyHistoryNext = response.data.next;
          } else {
            obx.surveyHistoryNext = null;
          }

          if(callback) {
            callback(response.data.results);
          }

          if(!append) {
            AppStore.finishLoading('listSurveyHistory');
          }
        })
        .catch(error => {
          AppStore.throwError(error);
        })
    }),
    /**
   * restoreVersion - Takes an old survey version and sets it to the draftJson
   * @param {string} surveySid - survey sid (SU)
   * @param {object} payload - old survey version data that will become the new draft
   * @param {function} callback - function to be ran after api call
   * @param {function} errorCallback - function to be ran if an error is thrown by api
   * @param {boolean} background - hides/shows loading indicator
   */
  restoreVersion: action((surveySid, payload, callback, errorCallback, background) => {
    if(!background) {
      AppStore.startLoading('restoreVersion');
    }

    const url = `/installs/${UserStore.installSid}/surveys/${surveySid}`;

    api.put(url, payload)
      .then(() => {
        if(callback) {
          callback();
        }

        if(!background) {
          AppStore.finishLoading('restoreVersion');
        }
      })
      .catch(error => {
        if(errorCallback) {
          errorCallback();
        }

        AppStore.throwError(error);
      })
  }),
  survey: {},
  /**
   * getSurveyVersion - Gets a specific survey version
   * @param {string} surveySid - survey sid (SU)
   * @param {string} versionSid - survey version sid (SV)
   */
  getSurveyVersion: action(async (surveySid, versionSid) => {
    const url = `/installs/${UserStore.installSid}/surveys/${surveySid}/version/${versionSid}`;
    await api.get(url)
    .then(response => {
      obx.survey = response.data;
      return response;
    })
  }),
  /**
   * createSurveyVersion - Create a new version for a survey
   * @param {string} surveySid - survey sid (SU)
   * @param {object} payload - survey data to be passed to the api
   * @param {function} callback - function to be ran after api call
   */
  createSurveyVersion: action((surveySid, payload, callback) => {
    AppStore.startLoading('updateSurvey');

    const url = `/installs/${UserStore.installSid}/surveys/${surveySid}/versions`;

    //Pass the userSid as well as the survey so we know who published
    payload.userSid = UserStore.userData.userSid

    api.post(url, payload)
      .then(response => {
        if (callback) {
          callback(response.data);
        }

        AppStore.finishLoading('updateSurvey');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
    }),
    /**
   * getSurvey - Gets a specific survey
   * @param {string} surveySid - survey sid (SU)
   * @param {function} callback - function to be ran after api call
   */
    getSurvey: action(async (surveySid, callback) => {
        AppStore.startLoading('getSurvey');

        let survey = {}
        const url = `/installs/${UserStore.installSid}/surveys?surveySid=${surveySid}`;

        await api.get(url)
            .then(response => {
                obx.survey = toJS(response.data[0]);
                survey = toJS(response.data[0])
                if(callback) {
                    callback();
                }
                AppStore.finishLoading('getSurvey');
            })
            .catch(error => {
                AppStore.throwError(error);
            })

        return survey;
    }),
    /**
   * getVersionDiff - Gets an older survey version to compare against current version
   * @param {string} surveySid - survey sid (SU)
   * @param {string} versionSid (the version being compared)
   * @param {string} baseVersionSid (the version being compared against)
   * @param {function} callback - function to be ran after api call
   */
  diff: null,

  getVersionDiff: action((surveySid, versionSid, baseVersionSid, callback) => {
    AppStore.startLoading('getVersionDiff');

    const url = `/installs/${UserStore.installSid}/surveys/${surveySid}/version/${versionSid}`;

    api.get(url)
      .then(response => {
        obx.diff = response.data;

        // Remove unnecessary data from diff version
        delete obx.diff.dateUpdated;
        delete obx.diff.lastPublishedDate;
        delete obx.diff.type;

        if(callback) {
          callback();
        }

        AppStore.finishLoading('getVersionDiff');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),
  /**
   * saveAttempt - Update this when it is needed
   *
   */
  saveAttempt: action(() => {
    console.log('This will be implemented later');
  }),

    /**
     * handleDraft - sets the draftJson of an existing Survey
     * @param {string} surveySid - survey sid (SU)
     * @param {object} payload draftJson to be set in the existing Survey
     * @param {function} callback - function to be ran after api call
     */

  handleDraft: action((surveySid, payload, callback) => {
      AppStore.startLoading('uploadDraft');

      const url = `/installs/${UserStore.installSid}/surveys/${surveySid}/draft`;
      payload = {
          draftJson: payload
      }
      api.put(url, payload)
          .then(response => {
              if (callback) {
                  callback(response.data);
              }
              AppStore.finishLoading('uploadDraft');
          })
          .catch(error => {
              AppStore.throwError(error);
          })
      return
  }),

    /**
     * getSurveyName - gets the name of the survey to display in the dropdown by looping to find the matching object by the SurveySid
     * @param {string} surveySid - survey sid (SU)
     */

    getSurveyName: action( (surveySid) => {
      if(!obx.surveys) {
        obx.listSurveys();
        return
      }
      if(surveySid === null){
          return "No Survey Selected"
      }

      for(const survey of obx.surveys){
        if(survey.surveySid === surveySid){
          return survey.name + ' (' + survey.key + ')';
        }
      }
    }),

    /**
     *
     * checkForSurvey - Checks if the requirements have been met to show a survey
     * @param {function} callback - function to be called after api call
     */
    surveyJson: null,
    detailText: null,
    name: null,
    facultySurveySid:null,

    checkForSurvey: action((surveySid, callback, facultyAssessmentGroupSid = null) => {
      const url = `/installs/${UserStore.installSid}/surveys/${surveySid}/users/${UserStore.userData.userSid}/checkTriggers`;

      if(!surveySid){
          return;
      }

      api.get(url)
        .then(response => {
          if(response.data.surveyJson && response.data.showSurvey) {
            // Store for use when presenting the survey to the user
            obx.surveyJson = response.data.surveyJson;
            obx.detailText = response.data.detailText;
            obx.name = response.data.name

          if(facultyAssessmentGroupSid){
              obx.facultySurveySid = surveySid;
              obx.facultyAssessmentGroupSid = facultyAssessmentGroupSid;
          }

            if (callback) {
              callback();
            }
          }
        })
        .catch(error => {
          AppStore.throwError(error);
        })
    }),

    /**
    *  sendSurveyData - sending an surveyData object to WGU's plg survey bucket
    *  @param {Object} surveyData - survey response object to be uploaded to WGU's S3 bucket
    *
    */
    sendSurveyData: action((surveyData) => {
      const { role } = UserStore.userData;

      const url = "/surveys/cptSurvey/data";

      const payload = {
        assessmentGroupSid: UserStore.assessmentGroupSid || obx.facultyAssessmentGroupSid,
        userSid: UserStore.userData.userSid,
        role: role === "learner" ? role : "faculty",
        surveySid:
          role === "learner"
            ? UserStore.assessmentGroup.studentSurveySid
            : obx.facultySurveySid,
      };

      if (surveyData) {
        payload.responseJson = surveyData;
      }

      api.post(url, payload)
        .catch((error) => {
          AppStore.throwError(error);
        });
    }),
    returnSurveyMetaData: action(() => {
      const { role } = UserStore.userData;

      return {
        assessmentGroupSid: UserStore.assessmentGroupSid || obx.facultyAssessmentGroupSid,
        userSid: UserStore.userData.userSid,
        role: role === "learner" ? role : "faculty",
        surveySid:
          role === "learner"
          ? UserStore.assessmentGroup.studentSurveySid
          : obx.facultySurveySid,
    }
  })
});

export default obx;