export let routes = {
    index: '/',
    expired: '/session-expired',
    report: '/report/:userId',
    reviewAssessment: '/assessment-review/:assessmentId',
    signIn: '/sign-in',
    takeAssessment: '/assessment/:assessmentId',
    unauthorized: '/unauthorized',
    configError: '/config-error',
    serverError: '/server-error',
    surveys: '/surveys',
    assessmentGroups: '/assessment-groups',
    assessments: '/assessments',
    results: '/results',
    result: '/result/:attemptSid',
    users: '/users',
    user: '/users/:userSid',
    userAdd: '/users/add',
    survey: '/survey/:surveySid',
    lriReport: '/lri-report'

};
