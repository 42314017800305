import React from 'react';
import { observer } from 'mobx-react';
import Head from '../common/Head'
import {Link} from 'react-router-dom';

// Helpers
import { getUrlSegment } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Components
import ResultCard from '../common/ResultCard';
import SurveyModal from "../reports/SurveyModal";

const Result = observer(class Result extends React.Component {

  constructor() {
    super();

    this.state = {
      isCompleted: false,
      showSurvey: false
    }
  }

  componentDidMount() {
    const { AssessmentStore, SurveyStore, UserStore } = this.props.store;
    const { assessmentGroup, userData } = UserStore;
    let surveySid;

    const attemptSid = getUrlSegment(2);

    AssessmentStore.getAttempt(attemptSid);

    if(userData.role.toLowerCase() === 'learner') {
      surveySid = assessmentGroup.studentSurveySid;
    } else {
      surveySid = assessmentGroup?.facultySurveySid
    }


    if(surveySid) {
      SurveyStore.checkForSurvey(surveySid, this.surveyState);
    }
  }


  componentDidUpdate() {
    if (this.state.showSurvey) {
      // This is to disable scrolling while the modal is active.
      document.body.style.overflow = 'hidden';
    } else if (!this.state.showSurvey) {
      // Allows scrolling again
      document.body.style.overflow = '';
    }
  }

  // Used to set state from child component
  surveyState = () => {
    this.setState({
      showSurvey: !this.state.showSurvey
    })
  }

  render() {
    const { store, translation } = this.props;
    const { AssessmentStore, SurveyStore } = store;
    const { attempt } = AssessmentStore;

    return (
      <main
        id="main-content"
        className="page"
        tabIndex="-1"
        aria-labelledby="page-title"
      >
        <Head>
          <title>{translation.heading} | SmarterMeasure</title>
        </Head>

        <div className="wrap">
          <header className="page__head">
            <h1 id="page-title" className="page__title">{translation.heading}</h1>
          </header>

          {attempt &&
            <ResultCard
              {...this.props}
              attempt={attempt}
              hideRetake
            />
          }

          <Link to="/#/results">
            <a className="btn btn--center btn--outline"
               href="#/"
            >
              {translation.back}
            </a>
          </Link>
          {this.state.showSurvey &&
          <SurveyModal
              {...this.props}
              surveyJson={SurveyStore.surveyJson}
              surveyState={this.surveyState}
              focusFirstQuestionAutomatic={false}
              showCompletedPage={false}
          />
          }
        </div>
      </main>
    )
  }
})

export default translate('Result')(Result);