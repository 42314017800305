import React from 'react';
import { observer } from 'mobx-react';

// Helpers
import { getUrlSegment } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Components
import ResultCard from '../common/ResultCard';
import Date from '../common/Date';

const ResultList = observer(class ResultList extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      incomplete: []
    }
  }

  componentDidMount() {
    const { AssessmentStore, UserStore } = this.props.store;
    const { targetUserData, userData} = UserStore;

    if(AssessmentStore.assessmentGroups.length === 0) {
      let query = null;

      if(userData.role.toLowerCase() !== 'learner' && targetUserData?.userSid) {
        query = {
          userSid: targetUserData.userSid
        };
      }

      AssessmentStore.listAssessmentGroups(query);
    }

    // Show all incomplete assessments to non-learners,
    // but wait for assessment groups to be available
    if(userData.role.toLowerCase() !== 'learner') {
      this.getIncompleteAttempts();
    }
  }


  /**
   * Get incompleted attempts
   *
   * @function getIncompleteAttempts
   * @param {string} assessmentGroupSid
   */
  getIncompleteAttempts = assessmentGroupSid => {
    const { AssessmentStore, UserStore } = this.props.store;
    const { targetUserData } = UserStore;

    const query = {
      completionState: 'all-incomplete',
      userSid: targetUserData.userSid || getUrlSegment(2)
    }

    if(assessmentGroupSid) {
      query.assessmentGroupSid = assessmentGroupSid;
    }

    const callback = (attempts) => {
      this.setState({
        incomplete: attempts
      });
    }

    AssessmentStore.listAttempts(query, null, callback, true);
  }

  populateStudentName(targetUserData) {
    const studentFullName = `${targetUserData.firstName} ${targetUserData.lastName}`
    if(studentFullName === "undefined undefined") {
      return "This student";
    } else {
      return `${targetUserData.firstName} ${targetUserData.lastName}`;
    }
  }

  /**
   * Only show results for a selected assessment group
   *
   * @function filterResults
   * @param {object} query
   */
  filterResults(query) {
    const { store } = this.props;
    const { AssessmentStore, UserStore } = store;

    query.completionState = 'latest-complete';
    query.userSid = UserStore.targetUserData.userSid || getUrlSegment(2);

    Object.keys(query).forEach(key => {
      if(!query[key]) {
        delete query[key];
      }
    });

    AssessmentStore.listAttempts(query);
    this.getIncompleteAttempts(query.assessmentGroupSid);
  }

  render() {
    const { attempts, store, translation } = this.props;
    const { incomplete } = this.state;
    const { AppStore, AssessmentStore, UserStore } = store;
    const { assessmentGroups } = AssessmentStore;
    const { targetUserData } = UserStore;
    const { role } = UserStore.userData;

    return (
      <div>
        {role.toLowerCase() === 'learner' &&
          <section
            className="panel panel--card note"
            aria-labelledby="interpret-heading"
          >
            <h3 className="panel__title panel__title-alt">
              {translation.intro}
            </h3>
            <div className="panel--md panel--solo-alt panel__content">
              <p>{translation.intro_text}</p>
            </div>
            <h3
              id="interpret-heading"
              className="panel__title panel__title-alt"
            >
              {translation.interpret}
            </h3>
            <div className="panel--md panel--solo-alt panel__content" dangerouslySetInnerHTML={{__html: translation.interpret_html}}></div>
          </section>
        }

        {assessmentGroups.length > 0 && role.toLowerCase() !== 'learner' &&
          <label className="select grid-filter">
            <select
              onChange={(e) => this.filterResults({ assessmentGroupSid: e.target.value })}
            >
              <option value="">{translation.filter_placeholder}</option>

              {assessmentGroups.map(
                (assessmentGroup, i) =>
                <option
                  key={i}
                  value={assessmentGroup.assessmentGroupSid}
                >
                  {assessmentGroup.name}
                </option>
              )}
            </select>
          </label>
        }

        {
          attempts.length === 0 &&
          !AppStore.loading &&
          <span className="no-results panel">
            {role.toLowerCase() === 'learner' && translation.no_results}
            {role.toLowerCase() !== 'learner' && translation.no_results_user}
          </span>
        }

        {attempts.length > 0 &&
          <ul
            id="results"
            className={`grid grid--wrap${
              role.toLowerCase() === 'learner' ? ' grid--grouped' : ''
            }`}
            data-columns="1"
          >
            {attempts.map(
              (attempt, i) =>
              <li
                className="grid__item"
                key={i}
              >
                <ResultCard
                  {...this.props}
                  attempt={attempt}
                  parent={AssessmentStore[`previousAttempts-${attempt.attemptSid}`] && AssessmentStore[`previousAttempts-${attempt.attemptSid}`].length > 0}
                />

                {AssessmentStore[`previousAttempts-${attempt.attemptSid}`] &&
                  AssessmentStore[`previousAttempts-${attempt.attemptSid}`].map(
                    (prevAttempt, j) =>
                    <ResultCard
                      {...this.props}
                      key={j}
                      attempt={prevAttempt}
                      child
                      hidePrevious
                    />
                  )
                }
              </li>
            )}
          </ul>
        }

        {incomplete.length > 0 &&
          <aside
            className="panel panel--md panel--solo-alt"
            aria-labelledby="incomplete-title"
          >
            <h3
              id="incomplete-title"
              className="panel__title"
            >
              {translation.incomplete.heading}
            </h3>

            <p>{translation.incomplete.body.replace('%name%', this.populateStudentName(targetUserData))}</p>

            <table className="data data--alt">
              <caption className="meta">{translation.incomplete.caption}</caption>
              <thead>
                <tr>
                  <th scope="col">{translation.incomplete.assessment}</th>
                  <th scope="col">{translation.incomplete.group}</th>
                  <th scope="col">{translation.incomplete.last_attempt}</th>
                </tr>
              </thead>
              <tbody>
                {incomplete.map((assessment, i) =>
                  <tr key={i}>
                    <td>{assessment.assessmentName}</td>
                    <td>{assessment.assessmentGroupName}</td>
                    <td>
                      <Date
                      {...this.props}
                      date={assessment.dateUpdated}
                      dateFormat='MM-dd-yyyy'
                    />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </aside>
        }
      </div>
    )
  }
})

export default translate('ResultList')(ResultList);