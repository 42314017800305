import React from 'react';
import { observer } from 'mobx-react';
import {Link} from 'react-router-dom';

// Translation
import translate from '../translate/Translate';

// Components
import Dropdown from '../common/Dropdown';

// Icons
import * as icons from './Icons';

const Nav = observer(class Nav extends React.Component {
  renderAdminNav() {
    const { translation } = this.props;

    return [
      <li key="0">
        <Link to="/" className="nav__item">
            <icons.dashboard />
            {translation.dashboard}
        </Link>
      </li>,
      <li key="1">
        <Link to="/surveys" className="nav__item">
            <icons.book />
            {translation.surveys}
        </Link>
      </li>,
      <li key="2">
        <Link to="/assessment-groups" className="nav__item">
            <icons.folder />
            {translation.assessmentGroups}
        </Link>
      </li>,
      <li key="3">
        <Link to="/assessments" className="nav__item">
            <icons.paperStack />
            {translation.assessments}
        </Link>
      </li>,
      <li key="4">
        <Link to="/users" className="nav__item">
            <icons.people />
            {translation.users}
        </Link>
      </li>
    ]
  }

  renderInstructorNav() {
    const { translation } = this.props;

    return [
      <li key="0">
        <Link href="/" className="nav__item">
            <icons.graph />
            {translation.results}
        </Link>
      </li>,
      <li key="1">
        <Link to="/assessment-groups" className="nav__item">
            <icons.folder />
            {translation.assessmentGroups}
        </Link>
      </li>,
      <li key="2">
        <Link to="/assessments" className="nav__item">
            <icons.paperStack />
            {translation.assessments}
        </Link>
      </li>,
    ]
  }

  renderUserBadge = (firstName) => {
    return (
      <span
        className={`badge badge--inline badge--${firstName.charAt(0).toLowerCase()}`}
      >
        {firstName.charAt(0)}
      </span>
    );
  }

  render() {
    const { store, translation } = this.props;
    const { UserStore } = store;
    const { targetUserId, userData } = UserStore;

    const userMenu = [
      // {
      //   title: translation.user_menu.settings,
      //   url: '/settings'
      // }
    ];

    if(userData?.role?.toLowerCase() === 'administrator') {
      userMenu.push({
        title: translation.user_menu.sign_out,
        action: UserStore.logout
      });
    }

    // TEMP: Don’t show navigation for students
    // because we don’t have any links for them
    if(userData && userData.role && userData.role.toLowerCase() === 'learner') {
      return null;
    }

    return (
      <nav id="primary-nav" className="nav nav--primary">
        <ul className="nav__list">
           {userData && userData.role && userData.role.toLowerCase() === 'learner' &&
            <li>
              <Link to="/"className="nav__item">
                  <icons.paperStack />
                  {translation.assessments}
              </Link>
            </li>
          }

          {/* Administrator nav links */}
          {userData?.role?.toLowerCase() === 'administrator' &&
            this.renderAdminNav()
          }

          {/* Instructor nav links */}
          {userData?.role?.toLowerCase() === 'instructor' && !targetUserId &&
            this.renderInstructorNav()
          }

          {userMenu.length > 0 &&
            <li>
              <Dropdown
                {...this.props}
                icon={this.renderUserBadge(userData.firstName)}
                triggerText={userData.firstName || ''}
                triggerClassName="nav__item dropdown__trigger--invisible"
                options={userMenu}
              />
            </li>
          }
        </ul>
      </nav>
    )
  }
})

export default translate('Nav')(Nav);