/**
 * Renders the account sign in view.
 */

import React from 'react';
import { observer } from 'mobx-react';
// Helpers
import { checkValidity, getParam } from '../../helpers';

// Translation
import translate from '../translate/Translate';

const SignIn = observer(class SignIn extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      renderForm: true
    };

    // Bind this to functions
    this.handleSubmit = this.handleSubmit.bind(this);

    // Create refs
    this.username = React.createRef();
    this.password = React.createRef();
  }

  componentDidMount() {

    const { UserStore } = this.props.store;

    const account = getParam('account');
    let renderForm = true;

    // If the user is signed in, send them home
    if(UserStore.userData && UserStore.token) {
      window.location = ('/');
    }

    // If there is no account in the URL params
    // don’t render the sign in form
    if(!account) {
      renderForm = false;
    } else if (!UserStore.installSid) {
      UserStore.getAccountInstall(account)
    }

    this.setState({
      renderForm
    });
  }

  handleSubmit() {
    const { UserStore } = this.props.store;

    const payload = {
      username: this.username.current.value,
      password: this.password.current.value
    }

    UserStore.login(payload);
  }

  render() {
    const { translation } = this.props;
    const { renderForm } = this.state;

    const fields = [
      {
        id: 'username',
        ref: this.username,
        translation: translation.username,
        type: 'text'
      }, {
        id: 'password',
        ref: this.password,
        translation: translation.password,
        type: 'password'
      }
    ];
    return (
      <main
        id="main-content"
        className="page"
        tabIndex="-1"
        aria-labelledby="page-title"
      >

        <div className="panel panel--sm panel--solo">
          {renderForm &&
            <form
              className="form"
              noValidate
              onSubmit={(e) => checkValidity(e, this.handleSubmit)}
            >
              <h1 id="page-title" className="panel__title">
                {translation.heading}
              </h1>

              <fieldset>
                <ul className="form__fields">
                  {fields.map(
                    (field, i) =>
                    <li key={i}>
                      <label
                        className="form__lbl meta"
                        htmlFor={field.id}
                      >
                        {field.translation.label}
                      </label>

                      <input
                        type={field.type}
                        id={field.id}
                        placeholder={field.translation.label}
                        ref={field.ref}
                        required
                        aria-required="true"
                        data-errormsg={field.translation.error}
                        autoFocus={i === 0}
                      />
                    </li>
                  )}
                </ul>
              </fieldset>

              <button
                className="btn btn--full"
                type="submit"
              >
                {translation.submit}
              </button>
            </form>
          }

          {!renderForm &&
            <span className="no-results">{translation.no_domain}</span>
          }
        </div>
      </main>
    )
  }
})

export default translate('SignIn')(SignIn);