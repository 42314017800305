import React from 'react';

class Head extends React.Component {
  render() {
    const { title, description } = this.props;
    return (
        <div>
          <title>{title}</title>
          <meta name="description" content={description} />
        </div>
    );
  }
}

export default Head;