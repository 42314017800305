/**
 * Renders the SurveyJS creator
 */

import React from 'react';

// Translation
import translate from '../translate/Translate';

export const SurveyDesigner = class SurveyBuilder extends React.Component {
    constructor() {
        super();

        // Set up the SurveyJS Creator
        this.creator = null;

        // Set the initial state
        this.state = {
            render: false,
        }
    }

    componentDidMount() {
        this.loadSurveyBuilder();
    }

    /**
     * Load the survey builder.
     * Note: Because of the way NextJS works and the fact that the SmarterSurveys
     * needs to inject a MathJax script into the DOM, we have to load the package
     * in this kind of weird way.
     *
     * @async
     * @function loadSurveyBuilder
     */
    loadSurveyBuilder = async () => {
        this.SmarterSurveys = (await import('@smarterservices/smartersurveys'));

        this.setState({
            render: true
        });
    }

    render() {
        const { survey, onUpdate } = this.props;
        if(this.state.render) {
            if(survey){
                return (
                    <div id="create-assessment" className="creator">
                        <this.SmarterSurveys.default.SurveyBuilder
                            isSurvey = {true}
                            name = {survey?.name || null}
                            onUpdate={surveyData => {
                                survey.surveyJson = surveyData;
                                onUpdate(survey);
                            }}
                            surveyData={survey?.surveyJson || null}
                            surveyNameReadonly={true}
                        />
                    </div>
                )
            }
        }
        return null;
    }
}

export default translate('SurveyDesigner')(SurveyDesigner);
