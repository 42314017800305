/**
 * Renders a date with time zone
 */

import React from 'react';

// Dependencies
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

const Date = class Date extends React.Component {
  render() {
    const { date, dateFormat } = this.props;
    const { timeZone } = this.props.store.UserStore;

    return (
      `${format(
        utcToZonedTime(parseISO(date), timeZone),
        dateFormat
      )}`
    )
  }
}

export default Date;
