/**
 * Renders Step 1 of 4 of the QTI Import form
 */

import React from 'react';
import Head from '../common/Head'

// Helpers
import { checkValidity } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Dependencies
import File from '../common/File';
import Tooltip from "../common/Tooltip";

export const ImportAssessmentUpload = class ImportAssessmentUpload extends React.Component {
  componentDidMount() {
    const { translation } = this.props;
    const { AppStore } = this.props.store;
    AppStore.drawerTitle = translation.heading;
  }

  componentWillUnmount() {
    const { AssessmentStore } = this.props.store;
    AssessmentStore.assessment = null;
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;

    if(onSubmit) {
      onSubmit();
    }
  }

  render() {
    const { translation, handleChange, handleFileChange } = this.props;

    const key = {
        id: 'key',
        ref: this.key,
        translation: translation.key,
        type: 'text',
        required: true
      }

    return (
      <div className="panel panel--md panel--solo">
        <Head>
          <title>{translation.heading} | SmarterMeasure</title>
        </Head>

        <form
          className="form"
          noValidate
          onSubmit={(e) => checkValidity(e, this.handleSubmit)}
        >
          <fieldset>
            <ul className="form__fields">
              <li>
                <label
                  className={`form__lbl${
                    key.required ? ' required' : ''
                  }`}
                  htmlFor={key.id}
                >
                  {key.translation.label}

                  { key.translation.tooltipText &&
                  <Tooltip
                      content={key.translation.tooltipText}
                      className="cplt-label-tooltip"
                      right
                  />
                  }
                </label>
                <input
                  type={key.type}
                  id={key.id}
                  required={key.required ? true : false}
                  aria-required={key.required ? 'true' : 'false'}
                  data-errormsg={key.translation.error}
                  ref={key.ref}
                  onChange={handleChange('key')}
                />
              </li>
              <li>
                <File
                  {...this.props}
                  multiple={false}
                  onAdd={handleFileChange}
                  required={true} />
              </li>
            </ul>
          </fieldset>

          <div className="panel__action">
            <button
              className="btn"
              type="submit"
            >
              {translation.next}
            </button>
          </div>
        </form>
      </div>
      )
  }
}

export default translate('ImportAssessmentUpload')(ImportAssessmentUpload);