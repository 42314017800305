/**
 * Renders a user detail view
 */

import React from 'react';
import { observer } from 'mobx-react';
import Head from '../common/Head'

// Helpers
import { getUrlSegment } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Components
import ResultList from './ResultList';

// Icons
import * as icons from '../ui/Icons';

 const User = observer(class User extends React.Component {
  componentDidMount() {
    const { AssessmentStore, EnrollmentStore } = this.props.store;
    const userSid = getUrlSegment(2);


    // Get the user’s data
    EnrollmentStore.getUser(userSid);

    // Get any assessment results for the user
    const query = {
      completionState: 'all-complete',
      limit: 1000,
      userSid: userSid
    };

    AssessmentStore.listAttempts(query);
  }

  render() {
    const { store } = this.props;
    const { AssessmentStore, EnrollmentStore } = store;
    const { attempts } = AssessmentStore;
    const { user } = EnrollmentStore;

    if(user) {
      return (
        <main
          id="main-content"
          className="page"
          tabIndex="-1"
          aria-labelledby="page-title"
        >
          <Head>
            <title>{user && `${user.firstName} ${user.lastName}`} | SmarterMeasure</title>
          </Head>

          <div className="wrap">
            <header className="page__head">
              <h1 id="page-title" className="page__title">
                {user && `${user.firstName} ${user.lastName}`}
              </h1>
            </header>

            <div className="page__content">
              <section className="page__primary">
                <ResultList
                  {...this.props}
                  attempts={attempts}
                />
              </section>

              <aside className="vcard page__secondary">
                <ul className="vcard__details">
                  {(user.preferredEmail || user.email) &&
                    <li>
                      <icons.envelope />

                      <a
                        href={`mailto:${user.preferredEmail ? user.preferredEmail : user.email}`}
                        title={`Email ${user.firstName} ${user.lastName}`}
                      >
                        {user.preferredEmail ? user.preferredEmail : user.email}
                      </a>
                    </li>
                  }

                  {user.mobilePhone &&
                    <li>
                      <icons.mobile />
                      {user.mobilePhone}
                    </li>
                  }

                  {user.homePhone &&
                    <li>
                      <icons.phone />
                      {user.homePhone}
                    </li>
                  }

                  {user.address &&
                    <li>
                      <icons.home />
                      {user.address}
                    </li>
                  }
                </ul>
              </aside>
            </div>
          </div>
        </main>
      )
    }

    return null;
  }
})

export default translate('User')(User);
