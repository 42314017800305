/**
 * Renders an assessment group card
 */

import React from 'react';

// Helpers
// import { getPathname } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Components
import EditAssessmentGroup from '../forms/EditAssessmentGroup';
import EditAssessmentGroupItem from '../forms/EditAssessmentGroupItem';

// Icons
import * as icons from '../ui/Icons';
import Select from "react-select";
import {observer} from "mobx-react"

const AssessmentGroupCard = observer(class AssessmentGroupCard extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      assessments: [],
      expanded: false,
      studentSurveySid: null,
      facultySurveySid: null
    };


    this.studentSurveySid = React.createRef();
    this.facultySurveySid = React.createRef();

    // Bind this to functions
    this.handleExpand = this.handleExpand.bind(this);
    this.handleEditAssessment = this.handleEditAssessment.bind(this);
    this.handleNewAssessment = this.handleNewAssessment.bind(this);
    this.handleStudentSurveyChange = this.handleStudentSurveyChange.bind(this);
    this.handleFacultySurveyChange = this.handleFacultySurveyChange.bind(this);
    this.setOptions = this.setOptions.bind(this)
  }

  componentDidMount() {
  }

  async handleExpand() {
    const { assessmentGroup, store } = this.props;
    const { assessments, expanded } = this.state;
    const { AssessmentStore } = store;

    this.setState({
      expanded: !expanded
    });

    if(!assessments.length) {
      const assessments = await AssessmentStore.listGroupAssessments(
        assessmentGroup.assessmentGroupSid
      );

      this.setState({
        assessments
      });
    }
  }

  handleStudentSurveyChange(e, surveySid){
    const {AssessmentStore} = this.props.store;
    const {assessmentGroupSid} = this.props.assessmentGroup


    const payload  = {
      audience: 'student',
      surveySid: surveySid
    }

    AssessmentStore.attachSurveyToGroup(payload, assessmentGroupSid);
    this.setState({studentSurveySid: surveySid})
  }
  handleFacultySurveyChange(e, surveySid){
    const {AssessmentStore} = this.props.store;
    const {assessmentGroupSid} = this.props.assessmentGroup

    const payload  = {
      audience: 'faculty',
      surveySid: surveySid
    }

    AssessmentStore.attachSurveyToGroup(payload, assessmentGroupSid);
    this.setState({studentSurveySid: surveySid})
  }

  handleEditAssessmentGroup(e, assessmentGroup) {
    e.preventDefault();

    const { AppStore, AssessmentStore } = this.props.store;

    AssessmentStore.assessmentGroup = assessmentGroup;

    // Update the URL without causing a page load
    // const pathname = getPathname(e.target.href);

    // window.history.pushState(
    //   {},
    //   'edit-assessment',
    //   pathname
    // );

    // Open the assessment edit drawer
    AppStore.drawerContent =
      <EditAssessmentGroup
        {...this.props}
        component="EditAssessmentGroup"
        assessmentGroupSid={assessmentGroup.assessmentGroupSid}
      />;

    AppStore.drawerMod = 'wide';
  }

  handleEditAssessment(e, assessment) {
    e.preventDefault();

    const { assessmentGroup } = this.props;

    // Update the URL without causing a page load
    // const pathname = getPathname(e.target.href);

    // window.history.pushState(
    //   {},
    //   'edit-assessment',
    //   pathname
    // );

    // Open the assessment edit drawer
    this.handleAssessmentDrawer(assessmentGroup, assessment.assessmentSid);
  }

  handleNewAssessment(e) {
    e.preventDefault();

    const { assessmentGroup } = this.props;

    this.handleAssessmentDrawer(assessmentGroup);
  }

  handleAssessmentDrawer(assessmentGroup, assessmentSid = null) {
    const { AppStore } = this.props.store;

    AppStore.drawerContent =
      <EditAssessmentGroupItem
        {...this.props}
        assessmentSid={assessmentSid}
        assessmentGroupSid={assessmentGroup.assessmentGroupSid}
        component="EditAssessmentGroupItem"
      />;

    AppStore.drawerMod = 'wide';
  }

  /**
   * handleDelete - Deletes the assessment group after a confirmation
   */
  handleDelete = () => {
    const { assessmentGroup, store, translation } = this.props;
    const { AppStore, AssessmentStore } = store;

    const callback = () => {
      AssessmentStore.listAssessmentGroups();
      AppStore.toast = translation.delete.success;
    }

    AppStore.showDialog(
      translation.delete_confirm,
      'confirm',
      () => AssessmentStore.deleteAssessmentGroup(assessmentGroup.assessmentGroupSid, callback)
    );
  }
  setOptions() {
    const { SurveyStore } = this.props.store;

    const options = [{value: null, label: 'No Survey Selected'}]

    let array = SurveyStore.surveys.map((survey) => {
      const container = {};

      container.value = survey.surveySid;

      container.label = survey.key
          ? survey.name + " (" + survey.key + ")"
          : survey.name;

      return container;
    })

    return [...options, ...array]
  }

  render() {


    const { assessmentGroup, translation } = this.props;
    const { assessments, expanded } = this.state;
    const { SurveyStore} = this.props.store

    return (
      <article
        className="panel panel--card panel--clickable"
      >
        <div className="panel__content">
          <h2 className="panel__title panel__title--alt">
            {assessmentGroup.name}
          </h2>

          <ul className="list-reset">
            {assessmentGroup.key &&
              <li>
                <span className="meta">
                  {translation.key}
                </span>

                <icons.key />
                {assessmentGroup.key}
              </li>
            }

            {assessmentGroup.itemCount === 0 &&
              <li>
                <icons.paperStack />
                {translation.assessments.replace('%count%', assessmentGroup.itemCount)}
              </li>
            }

            {assessmentGroup.itemCount > 0 &&
              <li>
                <icons.paperStack />

                <button
                  className={`btn--expand${
                    expanded ? ' expanded' : ''
                  }`}
                  type="button"
                  onClick={this.handleExpand}
                >
                  {assessmentGroup.itemCount === 1 ?
                    translation.assessment.replace('%count%', assessmentGroup.itemCount) :
                    translation.assessments.replace('%count%', assessmentGroup.itemCount)
                  }

                  <icons.chevronRight />
                </button>

                {assessments.length > 0 && expanded &&
                  <ul className="data data--split">
                    {assessments.map(
                      (assessment, i) =>
                      <li key={`${assessment.assessmentSid}-${i}`}>
                        <strong>{assessment.name}</strong><span>{assessment.assessmentKey}</span>

                        {/* <a
                          className="data__link"
                          href={`/edit-assessment/${assessment.assessmentSid}`}
                          onClick={(e) => this.handleEditAssessment(e, assessment)}
                        >
                          {assessment.name}
                        </a> */}
                      </li>
                    )}
                  </ul>
                }
              </li>
            }
          </ul>
            <label>
              {translation.survey.studentLabel}
              <Select
                  onChange={e => this.handleStudentSurveyChange(e, e.value)}
                  isSearchable
                  options={this.setOptions()}
                  placeholder={SurveyStore.getSurveyName(assessmentGroup.studentSurveySid)}
                  ref = {this.studentSurveySid}
              />
            </label>
            <label>
              {translation.survey.facultyLabel}
              <Select
                  onChange={e => this.handleFacultySurveyChange(e, e.value)}
                  isSearchable
                  options={this.setOptions()}
                  placeholder={SurveyStore.getSurveyName(assessmentGroup.facultySurveySid)}
                  ref = {this.facultySurveySid}
              />
            </label>

          <button
              className="panel__btn btn btn--outline btn--positive reset--z"
              onClick={this.handleNewAssessment}
          >
            <icons.plus />
            {translation.new_assessment}
          </button>

        </div>

        <footer className="panel__action panel__action--alt">
          <button
            className="btn btn--ghost reset--z"
            href={`/assessment-groups/edit/${assessmentGroup.assessmentGroupSid}`}
            onClick={(e) => this.handleEditAssessmentGroup(e, assessmentGroup)}
            type="button"
          >
            <icons.pencil />
            <span className="meta">{translation.edit.replace('%group%', assessmentGroup.name)}</span>
          </button>

          <button
            className="btn btn--ghost btn--negative reset--z "
            href={`/assessment-groups/edit/${assessmentGroup.assessmentGroupSid}`}
            onClick={this.handleDelete}
            type="button"
          >
            <icons.trash />
            <span className="meta">{translation.delete.button.replace('%group%', assessmentGroup.name)}</span>
          </button>
        </footer>
      </article>
    )
  }
})

export default translate('AssessmentGroupCard')(AssessmentGroupCard);