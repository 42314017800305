/**
 * Renders a local filter form
 * @property {array} data
 * @property {object} parent
 * @property {string} stateName
 */

import React from 'react';

const Filter = class Filter extends React.Component {
  constructor() {
    super();

    // Bind this to functions
    this.handleFilter = this.handleFilter.bind(this);
  }

  handleFilter(e) {
    const { data, parent, stateName, useApi } = this.props;
    const { value } = e.target;
    let results = [];
    let filtered = false;

    if(useApi) {
      this.handleApiFilter(value);
      return;
    }

    if(value && value.length > 2) {
      results = data.filter(record => {
        if(record.hasOwnProperty('name')) {
          return record.name.toLowerCase().includes(value.toLowerCase());
        } else {
          return record.title.toLowerCase().includes(value.toLowerCase());
        }
      });

      filtered = true;
    } else {
      results = data;
    }

    parent.setState({
      [stateName]: results,
      filtered
    });
  }

  handleApiFilter(value) {
    const { onClear, onFilter, filterProp } = this.props;
    const filterParams = {...this.props.filterParams};

    // Debounce searches
    if(this.searchDelay) {
      clearTimeout(this.searchDelay);
    }

    if(value) {
      this.searchDelay = setTimeout(() => {
        if(Array.isArray(filterProp)) {
          filterProp.forEach(prop => {
            filterParams[prop] = `*${value}*`;
          })
        } else {
          filterParams[filterProp ? filterProp : 'title'] = `*${value}*`;
        }

        onFilter(filterParams);
      }, 500);
    } else if(!value) {
      onClear()
    }
  }

  render() {
    const { id, placeholder } = this.props;

    return (
      <form
        className="filter"
        onSubmit={e => e.preventDefault()}
      >
        <label
          className="filter__lbl"
          htmlFor={id}
        >
          <div className="wrap">
            <input
              type="search"
              id={id}
              className="filter__input"
              placeholder={placeholder}
              onChange={this.handleFilter}
            />
          </div>
        </label>
      </form>
    )
  }
}

export default Filter;
