/**
 * readingQuestion - Renders a reading-rate question
 */
export function readingQuestion(Survey) {
  const question = {
    // Note: The name property *HAS TO BE LOWER CASE* because this
    // is SurveyJS so why would it be able to handle anything else?
    name: 'readingquestion',
    title: 'Reading Rate',
    iconName: 'icon-book',
    widgetIsLoaded: () => {
      return true;
    },
    isFit: (question) => {
      return question.getType() === 'readingquestion';
    },
    activatedByChanged: (activatedBy) => {
      Survey.JsonObject.metaData.addClass('readingquestion', [], null, 'text');

      Survey.JsonObject.metaData.addProperties('readingquestion', [
        { name: 'textToRead' }
      ]);
    },
    isDefaultRender: false,
    htmlTemplate: `
      <div class="rate-question">
        <strong class="rate-question__title">Text to Read:</strong>
        <div class="rate-question__passage"></div>
      </div>
    `,
    afterRender: (question, el) => {
      const passage = el.querySelector('.rate-question__passage');
      passage.innerHTML = question.textToRead || 'Text to read…';
    },
    willUnmount: (question, el) => {
      return;
    }
  }

  Survey.CustomWidgetCollection.Instance.addCustomWidget(question, 'customtype');
}

/**
 * typingQuestion - Renders a typing speed question
 */
export function typingQuestion(Survey) {
  const question = {
    // Note: The name property *HAS TO BE LOWER CASE* because this
    // is SurveyJS so why would it be able to handle anything else?
    name: 'typingquestion',
    title: 'Typing Speed',
    iconName: 'icon-keyboard',
    widgetIsLoaded: () => {
      return true;
    },
    isFit: (question) => {
      return question.getType() === 'typingquestion';
    },
    activatedByChanged: (activatedBy) => {
      Survey.JsonObject.metaData.addClass('typingquestion', [], null, 'text');

      Survey.JsonObject.metaData.addProperties('typingquestion', [
        { name: 'textToType' }
      ]);
    },
    isDefaultRender: false,
    htmlTemplate: `
      <div class="rate-question">
        <strong class="rate-question__title">Text to Type</strong>
        <div class="rate-question__passage"></div>
        <label class="rate-question__response-label"><span>Typing Response</span><textarea class="rate-question__response"></textarea></label>
      </div>
    `,
    afterRender: (question, el) => {
      const passage = el.querySelector('.rate-question__passage');
      const text = el.querySelector('.rate-question__response');

      if(!passage || !text) {
        return;
      }

      passage.innerHTML = question.textToType || 'Text to type…';
      text.placeholder = question.placeHolder;

      text.onchange = () => {
        question.value = text.value;
      }

      const onValueChangedCallback = () => {
        text.value = question.value ? question.value : '';
      }

      const onReadOnlyChangedCallback = () => {
        if(question.isReadOnly) {
          text.setAttribute('disabled', 'disabled');
        } else {
          text.removeAttribute('disabled');
        }
      };

      question.readOnlyChangedCallback = onReadOnlyChangedCallback;
      question.valueChangedCallback = onValueChangedCallback;
      onValueChangedCallback();
      onReadOnlyChangedCallback();
    },
    willUnmount: (question, el) => {
      return;
    }
  }

  Survey.CustomWidgetCollection.Instance.addCustomWidget(question, 'customtype');
}


export function multiTextInput(Survey) {
  const question = {
    // Note: The name property *HAS TO BE LOWER CASE* because this
    // is SurveyJS so why would it be able to handle anything else?
    name: 'multilinetextinput',
    title: 'Multi Line Text Input',
    iconName: 'icon-keyboard',
    widgetIsLoaded: () => {
      return true;
    },
    isFit: (question) => {
      return question.getType() === 'multilinetextinput';
    },
    activatedByChanged: (activatedBy) => {
      Survey.JsonObject.metaData.addClass('multilinetextinput', [], null, 'text');
    },
    isDefaultRender: false,
    htmlTemplate: `
      <div class="rate-question">
        <label class="rate-question__response-label"/>
        <textarea class="textarea-sm--grow"></textarea>
      </div>
    `,
    afterRender: (question, el) => {
      const text = el.querySelector('.textarea-sm--grow');
      if(!text) {
        return;
      }

      /**
       * This is a function to vertically expand an element automatically
       * @param {*} field - Field/element to expand
       */
      const autoExpand = (field) => {
        // Reset field height
        field.style.height = 'inherit';


        // Get the computed styles for the element
        let computed = window.getComputedStyle(field);

        // Calculate the height
        let height = parseInt(computed.getPropertyValue('border-top-width'), 10)
            + parseInt(computed.getPropertyValue('padding-top'), 10)
            + field.scrollHeight
            + parseInt(computed.getPropertyValue('padding-bottom'), 10)
            + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

        field.style.height = height + 'px';

      };


      text.addEventListener('input',  (event) =>{
        if (event.target.tagName.toLowerCase() !== 'textarea') return;
        autoExpand(event.target);
      }, false);

      text.onchange = () => {
        question.value = text.value;
      }

      const onValueChangedCallback = () => {
        text.value = question.value ? question.value : '';
      }

      question.valueChangedCallback = onValueChangedCallback;
      onValueChangedCallback();
    },
    willUnmount: (question, el) => {
      return;
    }
  }

  Survey.CustomWidgetCollection.Instance.addCustomWidget(question, 'customtype');
}

/**
 * likertQuestion - Renders a typing speed question
 * Patterned after SurveyJS Pretty Checkbox custom widget
 */
export function likertQuestion(Survey) {
  var widget = {
    name: "likertquestion",
    title: "Likert",
    widgetIsLoaded: function() {
      return true;
    },
    // SurveyJS requires an htmlTemplate attribute on the widget object.
    // All that attribute does though is append a child div to the element we're
    // modifying, and frustratingly enough, it renders the template div differently
    // in the survey creator versus our student-facing Assessment.js.
    // This div's only purpose is to prevent an error "htmlTemplate attribute is missing".
    // It's hidden in the Assessment.js rendering only.
    htmlTemplate: '<div class="likert-placeholder"></div>',
    isFit: function(question) {
      return question.getType() === 'likertquestion';
    },
    defaultJSON: {
      choices: [
        {
          text: "Strongly Disagree",
          value: "strongly-disagree",
          credit: 0
        }, {
          text: "Disagree",
          value: "disagree",
          credit: 25
        },
        {
          text: "Neutral",
          value: "neutral",
          credit: 50
        }, {
          text: "Agree",
          value: "agree",
          credit: 75
        }, {
          text: "Strongly Agree",
          value: "strongly-agree",
          credit: 100
        }
      ]
    },
    activatedByChanged: function(value) {
      Survey.JsonObject.metaData.addClass('likertquestion', [], null, 'radiogroup');
    },
    isDefaultRender: false,
    afterRender: function(question, element) {
      var itemInputs = {};

      var inChangeHandler = false;
      var changeHandler = function(event) {
        inChangeHandler = true;
        try {
          question.value = event.target.value;
        } finally {
          inChangeHandler = false;
        }
      };

      var choices = question.visibleChoices;

      choices.forEach(function(choiceItem, index) {

        // Lots of HTML formatting to roughly match 'radiogroup'.
        var input = document.createElement("input");
        input.type = "radio";
        input.name = question.name;
        input.onchange = changeHandler;
        input.value = choiceItem.value;
        input.classList.add('likert-choice__input')

        var label = document.createElement("label");
        label.className = "likert-choice__label";
        label.appendChild(input);
        var innerText = document.createElement("span");
        if (choiceItem.locText.hasHtml) {
          innerText.innerHTML = '<span>' + choiceItem.locText.html + '</span>';
        } else {
          innerText.innerHTML = '<span>' + choiceItem.locText.renderedText + '</span>';
        }

        var circle = document.createElement('span');
        circle.classList.add('likert-choice__circle');
        innerText.className = 'likert-choice__inner-text';
        label.appendChild(circle);
        label.appendChild(innerText);

        var itemRoot = document.createElement("div");
        itemRoot.className = "likert-choice";
        itemRoot.appendChild(label);
        element.appendChild(itemRoot);
        element.classList.add('likert');

        itemInputs[choiceItem.value] = input;
      });

      var updateValueHandler = function(newValue) {
        if (!inChangeHandler) {
          var checkedItems = [newValue && newValue.toString()];

          Object.values(itemInputs).forEach(function(inputItem) {
            if (checkedItems.indexOf(inputItem.value) !== -1) {
              inputItem.setAttribute("checked", undefined);
            } else {
              inputItem.removeAttribute("checked");
            }
          });
        }
      };
      var readOnlyHandler = function() {
        Object.values(itemInputs).forEach(function(inputItem) {
          if (question.isReadOnly) {
            inputItem.setAttribute("disabled", true);
          } else {
            inputItem.removeAttribute("disabled");
          }
        });
      };

      question.valueChangedCallback = updateValueHandler;
      question.readOnlyChangedCallback = readOnlyHandler;
      updateValueHandler(question.value);
      readOnlyHandler();
    },
    willUnmount: function(question, el) {
      question.valueChangedCallback = undefined;
      question.readOnlyChangedCallback = undefined;
    }
  };

  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
}
