import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../translate/Translate';

// Icons
import * as icons from '../ui/Icons';

// Components
import BarChart from '../charts/Bar';
import GaugeChart from '../charts/Gauge';
import PieChart from '../charts/Pie';

const LRISummary = observer(class LRISummary extends React.Component {
  render() {
    const summaryData = [
      { label: 'Life Factors', value: [70] },
      { label: 'Personal Attributes', value: [64] },
      { label: 'Technical Competency', value: [90] },
      { label: 'Technical Knowledge', value: [63] },
      { label: 'Reading Recall', value: [30] }
    ];

    const learningStyleData = [
      { label: 'Social', value: 8 },
      { label: 'Visual', value: 4 },
      { label: 'Aural', value: 4 },
      { label: 'Logical', value: 6 },
      { label: 'Verbal', value: 7 },
      { label: 'Solitary', value: 7 },
      { label: 'Physical', value: 7 }
    ];

    const averageData = [
      { label: 'Overall Technical Competency', value: [90, 76] },
      { label: 'Computer Competency', value: [100, 79] },
      { label: 'Internet Competency', value: [80, 73] },
      { label: 'Technical Knowledge', value: [63, 56] },
      { label: 'Reading Recall', value: [30, 73] },
      { label: 'Typing Speed', value: [66, 28] },
      { label: 'Typing Accuracy', value: [100, 94] }
    ];

    return (
      <div>
        <header className="page__head">
          <h1 className="page__title">Summary</h1>
        </header>

        <BarChart
          {...this.props}
          barWidth={30}
          data={summaryData}
          height={250}
          id="summary-chart"
          levels={4}
          max={100}
          scoreColors
          title="Assessment Summary"
          units="%"
        />

        <section className="page__section">
          <h2>Learning Styles</h2>

          <div className="note">
            <i className="note__icon">
              <icons.infoCircle />
            </i>

            <p>Your primary learning style is <strong>social</strong>.</p>
          </div>

          <PieChart
            {...this.props}
            data={learningStyleData}
            id="learning-styles-chart"
            size={200}
            title="Learning Styles"
            withKey
          />
        </section>

        <section className="page__section">
          <h2>Typing Speed & Accuracy</h2>

          <ul className="grid" data-columns="2">
            <li className="grid__item">
              <GaugeChart
                {...this.props}
                data={learningStyleData}
                id="typing-speed-chart"
                levels={[0, 25, 30, 55]}
                markers={[0, 30, 60, 90, 120]}
                max={120}
                min={0}
                title="Typing Speed"
                units="WPM"
                value={66}
              />

              <div className="note">
                <i className="note__icon">
                  <icons.infoCircle />
                </i>

                <p>Your typing speed was measured at 66 WPM</p>
              </div>
            </li>
            <li className="grid__item">
              <GaugeChart
                {...this.props}
                data={learningStyleData}
                id="typing-speed-chart"
                levels={[0, 80, 90, 95]}
                markers={[0, 25, 50, 75, 100]}
                max={100}
                min={0}
                title="Typing Accuracy"
                units="%"
                value={100}
              />

              <div className="note">
                <i className="note__icon">
                  <icons.infoCircle />
                </i>

                <p>Your typing accuracy was measured at 100%</p>
              </div>
            </li>
          </ul>
        </section>

        <section className="page__section">
          <h2>National Averages</h2>

          <div className="note">
            <i className="note__icon">
              <icons.infoCircle />
            </i>

            <p>The national average represents the average of all students from all schools who have taken this version of the assessment. These national averages are automatically updated monthly.</p>
          </div>

          <BarChart
            {...this.props}
            barWidth={30}
            data={averageData}
            height={250}
            id="average-chart"
            keyLabels={['Your Score', 'National Average']}
            levels={4}
            max={100}
            title="National Averages"
            units="%"
          />
        </section>
      </div>
    )
  }
})

export default translate('LRISummary')(LRISummary);