/**
 * Renders a form to create an assessment group
 */

import React from 'react';

// Helpers
import { checkValidity } from '../../helpers';

// Translation
import translate from '../translate/Translate';
import Head from "../common/Head";
import Tooltip from "../common/Tooltip";

export const EditAssessmentGroup = class EditAssessmentGroup extends React.Component {
  constructor() {
    super();

    // Bind this to functions
    this.handleSubmit = this.handleSubmit.bind(this);

    // Create refs
    this.nameField = React.createRef();
    this.keyField = React.createRef();
  }

  componentDidMount() {
    const { assessmentGroupSid, translation } = this.props;
    const { AppStore } = this.props.store;

    if(!assessmentGroupSid) {
      AppStore.drawerTitle = translation.heading;
    } else {
      AppStore.drawerTitle = translation.heading_edit;
    }
  }

  handleSubmit() {
    const { assessmentGroupSid, store, translation } = this.props;
    const { AppStore, AssessmentStore } = store;

    const payload = {
      name: this.nameField.current.value
    };

    if(this.keyField.current.value) {
      payload.key = this.keyField.current.value;
    }

    const callback = () => {
      AppStore.closeDrawer();

      if(!assessmentGroupSid) {
        AppStore.toast = translation.success;
      } else {
        AppStore.toast = translation.success_edit;
      }

      AssessmentStore.listAssessmentGroups();
    }

    if(!assessmentGroupSid) {
      // Creating a new assessment group
      AssessmentStore.createAssessmentGroup(payload, callback);
    } else {
      // Updating an existing assessment group
      AssessmentStore.updateAssessmentGroup(assessmentGroupSid, payload, callback);
    }
  }

  render() {
    const { assessmentGroupSid, store, translation } = this.props;
    const { AppStore, AssessmentStore } = store;
    const { assessmentGroup } = AssessmentStore;

    const fields = [
      {
        id: 'group-name',
        ref: this.nameField,
        translation: translation.name,
        type: 'text',
        required: true,
        defaultValue: assessmentGroup ? assessmentGroup.name : ''
      }, {
        id: 'group-key',
        ref: this.keyField,
        translation: translation.key,
        type: 'text',
        required: false,
        defaultValue: assessmentGroup ? assessmentGroup.key : ''
      }
    ];

    return (
      <form
        className="form panel panel--md panel--solo"
        noValidate
        onSubmit={(e) => checkValidity(e, this.handleSubmit)}
        onReset={AppStore.closeDrawer}
      >
        <Head>
          {!assessmentGroupSid &&
            <title>{translation.heading} | SmarterMeasure</title>
          }

          {assessmentGroupSid &&
            <title>{translation.heading_edit} | SmarterMeasure</title>
          }
        </Head>

        <fieldset>
          <ul className="form__fields">
            {fields.map(
              (field, i) =>
              <li key={i}>
                <label
                  className={`form__lbl${
                    field.required ? ' required' : ''
                  }`}
                  htmlFor={field.id}
                >
                  {field.translation.label}

                  { field.translation.tooltipText &&
                  <Tooltip
                      content={field.translation.tooltipText}
                      className="cplt-label-tooltip"
                      right
                  />
                  }
                </label>

                <input
                  type={field.type}
                  id={field.id}
                  required={field.required ? true : false}
                  aria-required={field.required ? 'true' : 'false'}
                  data-errormsg={field.translation.error}
                  ref={field.ref}
                  defaultValue={field.defaultValue}
                  placeholder={field.translation.placeholder}
                />
              </li>
            )}
          </ul>
        </fieldset>

        <div className="panel__action">
          <button
            className="btn btn--outline"
            type="reset"
          >
            {translation.cancel}
          </button>

          <button
            className="btn"
            type="submit"
          >
            {translation.submit}
          </button>
        </div>
      </form>
    )
  }
}

export default translate('EditAssessmentGroup')(EditAssessmentGroup);
