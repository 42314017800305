/**
 * Renders a tag alias editor
 */

import React from 'react';

// Helpers
import { checkValidity } from '../../helpers';

// Translation
import translate from '../translate/Translate';

export const EditTagAlias = class EditTagAlias extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      selectedTag: null,
      selectedTagIndex: -1,
      tagError: false
    };

    // Bind this to functions
    this.handleSubmit = this.handleSubmit.bind(this);

    // Create refs
    this.label = React.createRef();
    this.description = React.createRef();
  }

  componentDidMount() {
    const { aliasTag, tags } = this.props;

    if(aliasTag) {
      let tagIndex = tags.indexOf(aliasTag);

      this.setState({
        selectedTag: aliasTag,
        // aliasTag is used as the key, this is used to retain that for updating
        originalTag: aliasTag,
        selectedTagIndex: tagIndex
      });
    }
  }

  handleSubmit() {
    const {
      alias,
      onClose,
      onSubmit,
      store,
      translation
    } = this.props;
    let { originalTag, selectedTag } = this.state;
    const { AppStore } = store;

    if(!selectedTag) {
      this.setState({
        tagError: true
      });

      return;
    } else {
      this.setState({
        tagError: false
      });
    }

    const payload = {
      ...alias,
      label: this.label.current.value || ''
    }

    if(this.description.current.value) {
      payload.description = this.description.current.value;
    }

    if(onSubmit) {
      const callback = () => {
        AppStore.toast = translation.success;
        onClose();
      }

      // Check if scoring-tag has changed
      originalTag = selectedTag === originalTag ? null : originalTag;

      onSubmit(payload, selectedTag, callback, originalTag);
    }
  }

  render() {
    const { onClose, translation } = this.props;
    let { tags } = this.props;
    const { selectedTagIndex } = this.state;

    const fields = [
      {
        id: 'label',
        ref: this.label,
        translation: translation.label,
        type: 'text',
        required: true,
        defaultValue: this.props.alias ? this.props.alias.label : ''
      }, {
        id: 'description',
        ref: this.description,
        translation: translation.description,
        type: 'textarea',
        required: false,
        defaultValue: this.props.alias ? this.props.alias.description : ''
      }
    ];

    return (
      <form
        className="form panel panel--md panel--solo"
        noValidate
        onSubmit={(e) => checkValidity(e, this.handleSubmit)}
        onReset={onClose}
      >
        <fieldset>
          <ul className="form__fields">
            {fields.map(
              (field, i) =>
              <li key={i}>
                <label
                  className={`form__lbl${
                    field.required ? ' required' : ''
                  }`}
                  htmlFor={field.id}
                >
                  {field.translation.label}
                </label>

                {field.type !== 'textarea' &&
                  <input
                    type={field.type}
                    id={field.id}
                    required={field.required ? true : false}
                    aria-required={field.required ? 'true' : 'false'}
                    data-errormsg={field.translation.error}
                    ref={field.ref}
                    defaultValue={field.defaultValue}
                  />
                }

                {field.type === 'textarea' &&
                  <textarea
                    id={field.id}
                    className="brief"
                    required={field.required ? true : false}
                    aria-required={field.required ? 'true' : 'false'}
                    data-errormsg={field.translation.error}
                    ref={field.ref}
                    defaultValue={field.defaultValue}
                  />
                }
              </li>
            )}
          </ul>

          <div className="editor">
            <section className="editor__variables">
              <h2 className="editor__variables-heading">
                {translation.tags}
              </h2>

              {(!tags || !tags.length) &&
                <span className="no-results">
                  {translation.no_tags}
                </span>
              }

              {tags && tags.length > 0 &&
                <ul className="editor__variables-list">
                  {tags.map(
                    (tag, i) =>
                    <li key={i}>
                      <button
                        className={`editor__variable${
                          selectedTagIndex === i ? ' selected' : ''
                        }`}
                        type="button"
                        onClick={() => {
                          this.setState({
                            selectedTag: tag,
                            selectedTagIndex: i,
                            tagError: false
                          });
                        }}
                      >
                        {tag}
                      </button>
                    </li>
                  )}
                </ul>
              }

              {this.state.tagError &&
                <span className="error" role="alert">Please select a tag</span>
              }
            </section>
          </div>
        </fieldset>

        <div className="panel__action">
          <button
            className="btn btn--outline"
            type="reset"
          >
            {translation.cancel}
          </button>

          <button
            className="btn"
            type="submit"
          >
            {translation.submit}
          </button>
        </div>
      </form>
    )
  }
}

export default translate('EditTagAlias')(EditTagAlias);
