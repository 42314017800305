/**
 * Renders a pop-up dialog box
 */

import React from 'react';
import { observer } from 'mobx-react';

// Components
import Countdown from './Countdown';

const Dialog = observer(class Dialog extends React.Component {
  closeDialog(e) {
    e.preventDefault();
    this.props.store.AppStore.closeDialog()
  }

  render() {
    const {
      dialogCallback,
      dialogCancelCallback,
      dialogContent,
      dialogCountdown,
      dialogOnExpire,
      dialogType
    } = this.props.store.AppStore;

    return (
      <aside className="dialog" role="alert">
        <div className="dialog__content">
          <strong className="dialog__title">
            {dialogContent.heading}

            {dialogCountdown &&
              <Countdown
                {...this.props}
                onTimeout={dialogOnExpire}
                timeout={dialogCountdown}
              />
            }
          </strong>

          <p>{dialogContent.body}</p>

          {!dialogType &&
            <div className="dialog__action">
              <a
                href="#app"
                className="btn"
                onClick={e => this.closeDialog(e)}
              >
                {dialogContent.button}
              </a>
            </div>
          }

          {dialogType === 'confirm' &&
            <div className="dialog__action">
              <a
                href="#app"
                className="btn btn--outline"
                onClick={e => {
                  if(dialogCancelCallback) {
                    dialogCancelCallback();
                  }

                  this.closeDialog(e);
                }}
              >
                {dialogContent.cancel}
              </a>

              <button
                className="btn"
                onClick={e => {
                  dialogCallback();
                  this.closeDialog(e);
                }}
                type="button"
              >
                {dialogContent.button}
              </button>
            </div>
          }
        </div>
      </aside>
    )
  }
})

export default Dialog;
