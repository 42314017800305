/**
 * Renders a cut point editor
 */

import React from 'react';

// Helpers
import { checkValidity } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Dependencies
import { BasicConfig, Builder, Query, Utils as QbUtils } from 'react-awesome-query-builder';

// Components
import * as icons from '../ui/Icons';

export const EditCutPoint = class EditCutPoint extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      conditions: [
        { tree: null }
      ],
      config: null,
    };

    // Bind this to functions
    this.addCondition = this.addCondition.bind(this);
    this.removeCondition = this.removeCondition.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // Create refs
    this.variable = React.createRef();
    this.description = React.createRef();
  }

  componentDidMount() {
    const { algorithms, cutPoint, tags } = this.props;

    const config = {
      ...BasicConfig,
      fields: {}
    }

    const applyTags = (tag) => {
      config.fields[tag] = {
        label: tag,
        type: 'number',
        valueSources: ['value'],
        preferWidgets: ['number']
      }
    }

    algorithms.forEach(algorithm => {
      applyTags(algorithm.variable.toLowerCase());
    })

    tags.map(applyTags);

    let conditions = [];

    if(cutPoint && cutPoint.conditions && cutPoint.conditions.length > 0) {
      cutPoint.conditions.forEach(condition => {
        const { value, label } = condition;
        let query = false;
        let tree = null;

        if(condition.jsonLogic) {
          query = true;
          tree = QbUtils.checkTree(QbUtils.loadFromJsonLogic(condition.jsonLogic, config), config);
        }

        conditions.push({
          query,
          tree,
          label,
          value
        });
      });
    } else {
      conditions.push({
        query: false,
        tree: null,
        value: null
      });
    }

    this.setState({
      config,
      conditions
    });
  }

  addCondition() {
    const conditions = [...this.state.conditions];

    conditions.push({
      query: false,
      tree: null,
      value: null,
      label: null
    });

    this.setState({
      conditions
    });
  }

  addConditionQuery(i) {
    const conditions = [...this.state.conditions];

    conditions[i].query = true;

    this.setState({
      conditions
    });
  }

  removeCondition(i) {
    const conditions = [...this.state.conditions];

    conditions.splice(i, 1);

    this.setState({
      conditions
    });
  }

  handleQuery(immutableTree, config, i) {
    const conditions = [...this.state.conditions];

    conditions[i].tree = immutableTree;
    conditions[i].jsonLogic = QbUtils.jsonLogicFormat(immutableTree, config).logic;

    this.setState({
      conditions
    });
  }

  handleValue(e, i) {
    const conditions = [...this.state.conditions];

    conditions[i].value = e.target.value;

    this.setState({
      conditions
    });
  }

  handleLabel(e, i) {
    const conditions = [...this.state.conditions];

    conditions[i].label = e.target.value;

    this.setState({
      conditions
    });
  }

  handleSubmit() {
    const {
      cutPointIndex,
      onClose,
      onSubmit,
      store,
      translation
    } = this.props;
    const { AppStore } = store;
    const { conditions } = this.state;

    const payload = {
      variable: this.variable.current.value,
      conditions: []
    };

    if(this.description.current.value) {
      payload.description = this.description.current.value;
    }

    // Add conditions to the payload
    // Note: this is needed because
    // the query builder adds more
    // than just the jsonLogic and
    // we don’t want all of it
    conditions.forEach(condition => {
      const conditionObj = {};

      if(condition.jsonLogic) {
        conditionObj.jsonLogic = condition.jsonLogic;
      }

      if(condition.value) {
        conditionObj.value = condition.value;
      }

      if (condition.label) {
        conditionObj.label = condition.label
      }

      payload.conditions.push(conditionObj);
    });

    if(onSubmit) {
      const callback = () => {
        AppStore.toast = translation.success;
        onClose();
      }

      onSubmit(payload, cutPointIndex, callback);
    }
  }

  renderBuilder(props) {
    return (
      <div className="query-builder qb-lite">
        <Builder
          {...props}
        />
      </div>
    )
  }

  render() {
    const { onClose, translation } = this.props;
    const { conditions, config } = this.state;

    const fields = [
      {
        id: 'variable',
        ref: this.variable,
        translation: translation.variable,
        type: 'text',
        required: true,
        defaultValue: this.props.cutPoint ? this.props.cutPoint.variable : ''
      }, {
        id: 'description',
        ref: this.description,
        translation: translation.description,
        type: 'textarea',
        required: false,
        defaultValue: this.props.cutPoint ? this.props.cutPoint.description : ''
      }
    ];

    if(config) {
      return (
        <form
          className="form panel panel--solo panel--form"
          noValidate
          onSubmit={(e) => checkValidity(e, this.handleSubmit)}
          onReset={onClose}
        >


          <fieldset>
            <ul className="form__fields">
            {fields.map(
              (field, i) =>
                <li key={i}>
                  <label
                    className={`form__lbl${
                      field.required ? ' required' : ''
                    }`}
                    htmlFor={field.id}
                  >
                    {field.translation.label}
                  </label>

                  {field.type !== 'textarea' &&
                    <input
                      type={field.type}
                      id={field.id}
                      required={field.required ? true : false}
                      aria-required={field.required ? 'true' : 'false'}
                      data-errormsg={field.translation.error}
                      ref={field.ref}
                      defaultValue={field.defaultValue}
                    />
                  }

                  {field.type === 'textarea' &&
                    <textarea
                      id={field.id}
                      className="brief"
                      required={field.required ? true : false}
                      aria-required={field.required ? 'true' : 'false'}
                      data-errormsg={field.translation.error}
                      ref={field.ref}
                      defaultValue={field.defaultValue}
                    />
                  }
                </li>
              )}
            </ul>
          </fieldset>

          <fieldset>
            <legend className="form__legend">
              {translation.conditions.label}
            </legend>

            {conditions.map(
              (rule, i) =>
              <div
                className="query-rule"
                key={i}
              >
                <label
                  className="form__lbl"
                >
                  {i === 0 && translation.conditions.if}

                  {
                    i !== 0 ?
                      i === conditions.length - 1 && !rule.query ?
                        translation.conditions.else :
                        translation.conditions.else_if
                    : ''
                  }
                </label>

                {i !== 0 &&
                  <button
                    className="btn--link btn--negative"
                    onClick={() => this.removeCondition(i)}
                    type="button"
                  >
                    {translation.conditions.remove_condition}
                  </button>
                }

                {(rule.query || i !== conditions.length - 1) &&
                  <Query
                    {...config}
                    onChange={(immutableTree, config) => this.handleQuery(immutableTree, config, i)}
                    renderBuilder={this.renderBuilder}
                    value={rule.tree}
                  />
                }

                {!rule.query && i === conditions.length - 1 &&
                  <button
                    className="btn btn--full btn--placeholder"
                    onClick={() => this.addConditionQuery(i)}
                    type="button"
                  >
                    {translation.conditions.add_rules}
                  </button>
                }

                <ul className="form__fields">
                  <li>
                    <label
                      className="form__lbl required meta"
                      htmlFor={`conditional-value-${i}`}
                    >
                      {translation.conditions.value}
                    </label>

                    <input
                      id={`conditional-value-${i}`}
                      type="text"
                      placeholder={translation.conditions.value}
                      defaultValue={rule.value}
                      required={true}
                      aria-required='true'
                      data-errormsg={translation.conditions.value_required_error}
                      onChange={e => this.handleValue(e, i)}
                    />
                  </li>
                  <li>
                    <label
                      className="form__lbl required meta"
                      htmlFor={`conditional-label-${i}`}
                    >
                      {translation.conditions.key_label}
                    </label>

                    <input
                      id={`conditional-label-${i}`}
                      type="text"
                      placeholder={translation.conditions.key_label}
                      defaultValue={rule.label}
                      required={true}
                      aria-required='true'
                      data-errormsg={translation.conditions.label_required_error}
                      onChange={e => this.handleLabel(e, i)}
                    />
                  </li>
                </ul>
              </div>
            )}

            <button
              className="btn btn--ghost btn--positive"
              onClick={this.addCondition}
              type="button"
            >
              <icons.plus />
              {translation.conditions.new_condition}
            </button>
          </fieldset>

          <div className="panel__action">
            <button
              className="btn btn--outline"
              type="reset"
            >
              {translation.cancel}
            </button>

            <button
              className="btn"
              type="submit"
            >
              {translation.submit}
            </button>
          </div>
        </form>
      )
    }

    return null;
  }
}

export default translate('EditCutPoint')(EditCutPoint);