/**
 * Renders an assessment card
 */

import React from 'react';
import {Link} from 'react-router-dom';
import { toJS } from 'mobx';

// Helpers
import { formatQuery, parseSeconds } from '../../helpers';

// Translation
import Date from './Date';
import translate from '../translate/Translate';

// Dependencies
import { differenceInSeconds, parseISO } from 'date-fns';

// Icons
import * as icons from '../ui/Icons';

// Components
import Score from './Score';
import StudentReport from '../reports/StudentReport';
import InstructorReport from '../reports/InstructorReport'
import SurveyModal from "../reports/SurveyModal";
import {observer} from "mobx-react";

const ResultCard = observer(class ResultCard extends React.Component {

  constructor() {
    super();

    // Set the initial state
    this.state = {
      expanded: false,
      questionData: {},
      report: null,
      showPrevious: false,
      isCompleted: false,
      showSurvey: false
    };

    // Set up refs
    this.detailView = React.createRef();

    this.runCount = 0;
  }



  componentDidMount() {
    const { store } = this.props;
    const { AssessmentStore, UserStore } = store;
    const { userData, targetUserData } = UserStore;

    function removeClasses() {
      const elements = document.querySelectorAll('.report'); // select all elements with the class name
      elements.forEach(element => element.remove())
    }

    window.addEventListener('afterprint', removeClasses); // call removeClasses() after printing


    if(AssessmentStore.assessmentGroups.length === 0) {
      let query = null;

      if(userData.role.toLowerCase() !== 'learner' && targetUserData?.userSid) {
        query = {
          userSid: targetUserData.userSid
        };
      }

      AssessmentStore.listAssessmentGroups(query);
    }
  }

  componentDidUpdate() {
    if (this.state.showSurvey) {
      // This is to disable scrolling while the modal is active.
      document.body.style.overflow = 'hidden';
    } else if (!this.state.showSurvey) {
      // Allows scrolling again
      document.body.style.overflow = '';
    }
  }


  surveyState = () => {
    this.setState({
      showSurvey: !this.state.showSurvey
    })
  }

  handleAssessment(e, query, readOnly) {
    e.preventDefault();

    const { attempt, store } = this.props;
    const { AssessmentStore, UserStore } = store;
    const { userData } = UserStore;

    const payload = [{
      groupItemSid: attempt.groupItemSid,
      userSid: userData.userSid,
      enrollmentSid: userData.enrollmentSid
    }];

    const callback = () => {
      window.location = (
        `/assessment/${attempt.assessmentSid}${formatQuery(query)}`
      );
    };

    if(!readOnly) {
      AssessmentStore.createAttempt(payload, callback);
    } else {
      window.location = (
        '/assessment-review/[assessmentSid]',
        `/assessment-review/${attempt.assessmentSid}?attempt=${attempt.attemptSid}&assessmentVersionSid=${attempt.assessmentVersionSid}`
      );
    }
  }

  /**
   * Expose the results report for print
   * and trigger the browser print dialog.
   *
   * @async
   * @function handlePrint
   * @param {event} e
   * @param {object} query
   * @param {string} attemptSid
   */
  handlePrint = (e, query, attemptSid) => {
    e.preventDefault();

    const { attempt, store } = this.props;
    const { AssessmentStore, UserStore } = store;
    const { userData } = UserStore;
    let tags = attempt.outcomeJson.tags

    const report = {
      attempt,
      tags
    };

    // Hide any previously rendered reports
    // and re-show if this report is being
    // printed again
    this.setState({
      report: null
    }, async () => {

      // Get required data
      if (userData.role.toLowerCase() !== 'learner') {
        report.type = 'instructor';
        report.data = await AssessmentStore.getAssessment(attempt.assessmentSid, query);;
      } else {
        report.type = 'student';
        report.data = {
          assessmentVersionSid: query.assessmentVersionSid,
          attemptSid
        };
      }
      // Render the report
      this.setState({
        report
      }, () => {
        if(window.MathJax) {
          try {
            window.MathJax.typeset();
          } catch (error) {
            // Ignore MathJax retry error which is non-breaking
          }
        }

        // Wait until all images have loaded, then print
        this.printReport();
      });
    });
  }

  /**
   * Check if all images are loaded and print or check again
   *
   * @function printReport
   * @recursive
   */
  printReport = () => {
    const { AppStore } = this.props.store;
    const images = document.querySelectorAll('img');
    const unloaded = [];

    if (AppStore.loadingCalls.indexOf('loadingImages') === -1) {
      AppStore.startLoading('loadingImages');
    }

    images.forEach(img => {
      if (!img.complete) {
        unloaded.push(img);
      }
    });

    if (unloaded.length > 0) {
      if (this.runCount < 120) {
        this.runCount++;

        setTimeout(() => {
          this.printReport();
        }, 500);
      } else {
        AppStore.showErrorMsg({ message: 'Failed to load all images'  });
      }
    } else {
      AppStore.finishLoading('loadingImages');
      window.print();
    }
  }

  render() {
    const {
      attempt,
      child,
      hidePrevious,
      hideRetake,
      parent,
      store,
      translation
    } = this.props;
    const { report } = this.state;
    const { AssessmentStore, UserStore, SurveyStore, ActivityStore } = store;
    const { installSid, userData } = UserStore;
    const { assessmentGroups } = AssessmentStore;
    let tags = null;

    const duration = differenceInSeconds(parseISO(attempt.dateCompleted), parseISO(attempt.dateCreated));
    if(attempt.outcomeJson && attempt.outcomeJson.tags) {
      tags = attempt.outcomeJson.tags;
    }

    return (
      <article
        className={`panel panel--card result-card${parent ? ' result-card--parent' : ''}${child ? ' result-card--child' : ''}`}
      >
        <header
          className="result-card__head"
          aria-labelledby="result-card-title"
        >
          <h2
            id="result-card-title"
            className="result-card__title panel__title panel__title--alt"
          >
            {attempt.assessmentName ? attempt.assessmentName : 'Assessment Title'}
          </h2>

          <span className="result-card__subtitle">
            <icons.folder />
            {translation.group}: {attempt.assessmentGroupName}
          </span>

          {attempt.dateCompleted && (
            <ul className="result-card__time">
              <li>
                {translation.completed_date}: <time
                  dateTime={attempt.dateCompleted}
                >
                  <Date
                    {...this.props}
                    date={attempt.dateCompleted}
                    dateFormat="M/d/yy h:mm a"
                  />
                </time>
              </li>
              <li>
                {translation.duration}: <time
                  dateTime={parseSeconds(duration, true)}
                >{parseSeconds(duration)}</time>
              </li>
            </ul>
          )}

          <div className="result-card__tools">
            {/* Retake assessment button */}
            {userData.role.toLowerCase() === 'learner' && !hideRetake && !child && (
              <Link
                to={`/assessment/${attempt.assessmentSid}?install=${installSid}&token=${UserStore.token}`}
              >
                <a href="#/"
                  className="btn btn--outline result-card__retake"
                  onClick={(e) => {
                    e.preventDefault();

                    const payload = [
                      {
                        groupItemSid: attempt.groupItemSid,
                        userSid: userData.userSid,
                        enrollmentSid: userData.enrollmentSid,
                      },
                    ];

                    const callback = () => {
                      window.location = (
                        `/assessment/${attempt.assessmentSid}?install=${installSid}&attempt=${AssessmentStore.attemptSid}&token=${UserStore.token}`
                      );
                    };

                    AssessmentStore.createAttempt(payload, callback);
                  }}
                >
                  <icons.redo />
                  {translation.new_attempt}
                </a>
              </Link>
            )}

            {/* Instructor Download PDF button */}
            {(userData.role.toLowerCase() === 'instructor' || userData.role.toLowerCase() === 'learner') &&
              <button
                className="btn btn--outline result-card__pdf"
                type="button"
                onClick={e => {
                  this.handlePrint(
                    e,
                    { assessmentVersionSid: attempt.assessmentVersionSid },
                    attempt.attemptSid
                  )
                }}
              >
                <icons.paper />
                {translation.pdf}
              </button>
            }
          </div>
        </header>

        {/* Collapsed score view */}
        {tags && (
          <table
            className={`result-card__scores${this.state.expanded ? ' hidden' : ''}`}
          >
            <thead>
              <tr>
                <th className="meta" scope="col">{translation.competency}</th>
                <th className="meta" scope="col">{translation.score}</th>
              </tr>
            </thead>

            <tbody>
              {Object.keys(tags).map((tag, i) => (
                <Score
                  {...this.props}
                  key={i}
                  displayStyle="compact"
                  earned={tags[tag].earned}
                  percent={tags[tag].percent}
                  possible={tags[tag].possible}
                  title={tag}
                />
              ))}
            </tbody>
          </table>
        )}

        {/*
          Hard code: WGU CPT cut point label
          Note: this cut point name (cptcutpoint) needs to be documented
        */}
        {/*
          attempt?.outcomeJson?.['cut-point']?.cptcutpoint &&
          userData.role.toLowerCase() !== 'learner' &&
          <strong className="result-card__label">
            <span className="result-card__sublabel">
              {translation.preliminary}
            </span>
            {attempt.outcomeJson['cut-point'].cptcutpoint.cutpoint}
          </strong>
        */}

        {/* Expanded score view */}
        {tags && (
          <div
            id={`details-${attempt.attemptSid}`}
            className={`
              result-card__details${!this.state.expanded ? " hidden" : ""}
            `}
            ref={this.detailView}
            tabIndex="0"
            aria-expanded={this.state.expanded ? true : false}
          >
            {Object.keys(tags).length > 0 && (
              <table className="score-list">
                <thead>
                  <tr>
                    <th className="meta" scope="col">{translation.competency}</th>
                    <th className="meta" scope="col">{translation.score}</th>
                  </tr>
                </thead>

                <tbody>
                  {Object.keys(tags).map((tag, i) => (
                    <React.Fragment key={i}>
                      <Score
                        {...this.props}
                        earned={tags[tag].earned}
                        percent={tags[tag].percent}
                        possible={tags[tag].possible}
                        title={tag}
                      />

                      {/* If there are subkeys, render them */}
                      { //eslint-disable-next-line
                        Object.keys(tags[tag]).map((subTag, j) => {
                        if (
                          subTag !== "earned" &&
                          subTag !== "possible" &&
                          subTag !== "percentage"
                        ) {
                          return (
                            <Score
                              {...this.props}
                              key={j}
                              displayStyle="compact"
                              earned={tags[tag][subTag].earned}
                              percent={tags[tag][subTag].percent}
                              possible={tags[tag][subTag].possible}
                              superTitle={tag}
                              title={subTag}
                            />
                          );
                        }
                      })}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}

        {report && report.type !== 'student' &&
          <InstructorReport
            {...this.props}
            assessmentVersionSid={report.data.assessmentVersionSid}
            attempt={toJS(report.attempt)}
            questionData={toJS(report.data)}
            tags={report.tags}
          />
        }

        {report && report.type === 'student' &&
          <StudentReport
            {...this.props}
            assessmentVersionSid={report.data.assessmentVersionSid}
            attempt={toJS(report.attempt)}
            tags={report.tags}
          />
        }

        <footer className="result-card__foot">
          {/* Show/hide details */}
          <button
            className="btn btn--ghost"
            type="button"
            onClick={() => {
              if (!this.state.expanded && userData.role.toLowerCase() !== "learner") {
                ActivityStore.sendActivity('Page View',{pageUrl: window.location.href});
                const result = assessmentGroups.filter(group => group.name === attempt.assessmentGroupName)[0]
                SurveyStore.checkForSurvey(result.facultySurveySid, this.surveyState, result.assessmentGroupSid);

              }
              this.setState(
                {
                  expanded: !this.state.expanded,
                },
                () => {
                  if (this.detailView && this.detailView.current) {
                    this.detailView.current.focus();
                  }
                }
              );
            }}
            aria-controls={`details-${attempt.attemptSid}`}
          >
            {!this.state.expanded && <icons.plus />}
            {!this.state.expanded && translation.view_details}

            {this.state.expanded && <icons.minus />}
            {this.state.expanded && translation.hide_details}
          </button>

          {/* Show/hide previous attempts */}
          {!hidePrevious &&
            attempt.completedAttemptCount &&
            parseInt(attempt.completedAttemptCount, 10) > 1 &&
            userData.role.toLowerCase() !== "learner" && (
              <button
                className="btn btn--ghost"
                type="button"
                onClick={() => {
                  const callback = () => {
                    this.setState({
                      showPrevious: true,
                    });
                  };

                  if (!this.state.showPrevious) {
                    AssessmentStore.listAttempts(
                      {
                        groupItemSid: attempt.groupItemSid,
                        completionState: "other-complete",
                        userSid: UserStore.targetUserData.userSid || attempt.userSid,
                      },
                      `previousAttempts-${attempt.attemptSid}`,
                      callback
                    );
                  } else {
                    AssessmentStore[
                      `previousAttempts-${attempt.attemptSid}`
                    ] = [];

                    this.setState({
                      showPrevious: false,
                    });
                  }
                }}
              >
                {!this.state.showPrevious && <icons.history />}
                {!this.state.showPrevious && translation.view_previous}

                {this.state.showPrevious && <icons.history />}
                {this.state.showPrevious && translation.hide_previous}
              </button>
            )}

          {userData.role.toLowerCase() !== 'learner' && (
            <button
              className="btn btn--ghost"
              type="button"
              onClick={(e) => {
                this.handleAssessment(
                  e,
                  {
                    install: installSid,
                    assessmentVersionSid: attempt.assessmentVersionSid,
                    attempt: attempt.attemptSid,
                    token: UserStore.token,
                    displayMode: 'single-page',
                    ro: true,
                  },
                  true,
                  false
                );
              }}
            >
              <icons.paper />
              {translation.view_responses}
            </button>
          )}
          {this.state.showSurvey && SurveyStore.surveyJson &&
          <SurveyModal
              {...this.props}
              surveyJson={SurveyStore.surveyJson}
              surveyState={this.surveyState}
              focusFirstQuestionAutomatic={false}
              showCompletedPage={false}
          />
          }
        </footer>
      </article>
    );
  }
})

export default translate('ResultCard')(ResultCard);