/**
 * Renders a countdown
 * @prop {integer} timeout - The countdown length in seconds
 */

import React from 'react';
import { observer } from 'mobx-react';

// Helpers
import { parseSeconds } from '../../helpers';

const Countdown = observer(class Countdown extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      counterClass: ''
    };

    // Bind this to functions
    this.countdown = this.countdown.bind(this);
  }

  async componentDidMount() {
    const { timeout } = this.props;

    this.setState({
      timeout
    });

    this.timer = setInterval(this.countdown, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  countdown() {
    const { onTimeout } = this.props;
    let { timeout } = this.state;

    // Subtract 1 second
    // Note: The timeout variable is a representation
    // of seconds rather than milliseconds primarily
    // because the API tends to return things in seconds
    timeout -= 1;

    this.setState({
      timeout
    });

    if(timeout <= 0) {
      clearInterval(this.timer);

      if(onTimeout) {
        onTimeout();
      }
    }
  }

  render() {
    const { timeout } = this.state;

    return (
      <span className="counter">
        <strong className={`counter__time ${this.state.counterClass}`}>
          {timeout && parseSeconds(timeout)}
        </strong>
      </span>
    )
  }
})

export default Countdown;
