import React from 'react';

// Helpers
import { titleCase } from '../../helpers';

const Score = class Score extends React.Component {
  getWidth = (percent) => {
    /**
     * Add a 2% sliver of blue for scores 0-2.
     */
    return percent > 1 ?
      percent
      : 2;
  }

  render() {
    const {
      displayStyle,
      earned,
      possible,
      superTitle,
      title
    } = this.props;

    let { percent } = this.props;

    if(!percent) {
      percent = (earned / possible) * 100;
    }

    return (
      <tr
        className={`score${
          displayStyle === 'compact' ? ' score--compact' : ''
        }${
          displayStyle === 'minimal' ? ' score--minimal' : ''
        }`}
      >
        <th
          className="score__title"
          scope="row"
        >
          {superTitle &&
            <span className="meta">
              {titleCase(superTitle.replace(/-/g, ' '))}&nbsp;
            </span>
          }

          {titleCase(title.replace(/-/g, ' '))}
        </th>

        <td className="score__value">
          <span
            className="score__value-bar"
            style={{ width: `${this.getWidth(percent)}%` }}
          >
            {percent}%
          </span>
        </td>
      </tr>
    )
  }
}

export default Score;