import { action, observable } from 'mobx';
import { api } from '../config';

// Helpers
import { formatQuery } from '../helpers';

// AppStore (used for loading, error throwing, etc.)
import AppStore from './AppStore';

// UserStore (used to access user data)
import UserStore from './UserStore';

let obx = observable({
  /**
   * listCourses - Gets all courses for an install
   * @param {object} query
   */
  courses: [],
  coursesNext: null,

  listCourses: action((query, append, callback) => {
    AppStore.startLoading('listCourses');

    const url = `/installs/${UserStore.installSid}/courses${formatQuery(query)}`;

    api.get(url)
      .then(response => {
        if(!append) {
          obx.courses = response.data.results;
        } else {
          obx.courses = obx.courses.concat(response.data.results);
        }

        if(response.data.next) {
          obx.coursesNext = response.data.next;
        } else {
          obx.coursesNext = null;
        }

        if(callback) {
          callback();
        }

        AppStore.finishLoading('listCourses');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  setAllSurveys: action((studentSurveySid, facultySurveySid, callback) => {
    const payload = {studentSurveySid, facultySurveySid};

    const url = `/installs/${UserStore.installSid}/assessmentGroups/attachAll`;

    api.put(url, payload)
      .then(() => {
        if(callback) {
          callback()
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  })
});

export default obx;