/**
 * Renders a custom algorithm editor
 */

import React from 'react';

// Helpers
import { checkValidity } from '../../helpers';

// Translation
import translate from '../translate/Translate';

export const EditCustomAlgorithms = class EditCustomAlgorithms extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      algorithm: null,
      caretPosition: {
        start: 0,
        end: 0
      }
    };

    // Bind this to functions
    this.handleSubmit = this.handleSubmit.bind(this);

    // Create refs
    this.variable = React.createRef();
    this.description = React.createRef();
    this.canvas = React.createRef();
  }

  componentDidMount() {
    const { algorithm } = this.props;

    if(algorithm) {
      this.handleInput(algorithm.algorithm);
    }
  }

  handleInput(value) {
    this.saveCaretPosition();

    // Convert variables
    // if(value.indexOf('{') > -1 && value.indexOf('}') > -1) {
    //   const varsMatch = new RegExp('{(.*?)}', 'gm');
    //   const variables = value.match(varsMatch);

    //   if(variables && variables.length) {
    //     variables.forEach(variable => {
    //       const variableEl = document.createElement('span');
    //       variableEl.className = 'editor__variable';
    //       variableEl.textContent = variable.replace('{', '').replace('}', '');
    //       variableEl.contentEditable = false;

    //       const thisMatch = new RegExp(variable, 'g');
    //       value = value.replace(thisMatch, variableEl.outerHTML);
    //     });
    //   }
    // }

    this.setState({
      algorithm: value
    }, () => {
      if(document.activeElement === this.canvas.current) {
        this.restoreCaretPosition();
      }
    });
  }

  saveCaretPosition() {
    if(window.getSelection && window.getSelection().rangeCount <= 0) {
      return;
    }

    const range = window.getSelection().getRangeAt(0);
    const preSelectionRange = range.cloneRange();

    preSelectionRange.selectNodeContents(this.canvas.current);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);

    const start = preSelectionRange.toString().length;

    this.setState({
      caretPosition: {
        start,
        end: start + range.toString().length
      }
    });
  }

  restoreCaretPosition() {
    const { caretPosition } = this.state;
    let charIndex = 0;
    const range = document.createRange();

    range.setStart(this.canvas.current, 0);
    range.collapse(true);

    const nodeStack = [this.canvas.current];
    let node;
    let foundStart = false;
    let stop = false;

    while(!stop && (node = nodeStack.pop())) {
      if(node.nodeType === 3) {
        const nextCharIndex = charIndex + node.length;

        if(
          !foundStart &&
          caretPosition.start >= charIndex &&
          caretPosition.start <= nextCharIndex
        ) {
          range.setStart(node, caretPosition.start - charIndex);
          foundStart = true;
        }

        if(
          foundStart &&
          caretPosition.end >= charIndex &&
          caretPosition.end <= nextCharIndex
        ) {
          range.setEnd(node, caretPosition.end - charIndex);
          stop = true;
        }

        charIndex = nextCharIndex;
      } else {
        let i = node.childNodes.length;

        while(i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  focusCanvas() {
    this.canvas.current.focus();
    document.execCommand('selectAll', false, null);
    document.getSelection().collapseToEnd();
  }

  handleSubmit() {
    const {
      algorithmIndex,
      onClose,
      onSubmit,
      store,
      translation
    } = this.props;
    let { algorithm } = this.state;
    const { AppStore } = store;

    // Replace HTML tags (used for visualization)
    // with curly braces
    const openTagMatch = /(<span[^>]+>|<span>)/g;
    const closeTagMatch = /(<\/span>)/g;

    algorithm = algorithm.replace(openTagMatch, '{').replace(closeTagMatch, '}');

    // Replace any occurrences of &nbsp;
    const nbspMatch = /&nbsp;/g;

    algorithm = algorithm.replace(nbspMatch, ' ');

    const payload = {
      algorithm,
      variable: this.variable.current.value
    };

    if(this.description.current.value) {
      payload.description = this.description.current.value;
    }

    if(onSubmit) {
      const callback = () => {
        AppStore.toast = translation.success;
        onClose();
      }

      onSubmit(payload, algorithmIndex, callback);
    }
  }

  render() {
    const { onClose, translation } = this.props;
    let { tags } = this.props;
    const { algorithm } = this.state;

    const fields = [
      {
        id: 'variable',
        ref: this.variable,
        translation: translation.variable,
        type: 'text',
        required: true,
        defaultValue: this.props.algorithm ? this.props.algorithm.variable : ''
      }, {
        id: 'description',
        ref: this.description,
        translation: translation.description,
        type: 'textarea',
        required: false,
        defaultValue: this.props.algorithm ? this.props.algorithm.description : ''
      }
    ];

    return (
      <form
        className="form panel"
        noValidate
        onSubmit={(e) => checkValidity(e, this.handleSubmit)}
        onReset={onClose}
      >
        <fieldset>
          <ul className="form__fields">
            {fields.map(
              (field, i) =>
              <li key={i}>
                <label
                  className={`form__lbl${
                    field.required ? ' required' : ''
                  }`}
                  htmlFor={field.id}
                >
                  {field.translation.label}
                </label>

                {field.type !== 'textarea' &&
                  <input
                    type={field.type}
                    id={field.id}
                    required={field.required ? true : false}
                    aria-required={field.required ? 'true' : 'false'}
                    data-errormsg={field.translation.error}
                    ref={field.ref}
                    defaultValue={field.defaultValue}
                  />
                }

                {field.type === 'textarea' &&
                  <textarea
                    id={field.id}
                    className="brief"
                    required={field.required ? true : false}
                    aria-required={field.required ? 'true' : 'false'}
                    data-errormsg={field.translation.error}
                    ref={field.ref}
                    defaultValue={field.defaultValue}
                  />
                }
              </li>
            )}
          </ul>

          <label
            className="form__lbl required"
            htmlFor="algorithm"
          >
            {translation.algorithm.label}
          </label>

          <div className="editor">
            <section
              id="algorithm"
              className="editor__canvas"
              contentEditable
              placeholder={translation.algorithm.placeholder}
              onInput={e => this.handleInput(e.target.innerHTML)}
              ref={this.canvas}
              suppressContentEditableWarning
              dangerouslySetInnerHTML={{ __html: algorithm }}
              required
              data-errormsg={translation.algorithm.error}
            ></section>

            <section className="editor__variables">
              <h2 className="editor__variables-heading">
                {translation.tags}
              </h2>

              {(!tags || !tags.length) &&
                <span className="no-results">
                  {translation.no_tags}
                </span>
              }

              {tags && tags.length > 0 &&
                <ul className="editor__variables-list">
                  {tags.map(
                    (tag, i) =>
                    <li key={i}>
                      <button
                        className="editor__variable"
                        type="button"
                        onClick={e => {
                          // Make sure the canvas is focused
                          if(!document.activeElement !== this.canvas.current) {
                            this.focusCanvas();
                          }

                          // Create the variable element
                          const text = `{${e.target.textContent}}`;

                          // Insert the variable at the current
                          // cursor position and move the cursor
                          // to the end of the value
                          const selection = window.getSelection();
                          let range = selection.getRangeAt(0);

                          range.deleteContents();

                          const fragment = document.createDocumentFragment();

                          fragment.append(text);

                          range.insertNode(fragment);
                          selection.removeAllRanges();

                          this.handleInput(this.canvas.current.innerHTML);
                        }}
                      >
                        {tag}
                      </button>
                    </li>
                  )}
                </ul>
              }
            </section>
          </div>
        </fieldset>

        <div className="panel__action">
          <button
            className="btn btn--outline"
            type="reset"
          >
            {translation.cancel}
          </button>

          <button
            className="btn"
            type="submit"
          >
            {translation.submit}
          </button>
        </div>
      </form>
    )
  }
}

export default translate('EditCustomAlgorithms')(EditCustomAlgorithms);
