/**
 * Renders Step 2 of 4 of the QTI Import form
 */

import React from 'react';
import Head from '../common/Head'

// Helpers
import { checkValidity } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Dependencies
import Select from '../common/Select';
import Tooltip from "../common/Tooltip";

export const ImportAssessmentDetails = class ImportAssessmentDetails extends React.Component {
  componentDidMount() {
    const { translation } = this.props;
    const { AppStore } = this.props.store;
    AppStore.drawerTitle = translation.heading;
  }

  componentWillUnmount() {
    const { AssessmentStore } = this.props.store;
    AssessmentStore.assessment = null;
  }

  handleSubmit = () => {
    const { goToStep } = this.props;

    goToStep(3);
  }

  render() {
    const { translation, handleChange, updateAssessmentTemplate, values, store } = this.props;
    const { AssessmentStore } = store;
    const { assessmentSid } = values;
    const templates = AssessmentStore.assessmentTemplates;
    const assessmentGroups = AssessmentStore.assessmentGroups;

    return (
      <div className="panel panel--md panel--solo">
        <Head>
          <title>{translation.heading} | SmarterMeasure</title>
        </Head>

        {assessmentSid ?
          <div className="note">
            <p>{translation.description.assessment_found.replace('%key%', values.key)}</p>
          </div>
          : <div className="note">
              <p>{translation.description.not_found.replace('%key%', values.key)}</p>
            </div>
          }

        <form
          className="form"
          noValidate
          onSubmit={(e) => checkValidity(e, this.handleSubmit)}
        >
          <fieldset>
            <ul className="form__fields">
              <li>
                <label
                  className={`form__lbl required`}
                  htmlFor={'assessmentName'}
                >
                  {translation.assessment_name.label}

                  { translation.assessment_name.tooltipText &&
                    <Tooltip
                        content={translation.assessment_name.tooltipText}
                        className="cplt-label-tooltip"
                        right
                    />
                  }
                </label>
                <input
                  type="text"
                  id="assessmentName"
                  required={true}
                  aria-required="true"
                  data-errormsg={translation.assessment_name.error}
                  ref={this.assessmentName}
                  onChange={handleChange('assessmentName')}
                />
              </li>
              <li>
                <label
                  className={`form__lbl required`}
                  htmlFor={'assessmentDetails'}
                >
                  {translation.assessment_details.label}
                </label>
                <textarea
                  type="text"
                  id="assessmentDetails"
                  required={true}
                  aria-required="true"
                  data-errormsg={translation.assessment_details.error}
                  ref={this.assessmentDetails}
                  onChange={handleChange('assessmentDetails')}
                />
              </li>
              <li>
                <label
                  className={`form__lbl`}
                    htmlFor="maxAttempts"
                >
                  {translation.max_attempts.label}
                </label>

                <input
                  type="number"
                  id="maxAttempts"
                  min={0}
                  ref={this.maxAttempts}
                  onChange={handleChange('maxAttempts')}
                />
              </li>
              <li>
                <Select
                  error={translation.assessmentTemplate.error}
                  id="assessmentTemplate"
                  label={translation.assessmentTemplate.label}
                  onChange={updateAssessmentTemplate}
                  options={templates.map(({ title }) => ({ value: title, label: title }))}
                  placeholder={translation.assessmentTemplate.placeholder}
                  required
                />
              </li>
              <li>
                <div className="cbox">
                  <input
                    type="checkbox"
                    id="addToGroup"
                    className="cbox__input"
                    ref={this.addToGroup}
                    onChange={handleChange('addToGroup')}
                  />
                  <label
                    className={`cbox__lbl`}
                    htmlFor="addToGroup"
                  >
                    {translation.add_to_group}
                  </label>
                </div>
              </li>

              {values.addToGroup &&
                <li>
                  <div className="pill__box">
                    <div className="pill">
                      <input
                        type="radio"
                        id="createNew"
                        className="pill__input meta"
                        name="assessmentGroupType"
                        value="createNew"
                        checked={values.assessmentGroupType === 'createNew'}
                        onChange={handleChange('assessmentGroupType')}
                      />
                      <label className="pill__lbl" htmlFor="createNew">
                        {translation.create_new}
                      </label>
                    </div>
                    <div className="pill">
                      <input
                        type="radio"
                        id="useExisting"
                        className="pill__input meta"
                        name="assessmentGroupType"
                        value="useExisting"
                        checked={values.assessmentGroupType === 'useExisting'}
                        onChange={handleChange('assessmentGroupType')}
                      />
                      <label className="pill__lbl" htmlFor="useExisting">
                        {translation.use_existing}
                      </label>
                    </div>
                  </div>
                </li>
              }

              {values.addToGroup && values.assessmentGroupType === 'createNew' &&
                <li>
                  <label
                    className={`form__lbl required`}
                    htmlFor={'assessmentGroupKey'}
                  >
                    {translation.assessment_group_key.label}
                    { translation.assessment_group_key.tooltipText &&
                      <Tooltip
                          content={translation.assessment_group_key.tooltipText}
                          className="cplt-label-tooltip"
                          right
                      />
                    }
                  </label>
                  <input
                    type="text"
                    id="assessmentGroupKey"
                    required={true}
                    aria-required="true"
                    data-errormsg={translation.assessment_group_key.error}
                    ref={this.assessmentGroupKey}
                    onChange={handleChange('assessmentGroupKey')}
                    defaultValue={values.assessmentGroupKey}
                  />
                </li>
              }

              {values.addToGroup  && values.assessmentGroupType === 'createNew' &&
                <li>
                  <label
                    className={`form__lbl required`}
                    htmlFor={'assessmentGroupName'}
                  >
                    {translation.assessment_group_name.label}
                    { translation.assessment_group_name.tooltipText &&
                      <Tooltip
                          content={translation.assessment_group_name.tooltipText}
                          className="cplt-label-tooltip"
                          right
                      />
                    }
                  </label>
                  <input
                    type="text"
                    id="assessmentGroupName"
                    required={true}
                    aria-required="true"
                    data-errormsg={translation.assessment_group_name.error}
                    ref={this.assessmentGroupName}
                    onChange={handleChange('assessmentGroupName')}
                    defaultValue={values.assessmentGroupName}
                  />
                </li>
              }

              {values.addToGroup && values.assessmentGroupType === 'useExisting' &&
                <li>
                  <Select
                    error="This field is required"
                    id="existingKey"
                    label={translation.assessment_group_key.label}
                    onChange={handleChange}
                    options={assessmentGroups.map(({ key, name }) => ({ value: key, label: name }))}
                    placeholder="Pick One"
                    required
                  />
                </li>
              }

            </ul>
          </fieldset>

          <div className="panel__action">
            <button
              className="btn"
              type="submit"
            >
              {translation.next}
            </button>
          </div>
        </form>
      </div>
    )
  }
}

export default translate('ImportAssessmentDetails')(ImportAssessmentDetails);