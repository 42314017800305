/**
 * Renders a list of assessment changes
 */

import React from 'react';
import { observer } from 'mobx-react';

// Helpers
import { deformatQuery } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Components
import AssessmentDiff from './AssessmentDiff';
import Date from '../common/Date';
import Dropdown from '../common/Dropdown';
import LoadMore from '../common/LoadMore';

export const AssessmentHistory = observer(class AssessmentHistory extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      currentVersionSid: null,
      diffName: null,
      showDiff: false
    }
  }

  componentDidMount() {
    this.listAssessmentHistory();
  }

  listAssessmentHistory = () => {
    const { assessmentSid, store } = this.props;
    const { AssessmentStore } = store;

    const query = {
      limit: 24
    };

    const callback = (data) => {
      this.setState({
        currentVersionSid: data[0]?.assessmentVersionSid || null
      });
    }

    AssessmentStore.listAssessmentHistory(assessmentSid, query, false, callback);
  }

  /**
   * handleView - shows a preview of a version of an assessment
   * @param {object} version
   */
  handleView = (version) => {
    const { installSid, token } = this.props.store.UserStore;

    const url = `/assessment/${version.assessmentSid}?install=${installSid}&version=${version.assessmentVersionSid}&ro=true&token=${token}`;

    window.open(url);
  }

  /**
   * handleCompare - gets a diff of the selected version compared to the current version
   * @param {object} version
   */
  handleCompare = (version, name) => {
    const { currentVersionSid } = this.state;
    const { AssessmentStore } = this.props.store;

    const callback = () => {
      this.setState({
        diffName: name,
        showDiff: true
      });
    }

    AssessmentStore.getVersionDiff(version.assessmentSid, version.assessmentVersionSid, currentVersionSid, callback);
  }

  /**
   * handleRestore - restores a version of an assessment as a draft
   * @param {object} version
   */
  handleRestore = (version) => {
    const { onRestore, store, translation } = this.props;
    const { AppStore } = store;

    if(onRestore) {
      const callback = () => {
        AppStore.toast = translation.restore.success;
      }

      onRestore(version, callback);
    }
  }

  render() {
    const { assessmentSid, store, translation } = this.props;
    const { diffName, showDiff } = this.state;
    const { AppStore, AssessmentStore } = store;
    const { assessmentHistory, assessmentHistoryTotal, assessmentHistoryNext, diff } = AssessmentStore;

    return (
      <div className="panel panel--solo">
        {assessmentHistory.length === 0 && !AppStore.loading &&
          <span className="no-results">{translation.no_results}</span>
        }

        {assessmentHistory.length > 0 &&
          <table>
            <caption className="meta">{translation.caption}</caption>

            <thead>
              <tr>
                <th scope="col">{translation.version}</th>
                <th scope="col">{translation.published}</th>
                <th scope="col">{translation.user}</th>
                <th scope="col">{translation.actions}</th>
              </tr>
            </thead>

            <tbody>
              {assessmentHistory.map(
                (version, i) => {
                  const options = [
                    {
                      title: translation.view,
                      action: () => {
                        this.handleView(version);
                      }
                    }, {
                      title: translation.compare,
                      action: () => {
                        this.handleCompare(version, `${translation.version} ${assessmentHistoryTotal - i}`);
                      }
                    }, {
                      title: translation.restore.button,
                      action: () => {
                        this.handleRestore(version);
                      }
                    }
                  ];

                  // If this is the most recent version, remove
                  // the restore and compare options.
                  if(i === 0) {
                    options.splice(2, 1);
                    options.splice(1, 1);
                  }

                  return (
                    <tr key={i}>
                      <td>
                        {translation.version} {assessmentHistoryTotal - i}
                        {i === 0 &&
                          ' '
                        }
                        {i === 0 &&
                          <strong className="tag">{translation.current}</strong>
                        }
                      </td>
                      <td>
                        <Date
                          {...this.props}
                          date={version.startDate}
                          dateFormat="M/d/yy h:mm a"
                        />
                      </td>
                      <td>
                        {
                          version.user.firstName && version.user.lastName ?
                            `${version.user.firstName} ${version.user.lastName}` :
                            translation.user_not_found
                        }
                      </td>
                      <td className="table__cell--sm">
                        <Dropdown
                          {...this.props}
                          id={`${version.assessmentVersionSid}-actions`}
                          options={options}
                          triggerText={translation.actions}
                        />
                      </td>
                    </tr>
                  )
                }
              )}
            </tbody>
          </table>
        }

        {assessmentHistoryNext &&
          <LoadMore
            {...this.props}
            buttonText={translation.load_more}
            loadFunction={(callback) => {
              AssessmentStore.listAssessmentHistory(assessmentSid, deformatQuery(assessmentHistoryNext), true, callback)
            }}
            infinite={true}
          />
        }

        {showDiff &&
          <AssessmentDiff
            {...this.props}
            oldVersion={diff}
            onClose={() => this.setState({ diffName: null, showDiff: false })}
            title={translation.comparing.replace('%diff%', diffName)}
          />
        }
      </div>
    )
  }
})

export default translate('AssessmentHistory')(AssessmentHistory);
