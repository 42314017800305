/**
 * checkValidity - Checks the validity of form fields on submit
 * @param {event} e - Form submitted event. Also used to identify the form and prevent the default form submission so that we can validate the form before submitting
 * @param {function} callback - function to be called after this one is done. Used mostly for submitting the form after validating
 * @param {boolean} surveyDetails - a boolean that when set to true will tell the function that it should look for survey detail errors rather than the usual form errors
 */
export function checkValidity(e, callback, surveyDetails) {
  e.preventDefault();

  const form = e.target;


  const invalidFields = form.querySelectorAll('input[required]:invalid, select[required]:invalid, textarea[required]:invalid, input[required][aria-invalid="true"]');

  // Mark the form as submitted
  form.className += ' form--submitted';

  // Remove any existing error messages
  const existingErrors = surveyDetails ? form.querySelectorAll('.details__err') : form.querySelectorAll('.form__err');
  for(let i = 0; i < existingErrors.length; i++) {
    existingErrors[i].remove();
  }

  // Handle validation
  if(invalidFields.length > 0) {
    // If there are invalid fields, insert error messages
    for(let i = 0; i < invalidFields.length; i++) {
      const errorMsg = invalidFields[i].getAttribute('data-errormsg');
      const errorEl = document.createElement('label');

      errorEl.innerHTML = errorMsg;
      errorEl.className = surveyDetails ? 'details__err' : 'form__err';
      errorEl.setAttribute('for', invalidFields[i].id);
      errorEl.setAttribute('role', 'alert');

      // Insert error labels (for styled select elements, use the grandparent)
      const parent = invalidFields[i].parentNode;

      if(parent.classList.contains('select')) {
        const grandParent = parent.parentNode;
        grandParent.insertBefore(errorEl, invalidFields[i].nextSibling);

      } else if (parent.classList.contains('file')) {
        const grandParent = parent.parentNode;
        grandParent.insertBefore(errorEl, parent.nextSibling);

      } else {
        parent.insertBefore(errorEl, invalidFields[i].nextSibling);
      }
    }
  } else {
    if(callback) {
      callback(e);
    }
  }
}

/**
 * deformatQuery - Takes a URL, extracts the query string and returns it as an object
 * @param {url} query
 */
export function deformatQuery(url) {
  // eslint-disable-next-line
  const queryString = url.split('?')[1].replace(/\%/g, '*');
  const queryObj = JSON.parse(
    `{ "${queryString.replace(/&/g, '","').replace(/=/g,'":"')}" }`,
    (key, value) => { return key === '' ? value : decodeURIComponent(value) }
  );

  return queryObj;
}
export function aceEditorLanguageSelection(language){

  let languageObject = {
    cs: 'csharp',
    cpp: 'c_cpp',
    apache: 'apache_conf',
    coffeescript: 'coffee'
  }
  if(languageObject[language] ){
    return languageObject[language]
  } else {
    return language;
  }

}

/**
 * formatQuery - Takes an object and returns it as URL query parameters
 * @param {object} query
 */
export function formatQuery(query) {
  let queryString = '';

  for(let key in query) {
    if(!queryString) {
      // If this is the first key, add a ?
      queryString += '?'
    } else {
      // Otherwise, add a &
      queryString += '&'
    }

    queryString += `${key}=${query[key]}`
  }

  return queryString;
}

/**
 * getData - Gets data out of sessionStorage and falls back to cookies
 * @param {string} name
 */
export function getData(name) {
  try {
    return sessionStorage.getItem(name);
  } catch(error) {
    const match = document.cookie.match(new RegExp(name + '=([^;]+)'));

    if(match) {
      return match[1];
    } else {
      return null;
    }
  }
}

/**
 * getLocalData - Gets data out of localStorage and falls back to cookies
 * @param {string} name
 */
export function getLocalData(name) {
  try {
    return localStorage.getItem(name);
  } catch(error) {
    const match = document.cookie.match(new RegExp(name + '=([^;]+)'));

    if(match) {
      return match[1];
    } else {
      return null;
    }
  }
}

/**
 * getParam - Gets query params from a string
 * @export
 * @param {string} name
 * @param {string} url
 */
export function getParam(name, url) {
  if (!url) url = window.location.search;

  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  let results = regex.exec(url);

  if(!results) {
    return null;
  }

  if(!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * getPathname - Takes a URL string and gets the pathname
 * @param {string} url
 */
export function getPathname(url) {
  const pathname = new URL(url).pathname;

  return pathname;
}

/**
 * getUrlSegment - Gets a given URL segment from a string
 * @param {integer} segment
 * @param {string} pathname
 */
export function getUrlSegment(segment, pathname) {
  if (!pathname) pathname = window.location.pathname;

  let pathArray = pathname.split('/');

  if (pathArray[0] === '') {
    pathArray.splice(0, 1);
  }

  return pathArray[segment - 1];
}

/**
 * isDescendant - Checks if an element is a descendant of a given parent
 * @export
 * @param {parent} element
 * @param {child} element
 * @return boolean
 */
export function isDescendant(parent, child) {
  let node = child.parentNode;

  while(node !== null) {
    if(node === parent) {
      return true;
    }

    node = node.parentNode;
  }

  return false;
}

/**
 * isObject - Tests if the parameter is an object.
 */
export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * loadCSS - Loads a CSS file
 */
export function loadCSS(name, src) {
  const css = document.createElement('link');
  css.id = `${name}-css`;
  css.type = 'text/css';
  css.rel = 'stylesheet';
  css.href = src;

  document.head.append(css);
}

/**
 * loadJS - Loads a JS file
 */
export function loadJS(script, callback) {
  const js = document.createElement('script');
  js.id = `${script.name}-js`;
  js.type = 'text/javascript';
  js.async = true;
  js.src = script.src;

  if(callback) {
    js.onload = () => callback(script);
  }

  document.body.appendChild(js);
}

/**
 * mergeDeep - Recursively merges two objects.
 * @param {object} target
 * @param {object} source
 */
export function mergeDeep(target, source) {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target))
          Object.assign(output, { [key]: source[key] });
        else
          output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

/**
 * parseMinutes - Takes minutes and returns it as hh:mm
 */
export function parseMinutes(minutes) {
  return new Date(minutes * 60000).toISOString().substr(11, 5);
}

/**
 * parseSeconds - Takes seconds and returns it as mm:ss or #m #s
 * @param {string} seconds
 * @param {boolean} durationString
 */
export function parseSeconds(seconds, durationString) {
  let time = new Date(seconds * 1000).toISOString().substr(14, 5);

  if(durationString) {
    time = time.replace(':', 'm ');
    time += 's';
  }

  return time;
}

/**
 * readableMinutes - Takes minutes and returns it as # hours and # minutes
 */
export function readableMinutes(minutes, labels) {
  const hr = Math.floor(minutes / 60);
  let lbls = labels;

  if(!labels) {
    lbls = {
      hour: 'hour',
      hours: 'hours',
      minute: 'minute',
      minutes: 'minutes'
    };
  }

  let hrLabel = lbls.hours;

  if(hr === 1) {
    hrLabel = lbls.hour;
  }

  const min = minutes % 60;
  let minLabel = lbls.minutes;

  if(min === 1) {
    minLabel = lbls.minute;
  }

  let string = '';

  if(hr) {
    string = string + `${hr} ${hrLabel}`;
  }

  if(min) {
    string = string + `${hr ? ' ' : ''}${min} ${minLabel}`
  }

  return string;
}

/**
 * removeDuplicates
 * @param {array} arr
 */
export function removeDuplicates(arr) {
  const duplicates = {};

  arr = arr.filter(el => {
    const isDuplicate = duplicates[el];
    duplicates[el] = true;
    return !isDuplicate;
  });

  return arr;
}

/**
 * storeData - Stores data in sessionStorage and falls back to cookies
 * @param {string} name
 * @param {string} data
 */
export function storeData(name, data) {
  try {
    sessionStorage.setItem(name, data);
  } catch(error) {
    document.cookie = name + '=' + data + '; expires=0; path=/';
  }
}

/**
 * storeLocalData - Stores data in localStorage and falls back to cookies
 * @param {string} name
 * @param {string} data
 */
export function storeLocalData(name, data) {
  try {
    localStorage.setItem(name, data);
  } catch(error) {
    document.cookie = name + '=' + data + '; expires=0; path=/';
  }
}

/**
 * titleCase - Converts a string to title case, ignoring articles
 * @param {string} string
 */
export function titleCase(string) {
  const arr = [];
  const ignore = [
    'a', 'an', 'and', 'as',
    'at', 'but', 'by', 'for',
    'from', 'if', 'in', 'nor',
    'on', 'of', 'off', 'or',
    'out', 'over', 'the', 'to',
    'vs'
  ];

  const sentenceCase = (word) => {
    return word.toString().charAt(0).toUpperCase() + word.slice(word.length - (word.length - 1));
  }

  // Make sure capitalized versions of articles
  // are lowercased by adding them to the ignore array
  ignore.forEach(word => {
    ignore.push(sentenceCase(word));
  });

  const output = string.split(' ');

  return output.forEach((word, i) => {
    arr.push(i === 0 || ignore.indexOf(word) === -1 || output[i - 1].endsWith(':') ? sentenceCase(word) : word.toLowerCase());
    // eslint-disable-next-line
  }), arr.join(' ');
}

/**
 * toBase64 - Converts a qti zip file to Base64 string
 * @param {File} file
 */
export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

/**
 * isSameDate - Checks if two JS Date objects are the same day, month, & year
 * @param {Date} date1
 * @param {Date} date2
 */
export function isSameDate(date1, date2) {
  return (date1.getFullYear() === date2.getFullYear()) &&
        (date1.getMonth() === date2.getMonth()) &&
        (date1.getDate() === date2.getDate());
}

/**
 * isNotSameMonth - Checks if two JS Date objects belong in different months
 * @param {Date} date1
 * @param {Date} date2
 */
export function isNotSameMonth(date1, date2) {
  return (date1.getFullYear() !== date2.getFullYear()) ||
        (date1.getMonth() !== date2.getMonth());
}

/**
 * arrayChunk - Turns an array into an array of arrays, where the inner
 * 'chunk' length is set using an argument.
 * @param {Array} array
 * @param {integer} chunk - Number of a items in an inner array
 */
export function arrayChunk(array, chunk) {

  return array.reduce((result, item, index) => {
    const ch = Math.floor(index / chunk);

    if(!result[ch]) {
      result[ch] = [] // start a new chunk
    }

    result[ch].push(item)

    return result;
  }, [])
}
