/**
 * Renders a gauge chart
 */

import React from 'react';

const GaugeChart = class GaugeChart extends React.Component {
  render() {
    const {
      id,
      levels,
      markers,
      max,
      size = 200,
      title,
      units,
      value
    } = this.props;
    const strokeWidth = 30;
    const percent = value / max * 100;
    const circumfrence = Math.PI * (size - strokeWidth);

    return (
      <div className="chart gaugechart">
        <svg
          aria-describedby={`chart-desc-${id}`}
          aria-labelledby={`chart-title-${id}`}
          height={size / 2}
          ref={this.chart}
          role="img"
          width={size}
          xmlns="http://www.w3.org/2000/svg"
        >
          <title id={`chart-title-${id}`}>{title}</title>
          <desc id={`chart-desc-${id}`}>TODO: Description</desc>

          <circle
            className="gaugechart__meter"
            cx="50%"
            cy="50%"
            fill="none"
            strokeDasharray={`${circumfrence / 2}, ${circumfrence}`}
            strokeWidth={strokeWidth}
            transform={`translate(0 -${size / 4})`}
            r={(size - strokeWidth) / 2}
          />

          {levels &&
            <g>
              {levels.map(
                (level, i) =>
                <circle
                  className="gaugechart__level"
                  cx="50%"
                  cy="50%"
                  fill="none"
                  key={i}
                  strokeDasharray={`${circumfrence / 2 * ((max - level) / max)}, ${circumfrence}`}
                  strokeWidth={strokeWidth}
                  transform={`translate(0 -${size / 4})`}
                  r={(size - strokeWidth) / 2}
                />
              )}
            </g>
          }
        </svg>

        {markers &&
          <ul
            className="gaugechart__ticks"
            style={{ height: size / 2, width: size }}
          >
            {markers.map(
              (marker, i) =>
              <li
                className="tick"
                key={i}
                style={{ transform: `rotate(${(270 + ((marker / max * 100) * 180 / 100))}deg)` }}
              >
                <span className="gaugechart__marker">{marker}</span>
              </li>
            )}
          </ul>
        }

        <output
          className="gaugechart__needle"
          style={{ transform: `translateX(-50%) rotate(${(270 + (percent * 180 / 100))}deg)` }}
        >
          <span className="meta">{value}</span>
        </output>

        {units &&
          <span className="gaugechart__units">
            {units}
          </span>
        }
      </div>
    )
  }
}

export default GaugeChart;
