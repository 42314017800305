// Import stores
import AppStore from './AppStore';
import AssessmentStore from './AssessmentStore';
import CourseStore from './CourseStore';
import EnrollmentStore from './EnrollmentStore';
import ReportStore from './ReportStore';
import SurveyStore from './SurveyStore';
import UserStore from './UserStore';
import ActivityStore from './ActivityStore';

// Combine stores
const store = {
  AppStore,
  AssessmentStore,
  CourseStore,
  EnrollmentStore,
  ReportStore,
  SurveyStore,
  UserStore,
  ActivityStore
};

export default store;
